import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Container, Typography, Grid, Stack, Box } from '@mui/material';

function AuthFooter() {
    return (
        <div>

<Container maxWidth="xl">
     <Grid container spacing={2}>
      <Grid item md={4} sm={6} xs={12} className='about'>
        <Box>
        <Typography variant="h4">
          About Us
        </Typography>
        <Typography variant="body3">
        Welcome to the official website of St. Paul’s Senior Secondary School and thank you your interest. St. Paul’s Senior... <a href="https://www.stpaulspalampur.com/about-us-about-our-school" target='blank'>Read More</a> 
        </Typography>
        <Typography variant="h6">
          Follow Us : 
          <ul className='social_icons'>
            <li><a href="https://www.facebook.com/stpaulspalampur/" target='blank'><FacebookIcon/></a></li>
            <li><a href="https://www.instagram.com/stpaulsschoolpalampur/?utm_medium=copy_link" target='blank'><InstagramIcon/></a></li>
            <li><a href="https://www.youtube.com/channel/UCRBBI47qbX17VFTggJspx2A/about" target='blank'><YouTubeIcon/></a></li>
          </ul>
        </Typography>
        </Box>
      </Grid>

      <Grid item md={4} sm={6} xs={12}>
        <Box>
        <Typography variant="h4">
          Address
        </Typography>
        <Typography>
        <ul className="contact_info">
        <li>
        <EmailIcon/>
        <a href="mailto:stpaulsschool.palampur@gmail.com">stpaulsschool.palampur@gmail.com</a> 
        </li>
        <li>
        <LocalPhoneIcon/>
        <p><a href="tel:01894-235445">01894-235445 (O)</a><br/>
        <a href="tel:01894-230612">01894-230612 (O & R)</a> </p>
        </li>
        <li>
          <LocationOnIcon/>
          <p>Palampur Distt. Kangra H.P. 176061 (INDIA)<br/>
             Landmark : Near Civil Courts, Opposite St. John’s Church</p>
        </li>
        </ul>
        </Typography>
        </Box>
      </Grid>

      <Grid item md={4} sm={6} xs={12}>
        <Box>
        <Typography variant="h4">
        Google Map
        </Typography>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d54068.838163165885!2d76.537673!3d32.115135!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3904b3dac2d4a8b5%3A0x78501acb728d3b3f!2sSt.%20Paul&#39;s%20Senior%20Secondary%20School-%20Best%20ICSE%20School%20in%20Palampur%20%7C%20Kangra%20%7C%20Himachal%20Pradesh!5e0!3m2!1sen!2sin!4v1684242351782!5m2!1sen!2sin" title="Example Website"/>
        </Box>
      </Grid>
      </Grid>
      </Container>

      <div className='copyright'>
      <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={9} md={9} lg={8}>
            <p>Copyright © 2023 St. Paul's Sr. Sec. School. All Rights Reserved.</p>
       </Grid>
      <Grid item xs={12} sm={3} md={3} lg={4} style={{align: 'right'}}>
         <a href="https://www.cybrain.co.in/" target="blank"><img src='/assets/images/logo/cybrain-logo.png' alt='cybrain'/></a>
      </Grid>
      </Grid>
      </Container>
      </div>    
        </div>
    )
}

export default AuthFooter