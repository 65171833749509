import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import DownloadIcon from '@mui/icons-material/Download';
import {
    Box,
    Menu,
    Button,
    Paper,
    Card,
    Avatar,
    MenuItem,
    CardMedia,
    IconButton,
    Container,
    Typography,
    CardActions,
    CardContent,
    CardActionArea,
    Stack,
    Divider
} from '@mui/material';
import { GetPosts } from '../../services/feeds';
import { API_BASE_URL } from '../../Setting';

function ViewFeed() {
    const [feeds, setFeeds] = useState(null);
    const [loading, setLoading] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [downloadUrl, setDownloadUrl] = useState('');
    const { id } = useParams();

    // const handleCloseMenu = () => {
    //     setAnchorEl(null);
    // };

    const getPosts = async () => {
        try {
            if (id !== "0") {
                setLoading(true);
                const apiRes = await GetPosts(id);
                if (apiRes.status === 200) {
                    setFeeds(apiRes?.data?.data || null);
                } else {
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            toast.error('Something went wrong!, Please try again.', {
                position: toast.POSITION.BOTTOM_LEFT
            })
        }
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    // const documentUrl = `${API_BASE_URL}${feeds?.avatar}`;
    const isPdf = feeds?.avatar.endsWith(".pdf");
    const isDoc = feeds?.avatar.endsWith(".doc");
    const isDocx = feeds?.avatar.endsWith(".docx");
    const isImage = feeds?.avatar.endsWith(".jpg") || feeds?.avatar.endsWith(".jpeg") || feeds?.avatar.endsWith(".png");

    useEffect(() => {
        getPosts()
    }, [id])

    const fileUrl = `${API_BASE_URL}${feeds?.avatar}`;
    const fileName = fileUrl?.split("/").pop();

    const docs = [
        {
            uri: `${API_BASE_URL}${feeds?.avatar}`,
            fileType: "docx",
            fileName: `${fileName}`,
        },
    ];

    const doc = [
        {
            uri: `${API_BASE_URL}${feeds?.avatar}`,
            fileType: "doc",
            fileName: `${fileName}`,
        },
    ];

    const pdfs = [
        {
            uri: `${API_BASE_URL}${feeds?.avatar}`,
            fileType: "pdf",
            fileName: `${fileName}`,
        },
    ];

    const handleDownloadClick = () => {
        const downloadUrl = `${API_BASE_URL}${feeds?.avatar}`;
        window.open(downloadUrl);
    };

    return (
        <>
            <Card sx={{ maxWidth: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                {/* <iframe id={feeds.id}
                    src={`${API_BASE_URL}${feeds?.avatar}`}
                    src={API_BASE_URL + feeds?.avatar}
                    onLoad={() => console.log(`${API_BASE_URL}${feeds?.avatar}`)}
                    width="100%"
                    height="500px"
                    title="Document"
                /> */}

                {isPdf && (
                    <DocViewer
                        documents={pdfs}
                        pluginRenderers={DocViewerRenderers} />
                )}

                {isDoc && (
                    <>
                        <Button
                            sx={{
                                backgroundColor: '#d7b56d',
                                width: '20%',
                                marginTop: '2px',
                                mx: 2,
                                my: 2
                            }}
                            variant="contained"
                            endIcon={< DownloadIcon />}
                        >
                            <a href={`${API_BASE_URL}${feeds?.avatar}`} style={{ listStyle: 'none', color: 'white' }}>
                                {fileName}
                            </a>
                        </Button>
                    </>
                )}

                {isDocx && (
                    <>
                        <Button
                            sx={{
                                backgroundColor: '#d7b56d',
                                width: '20%',
                                marginTop: '2px',
                                mx: 2,
                                my: 2
                            }}
                            onClick={handleDownloadClick}
                            variant="contained"
                            endIcon={< DownloadIcon />}
                        >
                            <a href={`${API_BASE_URL}${feeds?.avatar}`} style={{ listStyle: 'none', color: 'white' }}>
                                {fileName}
                            </a>
                        </Button>
                    </>
                )}

                {isImage && (
                    <CardMedia
                        component="img"
                        image={API_BASE_URL + feeds?.avatar}
                    />
                )}
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {feeds?.title}
                    </Typography>

                    <Typography
                        variant="body2"
                        color="text.secondary"
                        dangerouslySetInnerHTML={{
                            __html: feeds?.messageContentWithHtml,
                        }}
                    />
                </CardContent>
                <CardActions sx={{ marginTop: 'auto' }}>
                    <Stack width={'100%'} direction="row" alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                            <Avatar src="/assets/images/logo/logo_new.png" />
                            <Box ml={1}>
                                <Typography variant="subtitle2" component="p">
                                    Administrator
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary" component="p">
                                    {new Date(feeds?.createdAt).toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                                </Typography>
                            </Box>
                        </Box>
                    </Stack>
                </CardActions>
            </Card>
        </>
    )
}

export default ViewFeed
