import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// icons
import SchoolIcon from '@mui/icons-material/School';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClassIcon from '@mui/icons-material/Class';
import Divider from '@mui/material/Divider';
import EventIcon from '@mui/icons-material/Event';
import GradingIcon from '@mui/icons-material/Grading';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {
    Box,
    Stack,
    Typography,
} from '@mui/material';
import Lottie from "lottie-react";
import dayjs from 'dayjs';
import noData from '../../animations/Circle.json';
import { ListEducationDetails } from '../../services/education';
import Loader from '../../loader/Loader';


function EducationProfile() {
    const [loading, setLoading] = useState(false);
    const [educationalDetails, setEducationalDetails] = useState([]);

    const id = localStorage.getItem('id')

    const getEducationalData = async () => {
        setTimeout(async () => {
            try {
                const apiRes = await ListEducationDetails(id);
                if (apiRes.status === 200) {
                    setEducationalDetails(apiRes?.data?.data || []);
                    setLoading(true);
                } else {
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                    setLoading(false);
                }
            } catch (err) {
                setLoading(false);
                toast.error('Something went wrong!, Please try again.', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        }, 500)
    };

    useEffect(() => {
        getEducationalData();
    }, [])

    return (
        <>
            {!loading ?
                <div>
                    <Loader />
                </div>
                :
                <>
                    {educationalDetails?.length === 0 ?
                        <>
                            <Box sx={{ bgcolor: 'white' }}>
                                <Lottie style={{ height: '300px' }} loop animationData={noData} />
                            </Box>
                        </>
                        :
                        <>

                            <Stack
                                direction={{ xs: 'column', sm: 'column', md: 'column' }}
                                spacing={5}
                            >
                                {educationalDetails?.map((item) => (
                                    <>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                boxShadow: '0px 0px 2px -2px',
                                                borderRadius: 0,
                                                mt: 4,
                                            }}
                                            py={5}
                                            px={5}
                                            width={'100%'}
                                            bgcolor='white'
                                        >
                                            <Typography variant="h5" gutterBottom>
                                                {/* {item?.institute.toUpperCase()} */}
                                                {item?.institute}
                                            </Typography>
                                            <Divider sx={{
                                                width: '100%',
                                                borderStyle: 'dashed'
                                            }} />
                                            {/* <p>
                           {item}
                       </p> */}
                                            <Stack direction={'row'} spacing={2}>
                                                <Box><SchoolIcon sx={{ fontSize: '17px' }} /></Box>
                                                <Box>
                                                    <Typography>
                                                        {item?.institute}
                                                    </Typography>
                                                </Box>
                                            </Stack>

                                            <Stack direction={'row'} spacing={2}>
                                                <Box><ClassIcon sx={{ fontSize: '17px' }} /></Box>
                                                <Box>
                                                    <Typography >
                                                        {item?.degree}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                            <Stack direction={'row'} spacing={2}>
                                                <Box><MenuBookIcon sx={{ fontSize: '17px' }} /></Box>
                                                <Box>
                                                    <Typography >
                                                        {item?.fieldOfStudy}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                            <Stack direction={'row'} spacing={2}>
                                                <Box><GradingIcon sx={{ fontSize: '17px' }} /></Box>
                                                <Box>
                                                    <Typography >
                                                        {item?.grade}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                            <Stack direction={'row'} spacing={2}>
                                                <Box><CalendarMonthIcon sx={{ fontSize: '17px' }} /></Box>
                                                <Box>
                                                    <Typography >
                                                        {dayjs(item?.startDate).format('DD-MM-YYYY')}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                            <Stack direction={'row'} spacing={2}>
                                                <Box><EventIcon sx={{ fontSize: '17px' }} /></Box>
                                                <Box>
                                                    <Typography >
                                                        {dayjs(item?.endDate).format('DD-MM-YYYY')}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </>
                                ))
                                }
                                <ToastContainer />
                            </Stack >
                        </>
                    }
                </>
            }
        </>

    )
}

export default EducationProfile
