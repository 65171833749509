import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import dayjs from 'dayjs';
import {
    Box,
    Stack,
    Container,
    Typography,
} from '@mui/material';
import '../../css/main.css';

// Icons
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ShareIcon from '@mui/icons-material/Share';

import BasicDetails from './BasicDetails';
import EducationalDetails from './EducationalDetails';
import WorkDetails from './WorkDetails';
import SocialLinks from './SocialLinks';

function EditProfile() {
    const [value, setValue] = React.useState('1');
    const [date, setDate] = React.useState(dayjs('2022-04-07'));


    const [file, setFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    const handleFileChange1 = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);

        const reader = new FileReader();
        reader.onload = (event) => {
            setImageUrl(event.target.result);
        };
        reader.readAsDataURL(selectedFile);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    return (
        <div>
            <Container maxWidth="xxl">
                <Stack direction="row" mb={5} className='margin_b'>
                    <Typography variant="h4" gutterBottom>
                        Edit Profile
                    </Typography>
                </Stack>

                <Stack direction="column" mb={5}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className='edit_fields'>
                            <TabList
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable auto tabs example"
                                onChange={handleChange}
                                ml={'auto'}
                                sx={{
                                    // float: 'right'
                                }}
                            className='edit_tabs'>
                                <Tab icon={<AccountBoxIcon />} label="Basic Details" value="1" />
                                <Tab icon={<EmojiEventsIcon />} label="Educational Details" value="2" />
                                <Tab icon={<WorkOutlineIcon />} label="Working Details" value="3" />
                                <Tab icon={<ShareIcon />} label="Social Links" value="4" />
                                {/* <Tab icon={<KeyIcon />} label="Change Password" value="5" /> */}
                            </TabList>
                        </Box>

                        <TabPanel value="1" className='padding_content'>
                            <BasicDetails />
                        </TabPanel>

                        <TabPanel value="2">
                            <EducationalDetails />
                        </TabPanel>

                        <TabPanel value="3">
                            <WorkDetails />
                        </TabPanel>

                        <TabPanel value="4">
                            <SocialLinks />
                        </TabPanel>
                    </TabContext>
                </Stack>
            </Container>
        </div>
    )
}

export default EditProfile
