/**
 * @scope api related to working details
 * 
 * @author Umasankar
 * @since 2nd feb 2023
 */

import { API } from './provider';
import { V1, USERS, CREATE_WORKING_DETAILS, GET_WORKING_DETAILS, DELETE_WORKING_DETAILS, UPDATE_WORKING_DETAILS, LIST_WORKING_DETAILS } from './endpoints';

export const listAllUsers = (query) => {
    return API.GET(V1, `${USERS}?roleId=${query?.roleId}`);
};

// Working Details apis
export const CreateWorkingDetails = (payload) => { return API.POST(V1, CREATE_WORKING_DETAILS, payload) };
export const ListWorkingDetails = () => { return API.GET(V1, LIST_WORKING_DETAILS); };
export const DeleteWorkingDetails = (id) => { return API.DELETE(V1, DELETE_WORKING_DETAILS.replace('{id}', id)); }
export const UpdateWorkingDetails = (id, payload) => { return API.PUT(V1, UPDATE_WORKING_DETAILS.replace('{id}', id), payload); }
export const GetWorkingDetails = (id) => { return API.GET(V1, GET_WORKING_DETAILS.replace('{id}', id)); }
