import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
import { ManageAccounts, Payments } from '@mui/icons-material';
import CampaignIcon from '@mui/icons-material/Campaign';
import ExploreIcon from '@mui/icons-material/Explore';
import { getInitials } from '../../../utils/string';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import SvgColor from '../../../components/svg-color';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';

// import navConfig from './config';

import Config from './config';
import { GetUserDetails } from '../../../services/users';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const [loading, setLoading] = useState(false);
  const [basicDetails, setBasicDetails] = useState();
  const [menus, setMenus] = useState(Config());
  const [sidebar, setSidebar] = useState([]);

  const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (localStorage.getItem('role') === 'Administrator') {
      setSidebar([
        {
          title: 'dashboard',
          path: '/dashboard/analytics',
          icon: icon('ic_analytics'),
        },
        {
          title: 'Calendar',
          path: '/calendar',
          icon: <EventIcon />,
        },
        {
          title: 'Profile',
          path: '/profile',
          icon: <ManageAccounts />,
        },
        // {
        //   title: 'Batchmates',
        //   path: '/batchmates',
        //   icon: <PeopleAltIcon />,
        // },
        {
          title: 'Alumni',
          path: '/alumni',
          icon: icon('ic_user'),
        },
        {
          title: 'Payments',
          path: '/payments-list',
          icon: <Payments />,
        },
        {
          title: 'Announcements',
          path: '/announcement',
          icon: <CampaignIcon />,
        },
        {
          title: 'News & Updates',
          path: '/news-updates',
          icon: <ExploreIcon />,
        }
      ])
    }
    else {
      setSidebar([
        {
          title: 'dashboard',
          path: '/dashboard/analytics',
          icon: icon('ic_analytics'),
        },
        {
          title: 'Calendar',
          path: '/calendar',
          icon: <EventIcon />,
        },
        {
          title: 'Profile',
          path: '/profile',
          icon: <ManageAccounts />,
        },
        {
          title: 'Alumni',
          path: '/alumni',
          icon: icon('ic_user'),
        },
        {
          title: 'Batchmates',
          path: '/batchmates',
          icon: <PeopleAltIcon />,
        },
        {
          title: 'News & Updates',
          path: '/news-updates',
          icon: <ExploreIcon />,
        },
      ])
    }

  }, []);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
      className='sidebar_menus'>
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }} className='dash_logo'>
        <Logo />
        <Typography mx={1} mt={1} sx={{ fontSize: '20px' }} variant='h5'>St Paul's Alumni</Typography>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }} className='user_info'>
        <Link underline="none">

          <StyledAccount>
            {/* <Avatar src={'/assets/images/avatars/avatar_default.jpg'} alt="NA" /> */}            
            <Avatar>
              {getInitials(`${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}`)}
            </Avatar>

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {localStorage.getItem('firstName')} {localStorage.getItem('lastName')}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {localStorage.getItem('role')}
              </Typography>
            </Box>
          </StyledAccount>

        </Link>
      </Box>

      <NavSection data={sidebar} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
      <ToastContainer />
    </Box>
  );
}
