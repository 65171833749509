import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import Autocomplete from "react-google-autocomplete";
import 'react-toastify/dist/ReactToastify.css';
import Slide from '@mui/material/Slide';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import {
    Box,
    Stack,
    Divider,
    Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from "lottie-react";
import * as swal from 'sweetalert';

// icons
import noData from '../../animations/Circle.json';

import { CreateWorkingDetails, DeleteWorkingDetails, GetWorkingDetails, ListWorkingDetails, UpdateWorkingDetails } from '../../services/working';
import Loader from '../../loader/Loader';


const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const currentYear = dayjs().year();
const minDate = `${currentYear - 72}-01-01`; // 72 years ago
const currentDate = new Date();
const maxDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`; // current year



function WorkDetails() {
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = useState([]);
    const [updateId, setUpdateId] = useState();
    const [editIndex, setEditIndex] = useState(-1);
    const [initialValues, setInitialValues] = useState({
        designation: '',
        company: '',
        organisationType: '',
        location: '',
        startDate: null,
        endDate: null,
        description: '',
    });

    const validationSchema = Yup.object().shape({
        designation: Yup.string()
            .required('Designation Name is required'),
        company: Yup.string()
            .required('company Name is required'),
        // organisationType: Yup.string().required('Organization Type is required'),
        location: Yup.string()
            .required('Location is required'),
        // startDate: Yup.string()
        // .required("Start Date is required").nullable(),
        // endDate: Yup.string().nullable(),
        description: Yup.string()
            .required('Description is required'),

    })

    const validate = (values) => {
        const errors = {};

        if (values?.startDate && values?.endDate) {
            const startDate = dayjs(values?.startDate);
            const endDate = dayjs(values?.endDate);

            if (endDate.isBefore(startDate)) {
                errors.endDate = "End date cannot be before start date";
            }

            if (startDate.isAfter(endDate)) {
                errors.startDate = "Start date cannot be after end date";
            }
        }

        return errors;
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        formik.resetForm();
        setEditIndex(-1);
    };

    const handleEdit = (index, id) => {
        setEditIndex(index);
        setUpdateId(id);
        formik.setValues(formData[index]);
        setOpen(true);
    };

    const deleteWorkingDetails = async (id) => {
        try {
            swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this !",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        confirmDelete()
                    }
                });

            const confirmDelete = async () => {
                const res = await DeleteWorkingDetails(id)
                if (res.status === 201) {
                    swal("Working detail deleted succesfully!", {
                        icon: "success",
                    });
                    getWorkingDetails();
                } else {
                    swal('Something went wrong!, Please try again.');
                }
            }
        } catch (err) {
            toast.error('Something went wrong!, Please try again.', {
                position: toast.POSITION.BOTTOM_LEFT
            })
        }
    }

    // const id = localStorage.getItem('id')
    const { id } = useParams();

    const getWorkingDetails = async () => {
        setTimeout(async () => {
            try {
                const apiRes = await GetWorkingDetails(id);
                if (apiRes.status === 200) {
                    setLoading(true);
                    setFormData(apiRes?.data?.data || []);
                } else {
                    setLoading(false);
                    alert(apiRes?.data?.message || 'Something went wrong!, Please try again.');
                }
            } catch (err) {
                setLoading(false);
                toast.error('Something went wrong!, Please try again.', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        }, 500)
    };

    useEffect(() => {
        getWorkingDetails();
    }, [id])

    // useEffect(() => {
    //     setUpdateId(updateId);
    // }, [updateId]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        validate,
        onSubmit: async (values) => {
            if (editIndex === -1) {
                try {
                    setLoadingBtn(true)
                    const apiRes = await CreateWorkingDetails({
                        designation: values.designation,
                        company: values.company,
                        organisationType: '',
                        location: values.location,
                        startDate: values.startDate ? new Date(values.startDate).toISOString() : null,
                        endDate: values.endDate ? new Date(values.endDate).toISOString() : null,
                        description: values.description,
                    });
                    if (apiRes.status === 201) {
                        handleClose()
                        setFormData([...formData, values]);
                        toast.success('Details added successfully..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                        getWorkingDetails();
                    } else {
                        toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                    }
                } catch (err) {
                    toast.error('Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                } finally {
                    setLoadingBtn(false)
                }
            } else {
                const updatedData = [...formData];
                updatedData[editIndex] = values;
                setEditIndex(-1);
                try {
                    setLoadingBtn(true)
                    const res = await UpdateWorkingDetails(updateId, {
                        designation: updatedData[editIndex].designation,
                        company: updatedData[editIndex].company,
                        organisationType: '',
                        location: updatedData[editIndex].location,
                        startDate: updatedData[editIndex].startDate ? new Date(updatedData[editIndex].startDate).toISOString() : null,
                        endDate: updatedData[editIndex].endDate ? new Date(updatedData[editIndex].endDate).toISOString() : null,
                        description: updatedData[editIndex].description,
                    });
                    if (res.status === 201) {
                        handleClose()
                        toast.success('Updated successfully..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                        getWorkingDetails();
                    }
                } catch (err) {
                    toast.error('Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                } finally {
                    setLoadingBtn(false)
                }
            }
            formik.resetForm();
        }
    });

    return (

        <>
            <Stack
            >
                <Box width={'100%'} mt={3} px={3} sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <Button type='submit' variant="contained" sx={{
                        backgroundColor: "rgb(0, 171, 85)"
                    }}
                        onClick={handleClickOpen}>
                        Add New
                    </Button>
                </Box>
                {!loading ?
                    <div>
                        <Loader />
                    </div> :
                    <>
                        <Box my={1} sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            {
                                formData?.length === 0 ?
                                    <>
                                        <Box sx={{ bgcolor: 'white' }}>
                                            <Lottie style={{ height: '300px' }} loop animationData={noData} />
                                        </Box>
                                    </>
                                    :
                                    <>
                                        <Stack mt={-2} direction={'column'} spacing={3}>
                                            <Stack>
                                                <Box>
                                                    <div>
                                                        {formData?.map((data, index) => (
                                                            <div key={index}>
                                                                <Stack
                                                                    sx={{
                                                                        bgcolor: 'white',
                                                                        boxShadow: '0px 0px 5px -2px',
                                                                        borderRadius: 3,
                                                                        mt: 4,
                                                                        pb: 4,
                                                                    }}
                                                                    px={5}
                                                                    direction={'column'}
                                                                    width={'100%'}
                                                                    key={index}
                                                                >
                                                                    <Box>
                                                                        <Stack mt={1}
                                                                            spacing={{ xs: 0, sm: 1, md: 1 }}
                                                                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                                                                            pb={{ xs: 5, sm: 4, md: 4 }}
                                                                            direction={{ xs: 'row', sm: 'row', md: 'row' }}
                                                                        >

                                                                            <Box>
                                                                                <Button
                                                                                    onClick={() => handleEdit(index, data?.id)}>Edit</Button>
                                                                            </Box>
                                                                            <Divider orientation="vertical" flexItem />
                                                                            <Box>
                                                                                <Button color='error' onClick={() => deleteWorkingDetails(data?.id)}>Delete</Button>
                                                                            </Box>
                                                                        </Stack>
                                                                    </Box>

                                                                    <Box sx={{
                                                                        width: '100%',
                                                                        mt: -5
                                                                    }}>
                                                                        <Typography variant="h5" sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }} mb={-1} gutterBottom>
                                                                            {data?.company.toUpperCase()}
                                                                        </Typography>
                                                                        {/* <Divider sx={{
                                                                    width: '100%',
                                                                    borderStyle: 'dashed'
                                                                }} /> */}
                                                                        <p>
                                                                            {data?.designation} at {data?.company} .
                                                                        </p>

                                                                        <Stack direction={'row'} mb={1} mt={-1} spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                                            <Box color={'rgb(89, 116, 162)'}>Company:</Box>
                                                                            <Box sx={{ marginLeft: 'auto' }}>
                                                                                <Typography>
                                                                                    {data?.company}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Stack>

                                                                        <Divider sx={{
                                                                            width: '100%',
                                                                        }} />

                                                                        {/* <Stack direction={'row'} py={1} spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                                            <Box color={'rgb(89, 116, 162)'}>Organisation Type:</Box>
                                                                            <Box sx={{ marginLeft: 'auto' }}>
                                                                                <Typography>
                                                                                    {data?.organisationType}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Stack> */}

                                                                        <Divider sx={{
                                                                            width: '100%',
                                                                        }} />

                                                                        <Stack direction={'row'} py={1} spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                                            <Box color={'rgb(89, 116, 162)'}>Location:</Box>
                                                                            <Box sx={{ marginLeft: 'auto' }}>
                                                                                <Typography>
                                                                                    {data?.location}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Stack>

                                                                        <Divider sx={{
                                                                            width: '100%',
                                                                        }} />

                                                                        {/* <Stack direction={'row'} mb={1} mt={-1} spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <Box color={'rgb(89, 116, 162)'}>Description:</Box>
                                                                    <Box sx={{ marginLeft: 'auto' }}>
                                                                        <Typography>
                                                                            {data.description}
                                                                        </Typography>
                                                                    </Box>
                                                                </Stack> */}

                                                                        <Stack direction={'row'} py={1} spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                            <Box color={'rgb(89, 116, 162)'}>Start Date:</Box>
                                                                            <Box sx={{ marginLeft: 'auto' }}>
                                                                                {data?.startDate ? (
                                                                                    <Typography>{dayjs(data.startDate).format('DD-MM-YYYY')}</Typography>
                                                                                ) : (
                                                                                    <Typography>Start Date Not Available</Typography>
                                                                                )}
                                                                            </Box>
                                                                        </Stack>

                                                                        <Divider sx={{
                                                                            width: '100%',
                                                                        }} />

                                                                        <Stack direction={'row'} py={1} spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                            <Box color={'rgb(89, 116, 162)'}>End Date:</Box>
                                                                            <Box sx={{ marginLeft: 'auto' }}>
                                                                                {data?.endDate ? (
                                                                                    <Typography>{dayjs(data.endDate).format('DD-MM-YYYY')}</Typography>
                                                                                ) : (
                                                                                    <Typography>End Date Not Available</Typography>
                                                                                )}
                                                                            </Box>

                                                                        </Stack>

                                                                    </Box>
                                                                </Stack>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    </>
                            }

                        </Box>
                    </>
                }
            </Stack>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                maxWidth="md"
                fullWidth
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(e) }}>
                    <DialogTitle>{"Add Working Details"}</DialogTitle>
                    <DialogContent>
                        <Stack>
                            <Box
                                // py={5}
                                sx={{
                                    width: '100%',
                                    // boxShadow: '0px 0px 5px -2px',
                                    borderRadius: '10px',
                                }}>
                                <Stack direction={'row'} spacing={3} px={5} py={1}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: '100%',
                                            }}
                                            id="outlined-company Name"
                                            label="company Name"
                                            name="company"
                                            value={formik.values.company}
                                            onChange={formik.handleChange}
                                            type="text"
                                            autoComplete="off"
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.company && formik.errors.company && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.company}</p>}
                                    </Box>
                                </Stack>
                                <Stack direction={'row'} spacing={3} px={5} py={1}>
                                    {/* <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Organization Type</InputLabel>
                                            <Select
                                                sx={{
                                                    width: '100%',
                                                }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="organisationType"
                                                label="Organization Type"
                                                value={formik.values.organisationType}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            >
                                                <MenuItem value='Sole Proprietorship'>Sole Proprietorship</MenuItem>
                                                <MenuItem value='Partnership'>Partnership</MenuItem>
                                                <MenuItem value='Limited Liability Partnership'>Limited Liability Partnership</MenuItem>
                                                <MenuItem value='Private Limited Companies'>Private Limited Companies</MenuItem>
                                                <MenuItem value='Public Limited Companies'>Public Limited Companies</MenuItem>
                                                <MenuItem value='One-Person Companies'>One-Person Companies</MenuItem>
                                                <MenuItem value='Section 8 company'>Section 8 company</MenuItem>
                                                <MenuItem value='Joint-Venture company'>Joint-Venture company</MenuItem>
                                                <MenuItem value='Non-Government Organization (NGO)'>Non-Government Organization (NGO)</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {formik.touched.organisationType && formik.errors.organisationType && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.organisationType}</p>}
                                    </Box> */}
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: '100%',
                                            }}
                                            id="outlined-Designation/Position"
                                            label="Designation/Position"
                                            name="designation"
                                            value={formik.values.designation}
                                            onChange={formik.handleChange}
                                            type="text"
                                            autoComplete="off"
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.designation && formik.errors.designation && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.designation}</p>}
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <Autocomplete
                                            className="location"
                                            style={{
                                                width: '100%',
                                                paddingLeft: '13px',
                                                height: '9vh',
                                                borderRadius: '6px',
                                                fontSize: '17px',
                                                fontWeight: '500',
                                                color: "#333",
                                                backgroundColor: "#F9FAFB",
                                                border: "1px solid #ced4da",
                                            }}
                                            apiKey={"AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc"}
                                            onPlaceSelected={(place) => {
                                                formik.setFieldValue('location', place?.formatted_address || '')
                                                formik.setFieldTouched('location', true);
                                            }}
                                            value={formik.values?.location}
                                            types={["address"]}
                                            componentRestrictions={{ country: "us" }}
                                            name="location"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.location && formik.errors.location && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.location}</p>}
                                    </Box>
                                </Stack>
                                <Stack direction={'row'} spacing={3} px={5} py={1}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                                            <DesktopDatePicker
                                                label="Start Date"
                                                inputFormat="DD/MM/YYYY"
                                                value={formik.values?.startDate}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue(
                                                        "startDate",
                                                        newValue
                                                    );
                                                    formik.setFieldTouched("startDate", true);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{
                                                            width: "100%"
                                                        }}
                                                        {...params}
                                                        name="startDate"
                                                        onBlur={formik.handleBlur}
                                                        error={formik.errors.startDate && formik.touched.startDate}
                                                    />
                                                )}
                                                minDate={minDate}
                                                maxDate={maxDate}
                                            />

                                        </LocalizationProvider>
                                        {/* {formik.touched.startDate && formik.errors.startDate && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.startDate}</p>} */}
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                                            <DesktopDatePicker
                                                label="End Date(Expected)"
                                                inputFormat="DD/MM/YYYY"
                                                value={formik.values?.endDate}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue(
                                                        "endDate",
                                                        newValue
                                                    );
                                                    formik.setFieldTouched("endDate", true);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{
                                                            width: "100%"
                                                        }}
                                                        {...params}
                                                        name="endDate"
                                                        onBlur={formik.handleBlur}
                                                        error={formik.errors.endDate && formik.touched.endDate}
                                                    />
                                                )}
                                                minDate={minDate}
                                            />

                                        </LocalizationProvider>
                                        {/* {formik.touched.endDate && formik.errors.endDate && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.endDate}</p>} */}
                                    </Box>
                                </Stack>
                                <Stack direction={'row'} spacing={2} px={5} py={1}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: '100%',
                                            }}
                                            id="outlined-description"
                                            label="Description"
                                            name="description"
                                            value={formik.values.description}
                                            onChange={formik.handleChange}
                                            type="text"
                                            multiline
                                            rows={4}
                                            maxRows={6}
                                            autoComplete="off"
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.description && formik.errors.description && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.description}</p>}
                                    </Box>
                                </Stack>
                            </Box>
                        </Stack>
                    </DialogContent>

                    <DialogActions>
                        <Stack spacing={1}
                            // sx={{ display: 'flex', justifyContent: 'flex-end' }} 
                            pr={7}
                            mb={2}
                            mt={-1}
                            direction={'row'}>
                            <LoadingButton
                                type='submit'
                                endIcon={<SaveIcon />}
                                loading={loadingBtn}
                                loadingPosition="end"
                                sx={{
                                    backgroundColor: "rgb(0, 171, 85)"
                                }}
                                variant="contained"
                            >
                                <span>{editIndex === -1 ? "Add" : "Save"}</span>
                            </LoadingButton>
                            {/* <Box>
                                <Button type='submit' variant="contained"
                                    sx={{
                                        backgroundColor: "rgb(0, 171, 85)"
                                    }}
                                    color="primary">
                                    {editIndex === -1 ? "Add" : "Save"}
                                </Button>
                            </Box> */}
                            <Divider disabled={formik.isSubmitting} orientation="vertical" flexItem />
                            <Box>
                                <Button onClick={handleClose} variant="contained" color="error">
                                    Cancel
                                </Button>
                            </Box>
                        </Stack>
                    </DialogActions>
                </form>
            </Dialog>

            <ToastContainer />
        </>
    )
}

export default WorkDetails
