/**
 * @scope api related to authentication
 * 
 * @author Rajendra
 * @since 15th Jan 2023
 */

import { API } from './provider';
import { V1, SIGNIN, SIGNUP, SIGNIN_GENERATE_OTP, PAYU, PAYU_GATEWAY_STATUS, PAYMENT, VERIFY_PAYMENT_GATEWAY, APPROVE_PAYMENT_STATUS, ADD_USER_TO_PAYMENT,DELETE_USER } from './endpoints';

export const signIn = (payload) => { return API.POST(V1, SIGNIN, payload); };
export const signUp = (payload) => { return API.POST(V1, SIGNUP, payload); };
export const generateSignInOTP = (payload) => { return API.POST(V1, SIGNIN_GENERATE_OTP, payload); };


// Including payment api's also.
export const paymentsList = () => { return API.GET(V1, PAYMENT); }
export const verifyPaymentGateway = (slug, key) => { return API.GET(V1, `${VERIFY_PAYMENT_GATEWAY}?slug=${slug}&key=${key}`); }
export const processToPayUGateway = (payload) => { return API.POST(V1, PAYU, payload); };
export const paymentResponse = (id) => { return API.GET(V1, PAYU_GATEWAY_STATUS.replace('{id}', id)); };
export const addUserToPayments = (payload) => { return API.POST(V1, ADD_USER_TO_PAYMENT,payload); };
export const approvePaymentStatus = (id,payload) => { return API.PUT(V1, APPROVE_PAYMENT_STATUS.replace('{id}', id),payload); };
export const deleteUser = (id) => { return API.DELETE(V1, DELETE_USER.replace('{id}', id)); };



