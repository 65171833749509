import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Slide from '@mui/material/Slide';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
    IconButton, Menu, MenuItem,
    Box,
    Stack,
    Divider,
    Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { css } from '@emotion/css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from "lottie-react";
import * as swal from 'sweetalert';
// icons
import SchoolIcon from '@mui/icons-material/School';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClassIcon from '@mui/icons-material/Class';
import EventIcon from '@mui/icons-material/Event';
import noData from '../../animations/Circle.json';
import scrollDown from '../../animations/Comp 1.json';

import { DeleteEducationDetails, CreateEducationalDetails, UpdateEducationDetails, ListEducationDetails, GetEducationalDetails } from '../../services/education';
import Loader from '../../loader/Loader';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

function EducationalDetails() {
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [editIndex, setEditIndex] = useState(-1);
    const [updateId, setUpdateId] = useState();
    const [initialValues, setInitialValues] = useState({
        institute: '',
        degree: '',
        fieldOfStudy: '',
        grade: '',
        startDate: null,
        endDate: null,
        description: '',
    });

    const validationSchema = Yup.object().shape({
        institute: Yup.string()
            .required('Institute Name is required'),
        degree: Yup.string()
            .required('Degree is required'),
        fieldOfStudy: Yup.string().required('Field Of Study is required'),
        // grade: Yup.string()
        // .required('Grade is required'),
        // startDate: Yup.string()
        // .required("Start Date is required").nullable(),
        // endDate: Yup.string()
        // .required('End Date is required').nullable(),
        description: Yup.string()
            .required('Description is required'),

    })

    const validate = (values) => {
        const errors = {};

        if (values?.startDate && values?.endDate) {
            const startDate = dayjs(values?.startDate);
            const endDate = dayjs(values?.endDate);

            if (endDate.isBefore(startDate)) {
                errors.endDate = "End date cannot be before start date";
            }

            if (startDate.isAfter(endDate)) {
                errors.startDate = "Start date cannot be after end date";
            }
        }

        return errors;
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        formik.resetForm();
        setEditIndex(-1);
    };

    const handleEdit = (index, id) => {
        setUpdateId(id);
        setEditIndex(index);
        formik.setValues(formData[index]);
        setOpen(true);
        handleCloseMenu();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const { id } = useParams();

    const getEducationalData = async () => {
        setTimeout(async () => {
            try {
                // const apiRes = await ListEducationDetails();
                const apiRes = await GetEducationalDetails(id);
                if (apiRes.status === 200) {
                    setLoading(true);
                    setFormData(apiRes?.data?.data || []);
                } else {
                    setLoading(false);
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
            } catch (err) {
                setLoading(false);
                toast.error('Something went wrong!, Please try again.', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        }, 500)
    };


    useEffect(() => {
        getEducationalData();
    }, [id])

    const deleteEducationalList = async (id) => {
        try {
            swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this !",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        confirmDelete()
                    }
                });

            const confirmDelete = async () => {
                const res = await DeleteEducationDetails(id)
                if (res.status === 201) {
                    swal("Educational detail deleted succesfully!", {
                        icon: "success",
                    });
                    getEducationalData();
                } else {
                    swal('Something went wrong!, Please try again.');
                }
            }
            handleCloseMenu();
        } catch (err) {
            setLoading(false);
            toast.error('Something went wrong!, Please try again.', {
                position: toast.POSITION.BOTTOM_LEFT
            })
        }
    }

    const formik = useFormik({
        initialValues,
        validate,
        validationSchema,
        onSubmit: async (values) => {
            if (editIndex === -1) {
                try {
                    setLoadingBtn(true)
                    const apiRes = await CreateEducationalDetails({
                        institute: values.institute,
                        degree: values.degree,
                        fieldOfStudy: values.fieldOfStudy,
                        grade: '',
                        startDate: values.startDate ? new Date(values.startDate).toISOString() : null,
                        endDate: values.endDate ? new Date(values.endDate).toISOString() : null,
                        description: values.description,
                        address: values.address,
                        occupation: values.occupation,
                    });
                    if (apiRes.status === 201) {
                        setLoading(true);
                        handleClose();
                        setFormData([...formData, values]);
                        toast.success('Details added successfully..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                        getEducationalData()
                    } else {
                        setLoading(false);
                        toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                    }
                } catch (err) {
                    setLoading(false);
                    toast.error('Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                } finally {
                    setLoadingBtn(false)
                }

            } else {
                const updatedData = [...formData];
                updatedData[editIndex] = values;
                setEditIndex(-1);
                try {
                    setLoadingBtn(true)
                    const res = await UpdateEducationDetails(updateId, {
                        institute: updatedData[editIndex].institute,
                        degree: updatedData[editIndex].degree,
                        fieldOfStudy: updatedData[editIndex].fieldOfStudy,
                        grade: '',
                        startDate: updatedData[editIndex].startDate ? new Date(updatedData[editIndex].startDate).toISOString() : null,
                        endDate: updatedData[editIndex].endDate ? new Date(updatedData[editIndex].endDate).toISOString() : null,
                        description: updatedData[editIndex].description,
                        address: updatedData[editIndex].address,
                        occupation: updatedData[editIndex].occupation,
                    })
                    if (res.status === 201) {
                        handleClose()
                        toast.success('Updated successfully..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                        getEducationalData();
                    }
                    else {
                        toast.error('Something went wrong!, Please try again.', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                    }
                } catch (err) {
                    toast.error('Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                    getEducationalData();
                } finally {
                    setLoadingBtn(false)
                }
            }
            formik.resetForm();
        }
    });

    const currentYear = dayjs().year();
    const minDate = `${currentYear - 72}-01-01`; // 72 years ago
    const currentDate = new Date();
    const maxDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;; // current year


    return (
        <div>
            <Stack
            >
                <Box width={'100%'} px={3} py={1} sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <Button type='submit' variant="contained" sx={{
                        backgroundColor: "rgb(0, 171, 85)"
                    }}
                        onClick={handleClickOpen}>
                        Add New
                    </Button>
                </Box>
                {!loading ?
                    <div>
                        <Loader />
                    </div> :
                    <>
                        <Box my={1} sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            {
                                formData.length === 0 ?
                                    <>
                                        <Box sx={{ bgcolor: 'white' }}>
                                            <Lottie style={{ height: '300px' }} loop animationData={noData} />
                                        </Box>
                                    </>
                                    :
                                    <>
                                        <Stack mt={-2} direction={'column'} spacing={3}>
                                            <Stack>
                                                <Box>
                                                    <div>
                                                        {formData?.map((data, index) => (
                                                            <div key={index}>
                                                                <Stack
                                                                    sx={{
                                                                        bgcolor: 'white',
                                                                        boxShadow: '0px 0px 5px -2px',
                                                                        borderRadius: 3,
                                                                        mt: 4,
                                                                        pb: 4,
                                                                    }}
                                                                    px={5}
                                                                    direction={'column'}
                                                                    width={'100%'}
                                                                >
                                                                    <Box>
                                                                        <Stack mt={1} spacing={{ xs: 0, sm: 1, md: 1 }}
                                                                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                                                                            pb={{ xs: 5, sm: 4, md: 4 }}
                                                                            direction={{ xs: 'row', sm: 'row', md: 'row' }}
                                                                        >
                                                                            <Box marginLeft={{ xs: 'auto', sm: 0 }}>
                                                                                <Button
                                                                                    onClick={() => handleEdit(index, data?.id)}>Edit</Button>
                                                                            </Box>
                                                                            <Divider orientation="vertical" flexItem />
                                                                            <Box marginRight={{ xs: 'auto', sm: 0 }}>
                                                                                <Button color='error'
                                                                                    onClick={() => deleteEducationalList(data?.id)}
                                                                                >Delete</Button>
                                                                            </Box>
                                                                        </Stack>
                                                                    </Box>

                                                                    <Box sx={{
                                                                        width: '100%',
                                                                        mt: -5
                                                                    }}>
                                                                        <Typography variant="h5" sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }} mb={-1} gutterBottom>
                                                                            {data?.institute.toUpperCase()}
                                                                        </Typography>

                                                                        <p>{data.description}</p>

                                                                        <Stack direction={'row'} mb={1} mt={-1} spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                                            <Box color={'rgb(89, 116, 162)'}>Institute Name:</Box>
                                                                            <Box sx={{ marginLeft: 'auto' }}>
                                                                                <Typography>
                                                                                    {data?.institute}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Stack>
                                                                        <Divider sx={{
                                                                            width: '100%',
                                                                            // borderStyle: 'dashed'
                                                                        }} />

                                                                        <Stack direction={'row'} py={1} spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                                            <Box color={'rgb(89, 116, 162)'}>Degree:</Box>
                                                                            <Box sx={{ marginLeft: 'auto' }}>
                                                                                <Typography>
                                                                                    {data?.degree}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Stack>

                                                                        <Divider sx={{
                                                                            width: '100%',
                                                                        }} />

                                                                        <Stack direction={'row'} py={1} sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                                            <Box color={'rgb(89, 116, 162)'}>Field of study:</Box>
                                                                            <Box sx={{ marginLeft: 'auto' }}>
                                                                                <Typography>
                                                                                    {data?.fieldOfStudy}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Stack>

                                                                        <Divider sx={{
                                                                            width: '100%',
                                                                        }} />

                                                                        {/* <Stack direction={'row'} py={1} spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Box color={'rgb(89, 116, 162)'}>Grade:</Box>
                                                                <Box sx={{ marginLeft: 'auto' }}>
                                                                    <Typography>
                                                                        {data.grade}
                                                                    </Typography>
                                                                </Box>
                                                            </Stack>

                                                            <Divider sx={{
                                                                width: '100%',
                                                            }} /> */}

                                                                        <Stack direction={'row'} py={1} spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                                            <Box color={'rgb(89, 116, 162)'}>Start Date:</Box>
                                                                            <Box sx={{ marginLeft: 'auto' }}>
                                                                                {data?.startDate ? (
                                                                                    <Typography>{dayjs(data.startDate).format('DD-MM-YYYY')}</Typography>
                                                                                ) : (
                                                                                    <Typography>Start Date Not Available</Typography>
                                                                                )}
                                                                            </Box>
                                                                        </Stack>

                                                                        <Divider sx={{
                                                                            width: '100%',
                                                                        }} />

                                                                        <Stack direction={'row'} spacing={2} mt={2} sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                                            <Box color={'rgb(89, 116, 162)'}>End Date:</Box>
                                                                            <Box sx={{ marginLeft: 'auto' }}>
                                                                                {data?.endDate ? (
                                                                                    <Typography>{dayjs(data.endDate).format('DD-MM-YYYY')}</Typography>
                                                                                ) : (
                                                                                    <Typography>End Date Not Available</Typography>
                                                                                )}
                                                                            </Box>

                                                                        </Stack>

                                                                    </Box>
                                                                </Stack>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    </>
                            }

                        </Box >
                    </>
                }
            </Stack >

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                aria-describedby="alert-dialog-slide-description"
            >
                <form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(e) }}>
                    <DialogTitle>{"Add Educational Details"}</DialogTitle>
                    <DialogContent>
                        <Stack direction={'row'} spacing={3}>
                            <Box
                                sx={{
                                    width: '100%',
                                    borderRadius: '10px',
                                }}>
                                <Stack direction={'row'} spacing={3} px={5} py={1}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: '100%',
                                            }}
                                            id="outlined-password-input"
                                            label="Institute Name"
                                            name="institute"
                                            value={formik.values.institute}
                                            onChange={formik.handleChange}
                                            type="text"
                                            autoComplete="off"
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.institute && formik.errors.institute && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.institute}</p>}
                                    </Box>
                                </Stack>
                                <Stack direction={'row'} spacing={3} px={5} py={1}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: '100%',
                                            }}
                                            id="outlined-password-input"
                                            label="Field Of Study"
                                            name="fieldOfStudy"
                                            value={formik.values.fieldOfStudy}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            type="text"
                                            autoComplete="off"
                                        />
                                        {formik.touched.fieldOfStudy && formik.errors.fieldOfStudy && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.fieldOfStudy}</p>}
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: '100%',
                                            }}
                                            id="outlined-password-input"
                                            label="Degree"
                                            name="degree"
                                            value={formik.values.degree}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            type="text"
                                            autoComplete="off"
                                        />
                                        {formik.touched.degree && formik.errors.degree && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.degree}</p>}
                                    </Box>
                                    {/* <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: '100%',
                                            }}
                                            id="outlined-password-input"
                                            label="Grade"
                                            name="grade"
                                            value={formik.values.grade}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            type="text"
                                            autoComplete="off"
                                        />
                                        {formik.touched.grade && formik.errors.grade && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.grade}</p>}
                                    </Box> */}
                                </Stack>
                                <Stack direction={'row'} spacing={3} px={5} py={1}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                                            <DesktopDatePicker
                                                label="Start Date"
                                                inputFormat="DD/MM/YYYY"
                                                value={formik.values?.startDate}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue(
                                                        "startDate",
                                                        newValue
                                                    );
                                                    formik.setFieldTouched("startDate", true);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{
                                                            width: "100%"
                                                        }}
                                                        {...params}
                                                        name="startDate"
                                                        onBlur={formik.handleBlur}
                                                        error={formik.errors.startDate && formik.touched.startDate}
                                                    />
                                                )}
                                                minDate={minDate}
                                                maxDate={maxDate}
                                            />

                                        </LocalizationProvider>
                                        {/* {formik.touched.startDate && formik.errors.startDate && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.startDate}</p>} */}
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                                            <DesktopDatePicker
                                                label="End Date(Expected)"
                                                inputFormat="DD/MM/YYYY"
                                                value={formik.values?.endDate}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue(
                                                        "endDate",
                                                        newValue
                                                    );
                                                    formik.setFieldTouched("endDate", true);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{
                                                            width: "100%"
                                                        }}
                                                        {...params}
                                                        name="endDate"
                                                        onBlur={formik.handleBlur}
                                                        error={formik.errors.endDate && formik.touched.endDate}
                                                    />
                                                )}
                                                minDate={minDate}
                                            />

                                        </LocalizationProvider>
                                        {/* {formik.touched.endDate && formik.errors.endDate && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.endDate}</p>} */}
                                    </Box>
                                </Stack>
                                <Stack direction={'row'} spacing={2} px={5} py={1}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: '100%',
                                            }}
                                            id="outlined-description"
                                            label="Description"
                                            type="text"
                                            multiline
                                            rows={4}
                                            maxRows={6}
                                            name="description"
                                            value={formik.values.description}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            autoComplete="off"
                                        />
                                        {formik.touched.description && formik.errors.description && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.description}</p>}
                                    </Box>
                                </Stack>
                            </Box>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Stack spacing={1}
                            pr={7}
                            mb={2}
                            mt={-1}
                            direction={'row'}>
                            <LoadingButton
                                type='submit'
                                endIcon={<SaveIcon />}
                                loading={loadingBtn}
                                loadingPosition="end"
                                sx={{
                                    backgroundColor: "rgb(0, 171, 85)"
                                }}
                                variant="contained"
                            >
                                <span>{editIndex === -1 ? "Add" : "Save"}</span>
                            </LoadingButton>
                            {/* <Box>
                                <Button type='submit' loading={loading} variant="contained" sx={{
                                    backgroundColor: "rgb(0, 171, 85)"
                                }}
                                    color="primary">
                                    {editIndex === -1 ? "Add" : "Save"}
                                </Button>
                            </Box> */}
                            <Divider
                                disabled={formik.isSubmitting}
                                orientation="vertical"
                                flexItem />
                            <Box>
                                <Button onClick={handleClose} variant="contained" color="error">
                                    Cancel
                                </Button>
                            </Box>
                        </Stack>
                    </DialogActions>
                </form>
            </Dialog>
            <ToastContainer />




        </div >
    )
}

export default EducationalDetails
