/**
 * API versions 
 */

export const V1 = 'v1';

/**
 * API endpoints
 */
export const ANALYTICS_COUNTER = "analytics/counters";

export const SIGNIN = "authentication/signin";
export const SIGNUP = "authentication/signup";
export const SIGNIN_GENERATE_OTP = "authentication/generate-signin-otp";

export const USERS = "users";
export const GET_USER_DETAILS = "users/{id}";
export const UPDATE_USER_DETAILS = "users/{id}";

export const PAYMENT = "payments";
export const VERIFY_PAYMENT_GATEWAY = "payments/verify-gateway";
export const PAYU = "payments/payu";
export const PAYU_GATEWAY_RESPONSE = "payments/payu/gateway/response";
export const PAYU_GATEWAY_STATUS = "payments/payu/gateway/status/{id}";
export const ADD_USER_TO_PAYMENT = "payments/save_user";
export const APPROVE_PAYMENT_STATUS = "payments/payment-bypass/{id}";
export const DELETE_USER = "payments/{id}";

export const CREATE_EDUCATIONAL_DETAILS = "educations";
export const GET_EDUCATIONAL_DETAILS = "educations/{id}";
export const LIST_EDUCATIONAL_DETAILS = "educations";
export const DELETE_EDUCATIONAL_DETAILS = "educations/{id}";
export const UPDATE_EDUCATIONAL_DETAILS = "educations/{id}";

export const CREATE_WORKING_DETAILS = "working-experience";
export const GET_WORKING_DETAILS = "working-experience/{id}";
export const LIST_WORKING_DETAILS = "working-experience";
export const DELETE_WORKING_DETAILS = "working-experience/{id}";
export const UPDATE_WORKING_DETAILS = "working-experience/{id}";

export const POST_OR_UPDATE_SOCIAL_DETAILS = "social-media";
export const LIST_SOCIAL_LINKS = "social-media";
export const GET_SOCIAL_LINKS = "social-media/{id}";

export const CREATE_NOTICES = "notices";
export const GET_NOTICES = "notices/{id}";
export const LIST_NOTICES = "notices";
export const DELETE_NOTICES = "notices/{id}";
export const UPDATE_NOTICES = "notices/{id}";

export const CREATE_EVENT = "calendar";
export const GET_EVENT = "calendar/{id}";
export const LIST_EVENT = "calendar";
export const DELETE_EVENT = "calendar/{id}";
export const UPDATE_EVENT = "calendar/{id}";

export const CREATE_POST = "feed";
export const GET_POST = "feed/{id}";
export const LIST_POSTS = "feed";
export const DELETE_POST = "feed/{id}";
export const UPDATE_POST = "feed/{id}";

export const UPLOAD_PROFILE = "users/upload-profile/{id}";

export const ROLES = "roles";