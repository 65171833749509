import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    InstapaperIcon
} from "react-share";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DataTable from "react-data-table-component";
import NorthIcon from '@mui/icons-material/North';
import EditIcon from '@mui/icons-material/Edit';
import IosShareIcon from '@mui/icons-material/IosShare';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CircularProgress from '@mui/material/CircularProgress';
// import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import TwitterIcon from '@mui/icons-material/Twitter';
import { ToastContainer, toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import * as Yup from 'yup';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
// @mui
import {
    Box,
    Grid,
    Card,
    Modal,
    Table,
    Stack,
    Paper,
    Avatar,
    Select,
    Dialog,
    Button,
    Tooltip,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TextField,
    FormGroup,
    TableBody,
    TableCell,
    Container,
    InputLabel,
    Typography,
    IconButton,
    DialogTitle,
    FormControl,
    DialogContent,
    DialogActions,
    InputAdornment,
    TableContainer,
    TablePagination,
    FormControlLabel,
    DialogContentText,
} from '@mui/material';
// components

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Autocomplete from "react-google-autocomplete";
import { signUp } from '../../services/authentication';
import { ListRoles } from '../../services/role';
import { GetUserDetails, listAllUsers } from '../../services/users';
import { API_BASE_URL } from '../../Setting';
import Loader from '../../loader/Loader';

import '../../css/main.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Alumni() {
    const customStyles = {
        cells: {
            style: {
                fontSize: '16px', // Adjust the font size as needed
            },
        },
    };

    const tableCustomStyles = {
        cells: {
            style: {
                fontSize: '16px', // Adjust the font size as needed
            },
        },
        headRow: {
            style: {
                fontSize: '15px',
            },
        },
        rows: {
            style: {
                backgroundColor: 'white',
                fontSize: '15px',
            },
            stripedStyle: {
                backgroundColor: "#fafafa"
            }
        },
    }

    const columns = [
        {
            name: "Name",
            selector: row => row.name,
            sortable: true,
            minWidth: "160px",
        },
        {
            name: "Mobile No.",
            selector: row => row.mobileNo,
            sortable: false,
        },
        {
            name: "Email",
            selector: row => row.email,
            sortable: true,
            minWidth: "250px",
        },
        {
            name: "Passing Out Class",
            selector: row => row.passingOutClass,
            sortable: true,
            minWidth: "180px"
        },
        {
            name: "Profile",
            selector: row => row.avatar,
            sortable: false,
            // right: true,
            cell: (row) => <Avatar src={API_BASE_URL + row.avatar} />,
            minWidth: "50px"
        },
        {
            name: 'Action',
            sortable: false,
            right: false,
            cell: (row) => (
                <>
                    {roleId === '1' ?
                        <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(row.id, event)}>
                            <MoreVertIcon />
                        </IconButton>
                        :
                        <IconButton onClick={(event) => handleOpenModal(row.id)}>
                            <VisibilityIcon />
                        </IconButton>
                    }
                </>
            ),
        },
    ];

    const [selectedRoleName, setSelectedRoleName] = useState();
    const [selectedRoleId, setSelectedRoleId] = useState(0);
    const [selectedYear, setSelectedYear] = useState('');
    const [viewAlumni, setViewAlumni] = useState();

    const [loading, setLoading] = useState(false);
    const [alumni, setAlumni] = useState([]);
    const [open, setOpen] = useState(null);
    const [editId, setEditId] = useState(null);
    const [roles, setRoles] = useState();

    const [openModal, setOpenModal] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState();
    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
        formik.resetForm();
    };

    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleOptionChange = (event) => {
        const option = event.target.name;
        if (option === 'All') {
            const allOptions = columns
                .filter((column) => column.name !== 'Action')
                .map((column) => column.name);
            if (selectedOptions?.length === allOptions?.length) {
                setSelectedOptions([]);
            } else {
                setSelectedOptions(allOptions);
            }
            setSelectAll(!selectAll);
        } else {
            if (selectedOptions?.includes(option)) {
                setSelectedOptions(selectedOptions?.filter((item) => item !== option));
            } else {
                setSelectedOptions([...selectedOptions, option]);
            }
            setSelectAll(false);
        }
    };

    useEffect(() => {
        const allOptions = columns
            .filter((column) => column.name !== 'Action')
            .map((column) => column.name);
        setSelectedOptions([]);
        setSelectAll(false);
    }, []);

    const openExport = Boolean(anchorEl);

    const handleOpenModal = async (id) => {
        // setEditId(id)
        setOpenModal(true)
        try {
            const apiRes = await GetUserDetails(id)
            setViewAlumni(apiRes?.data?.data)
        } catch {
            alert('Something went wrong!, Please try again..!')
        }
    }
    const handleCloseModal = () => {
        setOpenModal(false);
        setOpen(null);
    }

    const handleOpenMenu = (id, event) => {
        setEditId(id)
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const navigate = useNavigate();

    const id = localStorage.getItem('id')
    const roleId = localStorage.getItem('roleId')

    const getRoles = async () => {
        setTimeout(async () => {
            try {
                const apiRes = await ListRoles();
                if (apiRes.status === 200) {
                    setLoading(true);
                    setRoles(apiRes?.data?.data || []);
                    const AlumniRole = apiRes?.data?.data.filter((item) => item?.role === "Alumni");

                    if (AlumniRole) {
                        const roleId = AlumniRole[0].id;
                        setSelectedRoleId(roleId)
                    }

                } else {
                    setLoading(false);
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
            } catch (err) {
                setLoading(false);
                toast.error('Something went wrong!, Please try again..!', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        }, 500);
    };

    const listAllAlumni = async () => {
        setTimeout(async () => {
            try {
                const apiRes = await listAllUsers({ roleId: selectedRoleId });
                if (apiRes.status === 200) {
                    setLoading(true);
                    // const filteredData = apiRes?.data?.data?.filter(item => item?.id !== id).map(item => ({ ...item }));
                    const filteredData = apiRes?.data?.data?.filter(item => item?.id !== id && item?.isPaymentCompleted !== false)
                        .map((item, index) => ({
                            id: item?.id,
                            slno: index + 1,
                            name: `${item?.firstName} ${item?.lastName}`,
                            firstName: item?.firstName,
                            lastName: item?.lastName,
                            email: item?.email,
                            mobileNo: item?.mobileNo,
                            passingOutClass: item?.passingOutClass || 'Not available',
                            avatar: item?.avatar,
                        }));
                    // const searchedData = searchText
                    //     ? filteredData.filter((row) => row.name.toLowerCase().includes(searchText.toLowerCase()))
                    //         .map((item, index) => ({ ...item, slno: index + 1 }))
                    //     : filteredData;

                    // setAlumni(searchedData || []);
                    setAlumni(filteredData || []);
                } else {
                    setLoading(false);
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
            } catch (err) {
                setLoading(false);
                toast.error('Something went wrong!, Please try again..!', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        }, 500);
    };

    useEffect(() => {
        if (selectedRoleId) {
            listAllAlumni();
        }
    }, [selectedRoleId])

    useEffect(() => {
        getRoles();
    }, [])

    const exportToExcel = () => {
        if (selectedOptions.length === 0) {
            return;
        }

        const selectedColumns = columns.filter((column) =>
            selectedOptions.includes(column.name)
        );

        const worksheetData = alumni?.map((row) =>
            selectedColumns.map((column) => {
                if (typeof column.selector === "function") {
                    return column.selector(row);
                }
                return row[column.selector];
            })
        );

        const header = selectedColumns.map((column) => column.name);
        const worksheet = XLSX.utils.aoa_to_sheet([header, ...worksheetData]);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const data = new Blob([excelBuffer], { type: "application/vnd.ms-excel" });
        saveAs(data, "Alumni_data.xlsx");

        setSelectedOptions([]);
        setSelectAll(false);
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is required'),
        lastName: Yup.string()
            // .length(3, 'Last Name must be greater than 3 characters')
            .required('Last Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        mobile: Yup.string()
            .length(10, 'Mobile Number must be 10 digit')
            .required('Mobile Number is required'),
        dob: Yup.string()
            .required("Date of birth is required").nullable(),
        passingYear: Yup.string()
            .required('Passing out year is required'),
        passingOutClass: Yup.string()
            .required('Passing out class is required'),
        // anniversaryDate: Yup.string()
        //     .required('Anniversary date is required'),
        location: Yup.string()
            .required('Location is required'),
        occupation: Yup.string()
            .required('Occupation is required'),
        address: Yup.string()
            .required('Addres is required'),
        modeOfPayment: Yup.string()
            .required('Mode of payment is required'),
        amount: Yup.string()
            .required('Amount is required'),
    })

    const validate = (values) => {
        const errors = {};

        if (values?.dob && values?.passingYear) {
            const dob = dayjs(values?.dob);
            const passingYear = dayjs(`01/01/${values?.passingYear}`, 'DD/MM/YYYY');

            if (passingYear?.isBefore(dob)) {
                errors.passingYear = "Passing year cannot be before date of birth";
            }
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            dob: '',
            anniversaryDate: '',
            passingYear: '',
            passingOutClass: '',
            location: '',
            address: '',
            occupation: '',
            note: '',
            modeOfPayment: '',
            amount: '',
        },
        validationSchema,
        validate,
        onSubmit: async (values) => {
            try {
                const apiRes = await signUp({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    mobileNoPrefix: "+91",
                    mobileNo: values.mobile,
                    dob: new Date(values.dob),
                    anniversaryDate: new Date(values.anniversaryDate),
                    passingYear: values.passingYear,
                    passingOutClass: values.passingOutClass,
                    location: values.location,
                    address: values.address,
                    occupation: values.occupation,
                    amount: values.amount,
                    note: values.note,
                    otpBypass: true,
                });
                if (apiRes.status === 200) {
                    setLoading(true);
                    toast.success('Alumni added successfully..!', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                    listAllAlumni();
                    formik.resetForm();
                    handleClose();
                } else {
                    setLoading(false);
                    if (apiRes?.data?.message === "Sorry, EmailId already exist.") {
                        navigate('/auth/sign-in')
                    }
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
            } catch (err) {
                setLoading(false);
                toast.error('Something went wrong!, Please try again.', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        },
    });

    const currentYear = dayjs().year();
    const minDate = `${currentYear - 72}-01-01`; // 72 years ago
    const maxDate = `${currentYear}-01-01`; // current year

    const handleModeOfPaymentChange = (event) => {
        const selectedMode = event.target.value;
        const note = selectedMode === 'By Cash' ? 'Fees collected through Institution authority' : '';
        formik.setFieldValue('modeOfPayment', selectedMode);
        formik.setFieldValue('note', note);
    };

    return (
        <>
            <Helmet>
                <title> Alumni </title>
            </Helmet>

            <Container maxWidth="xxl">
                <Grid container spacing={2}>
                    <Grid item md={8} className='title_page'>
                        <Typography variant="h4">
                            Alumni
                        </Typography>
                    </Grid>

                    <Grid item md={4}>
                        <Stack direction={'row'}>
                            <Box className='search_area'
                            >
                                <TextField
                                    size="small"
                                    label="Search filter"
                                    variant="outlined"
                                    value={searchText}
                                    onChange={handleSearch}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            {roleId === '1' &&
                                <>
                                    <Box
                                        sx={{
                                            '&:hover': {
                                                cursor: 'pointer'
                                            },
                                            marginLeft: '10px'
                                        }}>
                                        <Tooltip title="Export to excel">
                                            <IconButton sx={{ color: '#d7b56d' }} aria-label="add to shopping cart" onClick={handleOpenPopover}>
                                                <IosShareIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Popover
                                            open={openExport}
                                            anchorEl={anchorEl}
                                            onClose={handleClosePopover}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "left",
                                            }}
                                        >
                                            <FormControl>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectAll || (selectedOptions?.length === columns.length - 1)}
                                                                onChange={handleOptionChange}
                                                                name="All"
                                                                id="checkbox-all"
                                                                sx={{
                                                                    mx: 2,
                                                                }}
                                                            />
                                                        }
                                                        label="All"
                                                        htmlFor="checkbox-all"
                                                    />
                                                    {columns
                                                        ?.filter((column) => column.name !== 'Action')
                                                        .map((column) => (
                                                            <FormControlLabel
                                                                key={column.name}
                                                                control={
                                                                    <Checkbox
                                                                        checked={selectedOptions?.includes(column.name)}
                                                                        onChange={handleOptionChange}
                                                                        name={column.name}
                                                                        id={`checkbox-${column.name}`}
                                                                        sx={{
                                                                            mx: 2,
                                                                        }}
                                                                    />
                                                                }
                                                                label={column.name}
                                                                htmlFor={`checkbox-${column.name}`}
                                                            />
                                                        ))}
                                                </FormGroup>
                                                <Button sx={{ backgroundColor: '#dbecff' }} onClick={exportToExcel}><InsertDriveFileIcon />Export to Excel</Button>
                                            </FormControl>
                                        </Popover>
                                    </Box>
                                    <Box
                                        sx={{
                                            '&:hover': {
                                                cursor: 'pointer'
                                            },
                                            // marginLeft: '5px'
                                        }}
                                    >
                                        <Tooltip title="Add new user">
                                            <IconButton sx={{ color: '#d7b56d' }} aria-label="add to shopping cart" onClick={handleClickOpen}>
                                                <AddCircleOutlineIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </>
                            }
                            <Dialog open={openDialog} onClose={handleClose}>
                                <DialogTitle>
                                    Add Alumni
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2, position: 'absolute', top: 0, right: 0 }}
                                        onClick={handleClose}
                                    >
                                        <CloseIcon
                                            sx={{ cursor: 'pointer' }}
                                        />
                                    </Box>
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        <form onSubmit={formik.handleSubmit}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Typography variant="h6" gutterBottom>
                                                        Basic Information
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <Box>
                                                        <TextField
                                                            sx={{
                                                                width: '100%',
                                                            }}
                                                            id="outlined-firstName-input"
                                                            label="First Name"
                                                            name="firstName"
                                                            type={'text'}
                                                            onChange={formik.handleChange}
                                                            value={formik.values.firstName}
                                                            onBlur={formik.handleBlur}
                                                            autoComplete="off"

                                                        />
                                                        {formik.touched.firstName && formik.errors.firstName && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.firstName}</p>}
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={6}>
                                                    <Box>
                                                        <TextField
                                                            sx={{
                                                                width: '100%',
                                                            }}
                                                            id="outlined-lastName-input"
                                                            label="Last Name"
                                                            name="lastName"
                                                            type={'text'}
                                                            onChange={formik.handleChange}
                                                            value={formik.values.lastName}
                                                            onBlur={formik.handleBlur}
                                                            autoComplete="off"
                                                        />
                                                        {formik.touched.lastName && formik.errors.lastName && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.lastName}</p>}
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={6}>
                                                    <Box>
                                                        <TextField
                                                            sx={{
                                                                width: '100%',
                                                            }}
                                                            id="outlined-email-input"
                                                            label="Email Address"
                                                            name="email"
                                                            type={'email'}
                                                            onChange={formik.handleChange}
                                                            value={formik.values.email}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.email && formik.errors.email && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.email}</p>}
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={6} className="phone_field">
                                                    <Box>
                                                        <TextField
                                                            sx={{
                                                                width: '100%',
                                                            }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        +91
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            id="outlined-mobile-input"
                                                            label="Mobile Number"
                                                            type="tel"
                                                            name="mobile"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.mobile}
                                                            onBlur={formik.handleBlur}
                                                            autoComplete="off"
                                                        />
                                                        {formik.touched.mobile && formik.errors.mobile && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.mobile}</p>}
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={6} className="btn_icons">
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                        }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DesktopDatePicker
                                                                label="Date Of Birth"
                                                                inputFormat="DD/MM/YYYY"
                                                                value={formik.values?.dob}
                                                                onChange={(newValue) => {
                                                                    formik.setFieldValue(
                                                                        "dob",
                                                                        newValue
                                                                    );
                                                                    formik.setFieldTouched("dob", true);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        sx={{
                                                                            width: "100%"
                                                                        }}
                                                                        {...params}
                                                                        name="dob"
                                                                        onBlur={formik.handleBlur}
                                                                        error={formik.errors.dob && formik.touched.dob}
                                                                    />
                                                                )}
                                                                minDate={minDate}
                                                                maxDate={maxDate}
                                                            />
                                                        </LocalizationProvider>
                                                        {formik.touched.dob && formik.errors.dob && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.dob}</p>}
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={6} className="btn_icons">
                                                    <Box>
                                                        <TextField
                                                            sx={{
                                                                width: '100%',
                                                            }}
                                                            id="outlined-occupation-input"
                                                            label="Occupation"
                                                            name="occupation"
                                                            type={'text'}
                                                            onChange={formik.handleChange}
                                                            value={formik.values.occupation}
                                                            onBlur={formik.handleBlur}
                                                            autoComplete="off"
                                                        />
                                                        {formik.touched.occupation && formik.errors.occupation && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.occupation}</p>}
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={6} className="btn_icons">
                                                    <Box sx={{ width: '100%' }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                                views={['year']}
                                                                label="Passing Year"
                                                                maxDate={dayjs().subtract(1, 'year')}
                                                                value={formik.values.passingYear || ''}
                                                                onBlur={formik.handleBlur}
                                                                onChange={(newValue) => {
                                                                    formik.setFieldValue('passingYear', newValue?.format('YYYY'))
                                                                }}
                                                                renderInput={(params) => <TextField
                                                                    sx={{
                                                                        width: '100%',
                                                                    }}
                                                                    {...params}
                                                                    name='passingYear'
                                                                    onBlur={formik.handleBlur}
                                                                    error={formik.errors.passingYear && formik.touched.passingYear}
                                                                />}
                                                                minDate={minDate}
                                                            />

                                                            {formik.touched.passingYear && formik.errors.passingYear && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.passingYear}</p>}
                                                        </LocalizationProvider>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={6}>
                                                    <Box>
                                                        <Autocomplete
                                                            className="location"
                                                            style={{
                                                                width: '100%',
                                                                paddingLeft: '13px',
                                                                height: '9vh',
                                                                borderRadius: '6px',
                                                                fontSize: '17px',
                                                                fontWeight: '500',
                                                                color: "#333",
                                                                backgroundColor: "#F9FAFB",
                                                                border: "1px solid #ced4da",
                                                            }}


                                                            apiKey={"AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc"}
                                                            onPlaceSelected={(place) => {
                                                                formik.setFieldValue('location', place?.formatted_address)
                                                            }}
                                                            types={["address"]}
                                                            componentRestrictions={{ country: "us" }}
                                                            placeholder={'Enter a Location'}
                                                            name="location"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.location && formik.errors.location && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.location}</p>}
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={6}>
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <FormControl fullWidth >
                                                            <InputLabel id="demo-simple-select-label">Passing Out Class</InputLabel>
                                                            <Select
                                                                sx={{
                                                                    height: '56px',
                                                                }}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                name="passingOutClass"
                                                                value={formik.values.passingOutClass}
                                                                label="Passing Out Class"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            >
                                                                <MenuItem value={'Matriculation'}>Matriculation</MenuItem>
                                                                <MenuItem value={'Senior Secondary/Plus Two'}>Senior Secondary/Plus Two</MenuItem>
                                                                <MenuItem value={'Other'}>Other</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {formik.touched.passingOutClass && formik.errors.passingOutClass && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.passingOutClass}</p>}
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={6}>
                                                    <Box sx={{ width: '100%' }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                                views={['day', 'month']} // Only display day and month
                                                                label="Anniversary Date"
                                                                value={formik.values.anniversaryDate || null}
                                                                onBlur={formik.handleBlur}
                                                                onChange={(newValue) => {
                                                                    formik.setFieldValue('anniversaryDate', newValue);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        sx={{
                                                                            width: '100%',
                                                                        }}
                                                                        {...params}
                                                                        name="anniversaryDate"
                                                                        onBlur={formik.handleBlur}
                                                                        error={formik.errors.anniversaryDate && formik.touched.anniversaryDate}
                                                                    />
                                                                )}
                                                            // maxDate={dayjs().subtract(1, 'day')} // Optional: To prevent future dates (anniversaries)
                                                            />

                                                            {/* {formik.touched.anniversaryDate && formik.errors.anniversaryDate && (
                                                                <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>
                                                                    {formik.errors.anniversaryDate}
                                                                </p>
                                                            )} */}
                                                        </LocalizationProvider>
                                                    </Box>

                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <TextField
                                                            sx={{
                                                                width: '100%',
                                                            }}
                                                            id="outlined-password-input"
                                                            label="Address"
                                                            name="address"
                                                            value={formik.values.address}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            type="text"
                                                            multiline
                                                            rows={4}
                                                            maxRows={6}
                                                            autoComplete="off"
                                                        />
                                                        {formik.touched.address && formik.errors.address && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.address}</p>}
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Typography variant="h6" gutterBottom>
                                                        Payment Information
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={6}>
                                                    <Box>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Mode of Payment</InputLabel>
                                                            <Select
                                                                sx={{
                                                                    height: '56px',
                                                                }}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                name="modeOfPayment"
                                                                value={formik.values.modeOfPayment}
                                                                label="Mode of Payment"
                                                                onChange={handleModeOfPaymentChange}
                                                                onBlur={formik.handleBlur}
                                                            >
                                                                <MenuItem value={'By Cash'}>By Cash</MenuItem>
                                                                {/* Add more options as needed */}
                                                            </Select>
                                                        </FormControl>
                                                        {formik.touched.modeOfPayment && formik.errors.modeOfPayment && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.modeOfPayment}</p>}
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={6}>
                                                    <Box>
                                                        <TextField
                                                            sx={{
                                                                width: '100%',
                                                            }}
                                                            id="outlined-note-input"
                                                            label="Note"
                                                            name="note"
                                                            type="text"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.note}
                                                            onBlur={formik.handleBlur}
                                                            autoComplete="off"
                                                            InputLabelProps={{
                                                                shrink: formik?.values?.note && true,
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <Box>
                                                        <TextField
                                                            sx={{
                                                                width: '100%',
                                                            }}
                                                            id="outlined-amount-input"
                                                            label="Amount"
                                                            name="amount"
                                                            type="number"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.amount}
                                                            onBlur={formik.handleBlur}
                                                            autoComplete="off"
                                                            InputLabelProps={{
                                                                shrink: formik?.values?.amount && true,
                                                            }}
                                                        />
                                                    </Box>
                                                    {formik.touched.amount && formik.errors.amount && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.amount}</p>}
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Box ml={'auto'}>
                                                        <LoadingButton
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            endIcon={<SaveIcon />}
                                                            variant="contained"
                                                        // loading={loading}
                                                        >
                                                            Save
                                                        </LoadingButton>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </DialogContentText>
                                </DialogContent>
                                {/* <DialogActions>
                                    <Button onClick={handleClose}>Cancel</Button>
                                    <Button onClick={handleClose}>Subscribe</Button>
                                </DialogActions> */}
                            </Dialog>
                        </Stack>
                    </Grid>
                </Grid>

                {alumni?.length <= 0 ?
                    <div>
                        <Loader />
                    </div> :
                    <>

                        <Card className='alumni_data'>
                            <DataTable
                                columns={columns}
                                data={alumni.filter((row) =>
                                    row.name?.toLowerCase().includes(searchText.toLowerCase()) ||
                                    row.passingOutClass?.toLowerCase().includes(searchText.toLowerCase())
                                )}
                                defaultSortField="name"
                                sortIcon={<NorthIcon />}
                                pagination
                                paginationPerPage={50}
                                paginationRowsPerPageOptions={[50, 100, 500]}
                                selectableRows={false}
                                customStyles={tableCustomStyles}
                                fixedHeader
                                striped
                            />
                        </Card>
                    </>
                }

            </Container>
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                {roleId === '1' &&
                    <>
                        <MenuItem onClick={(event) => handleOpenModal(editId)}>
                            <VisibilityIcon sx={{ mr: 2 }} />
                            View
                        </MenuItem>
                        <MenuItem onClick={() => navigate(`/profile/${editId}`)}>
                            <EditIcon sx={{ mr: 2 }} />
                            Edit
                        </MenuItem>
                    </>
                }
            </Popover>



            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    ...style,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Stack direction={'column'} className='edit_popup'>
                        {/* Close button/icon */}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2, position: 'absolute', top: 0, right: 0 }}
                            onClick={handleCloseModal}
                        >
                            <CloseIcon
                                sx={{ cursor: 'pointer' }}
                            />
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <Box
                                    sx={{
                                        width: 90,
                                        height: 90,
                                    }}>
                                    <Avatar
                                        variant="square"
                                        sx={{
                                            borderRadius: '9px',
                                            width: 90,
                                            height: 90,
                                        }}
                                        src={API_BASE_URL + viewAlumni?.avatar}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={8}>
                                <Box className='student_year'>
                                    <Typography
                                        color={'#357a38'}
                                        sx={{
                                            fontWeight: 'bold',
                                            '&:hover': {
                                                textDecoration: 'underline',
                                                cursor: 'pointer'
                                            },
                                        }}
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                    >
                                        {viewAlumni?.firstName.charAt(0).toUpperCase() + viewAlumni?.firstName.slice(1)} {viewAlumni?.lastName.charAt(0).toUpperCase() + viewAlumni?.lastName.slice(1)}

                                    </Typography>
                                    <Typography mt={-1} variant='h6' sx={{ textAlign: 'center', fontWeight: 'normal', fontSize: '10px' }}>
                                        {viewAlumni?.passingYear}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box mt={2} mb={2}>
                            <Stack direction={'row'} mx={'auto'}>
                                <Box xs={6} sx={{ textAlign: 'center' }}>
                                    <Typography mt={1} fontWeight={'normal'} variant="h6" sx={{ wordBreak: 'break-all' }}>
                                        <strong>Email:</strong>  {viewAlumni?.email || ''}
                                    </Typography>
                                    <Typography mt={1} fontWeight={'normal'} variant="h6" >
                                        <strong>Phone:</strong>  {viewAlumni?.mobileNo || ''}
                                    </Typography>
                                    <Typography mt={1} fontWeight={'normal'} variant="h6" >
                                        <strong>Occupation:</strong>  {viewAlumni?.occupation || ''}
                                    </Typography>
                                    <Typography mt={1} fontWeight={'normal'} variant="h6" >
                                        <strong>Location:</strong>  {viewAlumni?.location || ''}
                                    </Typography>
                                    <Typography mt={1} fontWeight={'normal'} variant="h6" >
                                        <strong>Passing out class:</strong>  {viewAlumni?.passingOutClass || ''}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Box>
                        {/* <Stack direction={'row'} spacing={2}>
                            {viewAlumni?.socialLinks?.map((socialLink) => (
                                <a href={socialLink?.url} target="_blank" rel="noopener noreferrer" key={socialLink?.id}>
                                    {socialLink?.type === 'facebook' && <FacebookIcon style={{ color: 'rgb(24, 119, 242)' }} />}
                                    {socialLink?.type === 'instagram' && <InstagramIcon style={{ color: 'rgb(224, 45, 105)' }} />}
                                    {socialLink?.type === 'linkedIn' && <LinkedInIcon style={{ color: 'rgb(0, 126, 187)' }} />}
                                    {socialLink?.type === 'twitter' && <TwitterIcon style={{ color: 'rgb(0, 170, 236)' }} />}

                                    {socialLink?.type === 'facebook' && <FacebookIcon url={socialLink?.url} />}
                                    {socialLink?.type === 'instagram' && <InstapaperIcon url={socialLink?.url} />}
                                    {socialLink?.type === 'linkedIn' && <LinkedinIcon url={socialLink?.url} />}
                                    {socialLink?.type === 'twitter' && <TwitterIcon url={socialLink?.url} />}
                                </a>
                            ))}
                        </Stack> */}
                        <Stack direction={'row'} spacing={2}>
                            {viewAlumni?.socialLinks?.map((socialLink) => (
                                <a href={socialLink?.url} target="_blank" rel="noopener noreferrer" key={socialLink?.id}>
                                    {socialLink?.type === 'facebook' && (
                                        <FacebookIcon url={socialLink?.url} />
                                    )}
                                    {socialLink?.type === 'instagram' && (
                                        <InstapaperIcon url={socialLink?.url} />
                                    )}
                                    {socialLink?.type === 'linkedIn' && (
                                        <LinkedinIcon url={socialLink?.url} />
                                    )}
                                    {socialLink?.type === 'twitter' && (
                                        <TwitterIcon url={socialLink?.url} />
                                    )}
                                </a>
                            ))}
                        </Stack>
                    </Stack>
                </Box>
            </Modal>

        </>
    );
}
