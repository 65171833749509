import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from "lottie-react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import * as swal from 'sweetalert';
import {
    Box,
    Menu,
    Button,
    Paper,
    Card,
    Avatar,
    MenuItem,
    CardMedia,
    IconButton,
    Container,
    Typography,
    CardActions,
    CardContent,
    CardActionArea,
    Stack,
    Divider,
    ListItem
} from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import ShareIcon from '@mui/icons-material/Share';
import Grid from '@mui/material/Unstable_Grid2';
import Chip from '@mui/material/Chip';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../loader/Loader';
import noData from '../../animations/Circle.json';
import { DeletePosts, ListPosts } from '../../services/feeds';
import { API_BASE_URL } from '../../Setting';

function ListFeeds() {
    const [loading, setLoading] = useState(false);
    const [feeds, setFeeds] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedPostId, setSelectedPostId] = useState(null);
    const [selectedFeedId, setSelectedFeedId] = useState(null);

    const roleId = localStorage.getItem('roleId');
    const navigate = useNavigate()

    const handleClick = (event, id) => {
        setSelectedFeedId(id)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedFeedId(null);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const getPosts = async () => {
        setTimeout(async () => {
            try {
                const apiRes = await ListPosts();
                if (apiRes.status === 200) {
                    setLoading(true);
                    setFeeds(apiRes?.data?.data || []);
                } else {
                    setLoading(false);
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
            } catch (err) {
                setLoading(false);
                toast.error('Something went wrong!, Please try again.', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        }, 500)
    };

    const deleteFeed = async (id) => {
        setSelectedFeedId(null);
        handleCloseMenu();
    };

    const confirmDelete = async () => {
        try {
            const res = await DeletePosts(selectedFeedId);
            if (res.status === 201) {
                swal('News&Update deleted successfully!', {
                    icon: 'success',
                });
                setAnchorEl(null);
                getPosts();
            } else {
                swal('Something went wrong!, Please try again.');
            }
        } catch (err) {
            setLoading(false);
            toast.error('Something went wrong!, Please try again.', {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        }
    };

    const handleDeleteConfirmation = () => {
        swal({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                confirmDelete();
            }
        });
    };
    useEffect(() => {
        getPosts()
    }, [])

    function truncateWords(content, limit) {
        if (!content) return '';
        const words = content.split(' ');
        if (words.length <= limit) return content;
        return `${words.slice(0, limit).join(' ')}...`;
    }

    return (
        <Container maxWidth="xxl">
            <Typography mb={5} variant="h4" gutterBottom>
                News & Updates
            </Typography>
            {roleId === '1' &&
                <Typography
                    mb={2}
                    sx={{
                        '&:hover': {
                            cursor: 'pointer',
                            color: 'blue'
                        },
                    }}
                    variant="body1"
                >
                    <Tooltip title="Create Post" arrow placement="top">
                        <Box component="span">
                            <Button
                                type='button'
                                endIcon={<AddIcon />}
                                sx={{
                                    backgroundColor: "rgb(0, 171, 85)"
                                }}
                                variant="contained"
                                onClick={() => navigate(`/news-updates/${0}`)}
                           className='create_news'>
                                <span>Create</span>
                            </Button>
                        </Box>
                    </Tooltip>
                </Typography>
            }

            {!loading ?
                <div>
                    <Loader />
                </div> :
                <>
                    {feeds?.length === 0 ?
                        <>
                            <Box sx={{ bgcolor: 'white', mt: 3 }}>
                                <Lottie style={{ height: '300px' }} loop animationData={noData} />
                            </Box>
                        </>
                        :
                        <>

                            <Box bgcolor='white' p={2}>
                                {feeds?.map((item, index) => (
                                    <>

                                        <List key={index} component="nav" aria-label="main mailbox folders">

                                            <ListItemText
                                                primary={
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <span>{`${index + 1}. ${item?.title}`}</span>

                                                        <IconButton edge="end" aria-label="delete">
                                                            <Box mr={1}>
                                                                {roleId === '1' && (
                                                                    <MoreVertIcon
                                                                        sx={{
                                                                            '&:hover': {
                                                                                cursor: 'pointer',
                                                                            },
                                                                        }}
                                                                        onClick={(e) => handleClick(e, item.id)}
                                                                    />
                                                                )}

                                                                <Menu
                                                                    anchorEl={anchorEl}
                                                                    open={Boolean(anchorEl)}
                                                                    onClose={handleClose}
                                                                    anchorOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}
                                                                >
                                                                    <MenuItem
                                                                        onClick={() => {
                                                                            setSelectedPostId(null);
                                                                            setSelectedFeedId(item.id);
                                                                            handleCloseMenu();
                                                                            navigate(`/news-updates/${selectedFeedId}`);
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </MenuItem>
                                                                    <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
                                                                </Menu>
                                                            </Box>
                                                        </IconButton>
                                                    </div>
                                                }
                                                // secondaryAction={
                                                //     <IconButton edge="end" aria-label="delete">
                                                //         <DeleteIcon />
                                                //     </IconButton>
                                                // }
                                                secondary={
                                                    <>
                                                        <Typography variant="body2" color="text.secondary" align="left">
                                                            {new Date(item?.createdAt).toLocaleString('en-US', {
                                                                month: 'long',
                                                                day: 'numeric',
                                                                year: 'numeric',
                                                            })}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" component="div" dangerouslySetInnerHTML={{ __html: truncateWords(item?.messageContentWithHtml, 30) }} />
                                                        <Button variant="contained" size="small"
                                                            sx={{ backgroundColor: '#d7b56d' }}
                                                            onClick={() => navigate(`/news-updates/view/${item?.id}`)}
                                                        >
                                                            View Details
                                                        </Button>
                                                    </>
                                                }
                                            />

                                        </List>
                                        <Divider />

                                        {/* <Grid item xs={12} sm={6} md={6} key={index}>
                                            <Card sx={{ maxWidth: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                                                {item.avatar.endsWith('.pdf') || item.avatar.endsWith('.doc') || item.avatar.endsWith('.docx') ? (
                                                    <iframe src={`${API_BASE_URL}${item.avatar}`} width="100%" height="500px" title="Document" />
                                                ) : (
                                                    <CardMedia
                                                        component="img"
                                                        height="140"
                                                        image={API_BASE_URL + item.avatar}
                                                        alt="green iguana"
                                                    />
                                                )}
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        {item?.title}
                                                    </Typography>

                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        dangerouslySetInnerHTML={{
                                                            __html: item?.messageContentWithHtml?.split(' ')?.slice(0, 30)?.join(' '),
                                                        }}
                                                    />
                                                </CardContent>
                                                <CardActions sx={{ marginTop: 'auto' }}>
                                                    <Stack width={'100%'} direction="row" alignItems="center" justifyContent="space-between">
                                                        <Box display="flex" alignItems="center">
                                                            <Avatar src="/assets/images/logo/logo_new.png" />
                                                            <Box ml={1}>
                                                                <Typography variant="subtitle2" component="p">
                                                                    Administrator
                                                                </Typography>
                                                                <Typography variant="subtitle2" color="textSecondary" component="p">
                                                                    {new Date(item?.createdAt).toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box mr={1}>
                                                            {role === 'Administrator' && (
                                                                <MoreVertIcon
                                                                    sx={{
                                                                        '&:hover': {
                                                                            cursor: 'pointer',
                                                                        },
                                                                    }}
                                                                    onClick={(e) => handleClick(e, item.id)}
                                                                />
                                                            )}

                                                            <Menu
                                                                anchorEl={anchorEl}
                                                                open={Boolean(anchorEl)}
                                                                onClose={handleClose}
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                            >
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        setSelectedPostId(null);
                                                                        setSelectedFeedId(item.id);
                                                                        handleCloseMenu();
                                                                        navigate(`/news-updates/${selectedFeedId}`);
                                                                    }}
                                                                >
                                                                    Edit
                                                                </MenuItem>
                                                                <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
                                                            </Menu>
                                                        </Box>
                                                    </Stack>
                                                </CardActions>
                                            </Card>
                                        </Grid> */}

                                    </>
                                ))
                                }
                            </Box>

                        </>
                    }
                </>
            }
            <ToastContainer />

        </Container >
    )
}

export default ListFeeds
