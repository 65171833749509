import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    InstapaperIcon
} from "react-share";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DataTable from "react-data-table-component";
import NorthIcon from '@mui/icons-material/North';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
// @mui
import {
    Box,
    Grid,
    Card,
    Modal,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TextField,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    InputAdornment,
    TableContainer,
    TablePagination,
} from '@mui/material';
// components

import { ListRoles } from '../../services/role';
import { GetUserDetails, listAllUsers } from '../../services/users';
import { API_BASE_URL } from '../../Setting';
import Loader from '../../loader/Loader';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function BatchMates() {
    const tableCustomStyles = {
        headRow: {
            style: {
                fontSize: '15px',
                // color: '#223336',
                // backgroundColor: '#e7eef0'
            },
        },
        rows: {
            style: {
                backgroundColor: '#fafafa',
                fontSize: '15px',
            },
            stripedStyle: {
                backgroundColor: "#fff"
            }
        },
        headCells: {
            style: {
                // paddingLeft: '20px', // Adjust the padding here
                // paddingRight: '10px', // Adjust the padding here
            },
        },
        cells: {
            style: {
                // paddingLeft: '10px', 
                // paddingRight: '10px', 
            },
        },
    };

    const columns = [
        // {
        //     name: "Sl No.",
        //     selector: "slno",
        //     sortable: true,
        // },
        {
            name: "Name",
            selector: "name",
            sortable: true,
            minWidth: "200px",
        },
        {
            name: "Mobile No.",
            selector: "mobileNo",
            sortable: false,
        },
        {
            name: "Email",
            selector: "email",
            sortable: true,
            minWidth: "240px",
        },
        // {
        //     name: "Passing Out Class",
        //     selector: "passingOutClass",
        //     sortable: true,
        //     minWidth: "200px",
        // },
        {
            name: "Profile",
            selector: "avatar",
            sortable: false,
            // right: true,
            cell: (row) => <Avatar src={API_BASE_URL + row.avatar} />,
            maxWidth: "60px"
        },
        {
            name: 'Action', // Empty field property for the triple dots column
            sortable: false,
            // right: true,
            cell: (row) => (
                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(row.id, event)}>
                    <MoreVertIcon />
                </IconButton>
            ),
        },
    ];

    const [selectedRoleName, setSelectedRoleName] = useState();
    const [selectedRoleId, setSelectedRoleId] = useState(0);
    const [selectedYear, setSelectedYear] = useState('');
    const [viewAlumni, setViewAlumni] = useState();

    const [loading, setLoading] = useState(true);
    const [alumni, setAlumni] = useState([]);
    const [open, setOpen] = useState(null);
    const [editId, setEditId] = useState(null);
    const [roles, setRoles] = useState();

    const [openModal, setOpenModal] = useState(false);
    const [searchText, setSearchText] = useState('');
    const handleOpenModal = async () => {
        setOpenModal(true)
        try {
            const apiRes = await GetUserDetails(editId)
            setViewAlumni(apiRes?.data?.data)
        } catch {
            alert('Something went wrong!, Please try again..!')
        }
    }
    const handleCloseModal = () => {
        setOpenModal(false);
        setOpen(null);
    }

    const handleOpenMenu = (id, event) => {
        setEditId(id)
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const navigate = useNavigate();

    const id = localStorage.getItem('id')
    const roleId = localStorage.getItem('roleId')

    const getRoles = async () => {
        setTimeout(async () => {
            try {
                const apiRes = await ListRoles();
                if (apiRes.status === 200) {
                    setLoading(true);
                    setRoles(apiRes?.data?.data || []);
                    const AlumniRole = apiRes?.data?.data.filter((item) => item?.role === "Alumni");

                    if (AlumniRole) {
                        const roleId = AlumniRole[0].id;
                        setSelectedRoleId(roleId)
                    }

                } else {
                    setLoading(false);
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
            } catch (err) {
                setLoading(false);
                toast.error('Something went wrong!, Please try again..!', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        }, 700);
    };

    const listAllAlumni = async () => {
        setTimeout(async () => {
            try {
                const apiRes = await listAllUsers({ roleId: selectedRoleId });
                if (apiRes.status === 200) {
                    // const filteredData = apiRes?.data?.data?.filter(item => item?.id !== id).map(item => ({ ...item }));
                    const currentUser = apiRes?.data?.data?.find((user) => user.id === parseInt(id, 10));
                    const currentUserPassingYear = currentUser.passingYear;
                    const filteredData = apiRes?.data?.data.filter(
                        (user) => user?.id !== currentUser?.id && user?.passingYear === currentUserPassingYear
                    );
                    const filteredTable = filteredData.filter(item => item?.id !== id && item?.isPaymentCompleted !== false)
                        .map((item, index) => ({
                            id: item?.id,
                            slno: index + 1,
                            name: `${item?.firstName} ${item?.lastName}`,
                            firstName: item?.firstName,
                            lastName: item?.lastName,
                            email: item?.email,
                            mobileNo: item?.mobileNo,
                            passingOutClass: item?.passingOutClass,
                            avatar: item?.avatar,
                        }));
                    setAlumni(filteredTable || []);
                } else {
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
            } catch (err) {
                toast.error('Something went wrong!, Please try again..!', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            } finally {
                setLoading(false); // Update loading state after fetching is completed, regardless of success or error
            }
        }, 500);
    };

    useEffect(() => {
        if (selectedRoleId) {
            listAllAlumni();
        }
    }, [selectedRoleId])

    useEffect(() => {
        getRoles();
    }, [])

    return (
        <>
            <Helmet>
                <title> Alumni </title>
            </Helmet>

            <Container maxWidth="xxl" className="batch_page">
                <Grid container spacing={2}>
                    <Grid item md={8} className='title_page'>
                        <Typography variant="h4" gutterBottom>
                            Batchmates
                        </Typography>
                    </Grid>

                    <Grid item md={4}>
                        <Box className='search_area'
                            sx={{
                                padding: '13px 0'
                            }}>
                            <TextField
                                size='small'
                                label="Search by name"
                                variant="outlined"
                                value={searchText}
                                onChange={handleSearch}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>

                {loading ?
                    <div>
                        <Loader />
                    </div> :
                    <>
                        <Card className='alumni_data'>
                            <DataTable
                                columns={columns}
                                // data={alumni}
                                data={alumni.filter((row) =>
                                    row.name.toLowerCase().includes(searchText.toLowerCase())
                                )}
                                defaultSortField="name"
                                sortIcon={<NorthIcon />}
                                pagination
                                selectableRows={false}
                                customStyles={tableCustomStyles} paginationPerPage={50}
                                paginationRowsPerPageOptions={[50, 100, 500]}
                                striped
                            />
                        </Card>
                    </>
                }

            </Container>
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                {roleId === '1' ?
                    <>
                        <MenuItem onClick={handleOpenModal}>
                            <VisibilityIcon sx={{ mr: 2 }} />
                            View
                        </MenuItem>
                        <MenuItem onClick={() => navigate(`/profile/${editId}`)}>
                            <EditIcon sx={{ mr: 2 }} />
                            Edit
                        </MenuItem>
                    </>
                    :
                    <MenuItem onClick={handleOpenModal}>
                        <VisibilityIcon sx={{ mr: 2 }} />
                        View
                    </MenuItem>
                }
            </Popover>



            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    ...style,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Stack direction={'column'} className='edit_popup'>
                        {/* Close button/icon */}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}
                            onClick={handleCloseModal}
                        >
                            <CloseIcon
                                sx={{ cursor: 'pointer' }}
                            />
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <Box
                                    sx={{
                                        width: 90,
                                        height: 90,
                                    }}>
                                    <Avatar
                                        variant="square"
                                        sx={{
                                            borderRadius: '9px',
                                            width: 90,
                                            height: 90,
                                        }}
                                        src={API_BASE_URL + viewAlumni?.avatar}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={8}>
                                <Box className='student_year'>
                                    <Typography
                                        color={'#357a38'}
                                        sx={{
                                            fontWeight: 'bold',
                                            '&:hover': {
                                                textDecoration: 'underline',
                                                cursor: 'pointer'
                                            },
                                        }}
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                    >
                                        {viewAlumni?.firstName.charAt(0).toUpperCase() + viewAlumni?.firstName.slice(1)} {viewAlumni?.lastName.charAt(0).toUpperCase() + viewAlumni?.lastName.slice(1)}

                                    </Typography>
                                    <Typography mt={-1} variant='h6' sx={{ textAlign: 'center', fontWeight: 'normal', fontSize: '10px' }}>
                                        {viewAlumni?.passingYear}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box mt={2} mb={2}>
                            <Stack direction={'row'} mx={'auto'}>
                                <Box xs={6} sx={{ textAlign: 'center' }}>
                                    <Typography mt={1} fontWeight={'normal'} variant="h6" sx={{ wordBreak: 'break-all' }}>
                                        <strong>Email:</strong>  {viewAlumni?.email || ''}
                                    </Typography>
                                    <Typography mt={1} fontWeight={'normal'} variant="h6" >
                                        <strong>Phone:</strong>  {viewAlumni?.mobileNo || ''}
                                    </Typography>
                                    <Typography mt={1} fontWeight={'normal'} variant="h6" >
                                        <strong>Occupation:</strong>  {viewAlumni?.occupation || ''}
                                    </Typography>
                                    <Typography mt={1} fontWeight={'normal'} variant="h6" >
                                        <strong>Location:</strong>  {viewAlumni?.location || ''}
                                    </Typography>
                                    <Typography mt={1} fontWeight={'normal'} variant="h6" >
                                        <strong>Passing out class:</strong>  {viewAlumni?.passingOutClass || ''}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Box>
                        <Stack direction={'row'} spacing={2}>
                            {viewAlumni?.socialLinks?.map((socialLink) => (
                                <a href={socialLink?.url} target="_blank" rel="noopener noreferrer" key={socialLink?.id}>
                                    {socialLink?.type === 'facebook' && (
                                        <FacebookIcon url={socialLink?.url} style={{ color: 'rgb(24, 119, 242)' }} />
                                    )}
                                    {socialLink?.type === 'instagram' && (
                                        <InstapaperIcon url={socialLink?.url} style={{ color: 'rgb(224, 45, 105)' }} />
                                    )}
                                    {socialLink?.type === 'linkedIn' && (
                                        <LinkedinIcon url={socialLink?.url} style={{ color: 'rgb(0, 126, 187)' }} />
                                    )}
                                    {socialLink?.type === 'twitter' && (
                                        <TwitterIcon url={socialLink?.url} style={{ color: 'rgb(0, 170, 236)' }} />
                                    )}
                                </a>
                            ))}
                        </Stack>
                    </Stack>
                </Box>
            </Modal>
            <ToastContainer />
        </>
    );
}
