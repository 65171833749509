import React, { useEffect, useState } from 'react';
import { Navigate, useRoutes, useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Cookies from 'js-cookie';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

// pages

import SignIn from './pages/Auth/SignIn';
import SignUp from './pages/Auth/SignUp';
import Otp from './pages/Auth/Otp';


import Dashboard from './pages/Dashboard/AppAnalytics';

import Page404 from './pages/Page404';

import Profile from './pages/Profile/index';
import EditProfile from './pages/EditProfile/index';
import Payment from './pages/Auth/Payment';
import PaymentStatus from './pages/Auth/PaymentStatus';
import { GetUserDetails } from './services/users';
import PaymentsList from './pages/Payments/PaymentsList';
import Announcements from './pages/Announcements/Announcements';
import AnnouncementList from './pages/Announcements/AnnouncementList';
import ProfileLayout from './layouts/profile/ProfileLayout';
import AnnouncementLayout from './layouts/announcement/AnnouncementLayout';
import AlumniLayout from './layouts/alumni/AlumniLayout';
import PaymentLayout from './layouts/payments/PaymentLayout';
import CalendarLayout from './layouts/calender/CalendarLayout';
import Calendar from './pages/Calendar/Calendar';
import PrivateRoute from './PrivateRoute';
import ListFeeds from './pages/Feeds/ListFeeds';
import CreateFeeds from './pages/Feeds/CreateFeeds';
import FeedsLayout from './layouts/feeds/FeedsLayout';
import Alumni from './pages/Alumni/Alumni';
import BatchmatesLayout from './layouts/batchmates/BatchmatesLayout';
import BatchMates from './pages/BatchMates/BatchMates';
import ViewFeed from './pages/Feeds/ViewFeed';

export default function Router() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [expireTime, setExpaireTime] = useState(0);
  const [role, setRole] = useState();
  const [loading, setLoading] = useState(false);

  const auth = localStorage.getItem("token")
  const currentTime = Math.floor(new Date().getTime() / 1000);
  const location = useLocation();

  axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const LoginCheck = () => {
    if (auth) {
      const decode = jwtDecode(auth);
      if (new Date() > new Date(decode?.exp * 1000)) {
        console.group(
          '\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b Token EXpired \b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\n'
        );
        console.log('Iat =>', new Date(decode?.iat * 1000));
        console.log('Exp =>', new Date(decode?.exp * 1000));
        console.log('Current Time =>', new Date());
        console.groupEnd();
        localStorage.clear();
        setIsLoggedIn(false);
      } else {
        // axios.defaults.headers.common.Authorization = `Bearer ${auth}`;
        setIsLoggedIn(true);
        const timeToExpiration = decode.exp - currentTime;
        setExpaireTime(timeToExpiration)
        // console.log('decode', decode);
      }
    } else {
      setIsLoggedIn(false);
      localStorage.clear();
    }
    // console.log(location.pathname)
    return !isLoggedIn ? <Navigate to='auth/sign-in' /> : <Navigate to='/dashboard' />;
  }

  const id = localStorage.getItem('id')

  const getUserDetails = async () => {
    try {
      setLoading(true);
      const apiRes = await GetUserDetails(id);
      if (apiRes.status === 200) {
        setRole(apiRes?.data?.data?.role?.role)
        localStorage.setItem('role', apiRes?.data?.data?.role?.role)
        localStorage.setItem('roleId', apiRes?.data?.data?.role?.id)
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error('Something went wrong!, Please try again.', {
        position: toast.POSITION.BOTTOM_LEFT
      })
    }
  };

  useEffect(() => {
    if (id) {
      getUserDetails()
    }
    LoginCheck()
    if (expireTime > 0) {
      setTimeout(() => {
        toast.error('You will be logged out in 1 minute!', {
          position: toast.POSITION.BOTTOM_LEFT
        })
      }, (expireTime - 60) * 1000);

      setTimeout(() => {
        setIsLoggedIn(false);
        setExpaireTime(0);
        localStorage.clear();
        toast.error('Please sign in to continue!', {
          position: toast.POSITION.BOTTOM_LEFT
        })
      }, expireTime * 1000);
    }
  }, [auth, expireTime])

  return (
    <div>
      <Routes>

        <Route element={<PrivateRoute />}>
          <Route path="/" element={<LoginCheck />} />
          <Route path="/dashboard" element={<DashboardLayout />} >
            <Route path="analytics" element={<Dashboard />} />
            <Route index element={<Navigate to="/dashboard/analytics" />} />
          </Route>
          <Route path="/calendar" element={<CalendarLayout />} >
            <Route path="" element={<Calendar />} />
          </Route>
          <Route path="/alumni" element={<AlumniLayout />} >
            <Route path="" element={<Alumni />} />
          </Route>
          <Route path="/batchmates" element={<BatchmatesLayout />} >
            <Route path="" element={<BatchMates />} />
          </Route>
          <Route path="/payments-list" element={<PaymentLayout />} >
            <Route path="" element={<PaymentsList />} />
          </Route>
          <Route path="/profile" element={<ProfileLayout />} >
            <Route path="" element={<Profile />} />
            <Route path=":id" element={<EditProfile />} />
          </Route>
          <Route path="/announcement" element={< AnnouncementLayout />} >
            <Route path="" element={<AnnouncementList />} />
            <Route path=":id" element={<Announcements />} />
          </Route>
          <Route path="/news-updates" element={<FeedsLayout />} >
            <Route path="" element={<ListFeeds />} />
            <Route path=":id" element={<CreateFeeds />} />
            <Route path="view/:id" element={<ViewFeed />} />
          </Route>
        </Route>

        <Route path="auth/sign-in" element={!isLoggedIn ? <SignIn /> : <Navigate to="/dashboard/analytics" />} />
        <Route path="payment" element={!isLoggedIn ? <Payment /> : <Navigate to="/dashboard/analytics" />} />
        <Route path="payment/gateway-response" element={!isLoggedIn ? <PaymentStatus /> : <Navigate to="/dashboard/analytics" />} />
        <Route path="verify-otp" element={!isLoggedIn ? <Otp /> : <Navigate to="/dashboard/analytics" />} />
        <Route path="auth/sign-up" element={!isLoggedIn ? <SignUp /> : <Navigate to="/dashboard/analytics" />} />
        <Route element={<SimpleLayout />} >
          <Route path="404" element={<Page404 />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Route>
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>

      <ToastContainer />
    </div>
  );

}
