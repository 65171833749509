import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ToastContainer, toast } from 'react-toastify';
import {
    Box,
    Stack,
    Button,
    Select,
    MenuItem,
    FormGroup,
    TextField,
    Container,
    InputLabel,
    Typography,
    FormControl,
    FormControlLabel,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CreatePosts, GetPosts, UpdatePosts } from '../../services/feeds';

function CreateFeeds() {
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [feeds, setFeeds] = useState(null);

    const { id } = useParams();
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        title: Yup.string().required("Title/Subject is required"),
        messageContentWithHtml: Yup.string().required("Post content is required"),
        avatar: Yup.string().required("Image is required"),
    });

    const getPosts = async () => {
        try {
            if (id !== "0") {
                setLoading(true);
                const apiRes = await GetPosts(id);
                if (apiRes.status === 200) {
                    setFeeds(apiRes?.data?.data || null);
                } else {
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            toast.error('Something went wrong!, Please try again.', {
                position: toast.POSITION.BOTTOM_LEFT
            })
        }
    };

    useEffect(() => {
        getPosts()
    }, [id])

    // Update initialValues with Feed data
    useEffect(() => {
        if (feeds) {
            formik.setValues({
                title: feeds?.title,
                avatar: feeds?.avatar,
                messageContentWithHtml: feeds?.messageContentWithHtml,
            });
        }
    }, [feeds]);

    const formik = useFormik({
        initialValues: {
            title: "",
            avatar: "",
            messageContentWithHtml: "",
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            if (feeds === null) {
                try {
                    setLoadingBtn(true)
                    const formData = new FormData();
                    formData.append('title', values.title);
                    formData.append('messageContentWithHtml', values.messageContentWithHtml);
                    formData.append('avatar', values.avatar);
                    const apiRes = await CreatePosts(formData);
                    if (apiRes.status === 201) {
                        navigate('/news-updates')
                        toast.success('Post created successfully..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                    } else {
                        toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                    }
                } catch (err) {
                    toast.error('Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                    setLoading(false)
                } finally {
                    setLoadingBtn(false)
                }
            } else {
                try {
                    setLoadingBtn(true)
                    const formData = new FormData();
                    formData.append('title', values.title);
                    formData.append('messageContentWithHtml', values.messageContentWithHtml);
                    formData.append('avatar', values.avatar);
                    const apiRes = await UpdatePosts(id, formData);
                    if (apiRes.status === 201) {
                        navigate('/news-updates')
                        toast.success('Post updated successfully..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                    } else {
                        toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                    }
                } catch (err) {
                    toast.error('Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                    setLoading(false)
                } finally {
                    setLoadingBtn(false)
                }
            }
        },

    });

    return (
        <Container maxWidth="xxl">
            <Typography mb={5} variant="h4" gutterBottom>
                News & Updates
            </Typography>

            <Stack>
                <Box
                    px={2}
                    py={2}
                    width={"100%"}
                    bgcolor={"white"}
                    sx={{
                        // width: { xs: '100%', sm: '100%', md: '40%', lg: '40%' },
                        boxShadow: '0px 0px 5px -2px',
                        borderRadius: '10px',
                    }}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <Stack
                            mx={2}
                            direction={'column'}
                            spacing={2}

                        >
                            {/* <Box mb={2}>
                                <TextField
                                    sx={{ width: '100%' }}
                                    label="Title/Subject"
                                    id="Post title"
                                    name="title"
                                    type="text"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.title && Boolean(formik.errors.title)}
                                />
                                {formik.touched.title && formik.errors.title && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.title}</p>}
                            </Box> */}

                            <Box mb={2} mt={1}>
                                <InputLabel htmlFor="post-title">Post title</InputLabel>
                                <TextField
                                    sx={{ width: '100%' }}
                                    id="title"
                                    name="title"
                                    // label="Title/Subject"
                                    placeholder='Title/Subject'
                                    type="text"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.title && formik.errors.title && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.title}</p>}
                            </Box>

                            <Box mt={2}>
                                <InputLabel htmlFor="photo">Upload photo/pdf/doc</InputLabel>
                                <input
                                    id="avatar"
                                    name="avatar"
                                    type="file"
                                    accept="image/*, .pdf, .doc, .docx" // Accept image, PDF, and Word file formats
                                    onChange={(event) => {
                                        formik.setFieldValue("avatar", event.currentTarget.files[0]);
                                    }}

                                />

                                {formik.touched.avatar && formik.errors.avatar && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.avatar}</p>}
                                <div>{feeds?.avatar ? feeds?.avatar?.split('/').pop() : ''}</div>

                            </Box>

                            <Box mb={2}>
                                <InputLabel htmlFor="post-title">Post content</InputLabel>
                                <CKEditor
                                    id="messageContentWithHtml"
                                    name="messageContentWithHtml"
                                    className="inputText"
                                    editor={ClassicEditor}
                                    data={formik.values?.messageContentWithHtml}
                                    value={formik.values?.messageContentWithHtml}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.messageContentWithHtml && Boolean(formik.errors.messageContentWithHtml)}
                                    onChange={(event, editor) => {
                                        if (editor) {
                                            formik.setFieldValue("messageContentWithHtml", editor.getData());
                                            formik.setFieldValue(
                                                "rawContent",
                                                new DOMParser().parseFromString(
                                                    editor.getData(),
                                                    "text/html"
                                                ).documentElement.textContent
                                            );
                                        }
                                    }}
                                    config={{
                                        toolbar: ['bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', '|', 'heading', 'blockquote', '|', 'undo', 'redo']
                                    }}
                                />
                                {formik.touched.messageContentWithHtml && formik.errors.messageContentWithHtml && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.messageContentWithHtml}</p>}
                            </Box>

                            <Stack py={1} spacing={2}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                    {/* <Box>
                                        <LoadingButton
                                            loadingPosition="end"
                                            loading={loadingBtn}
                                            type='button'
                                            variant="outlined"
                                            onClick={() => {
                                                formik.setValues({
                                                    ...formik.values,
                                                    status: 'draft' // set the status to 'draft'
                                                });
                                                formik.handleSubmit(); // call handleSubmit with the updated values
                                            }}
                                        >
                                            Save as Draft
                                        </LoadingButton>

                                    </Box> */}
                                    <Box ml={2}>
                                        <LoadingButton
                                            type='button'
                                            endIcon={<SendIcon />}
                                            loading={loadingBtn}
                                            loadingPosition="end"
                                            sx={{
                                                backgroundColor: "rgb(0, 171, 85)"
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                formik.setValues({
                                                    ...formik.values,
                                                    status: 'publish' // set the status to 'publish'
                                                });
                                                formik.handleSubmit(); // call handleSubmit with the updated values
                                            }}
                                            className='create_news'>
                                            <span>Publish</span>
                                        </LoadingButton>
                                    </Box>
                                </Box>
                            </Stack>

                        </Stack>
                    </form>
                </Box>
            </Stack>
            <ToastContainer />
        </Container>
    )
}

export default CreateFeeds
