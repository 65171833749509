/**
 * @scope api related to Calendar
 * 
 * @author Umasankar
 * @since 7th apr 2023
 */
import { API } from './provider';
import { V1, USERS, CREATE_EVENT, LIST_EVENT, DELETE_EVENT, UPDATE_EVENT, GET_EVENT } from './endpoints';

export const listAllUsers = (query) => {
    return API.GET(V1, `${USERS}?roleId=${query?.roleId}`);
};

// Notices apis
export const CreateEvent = (payload) => { return API.POST(V1, CREATE_EVENT, payload) };
export const ListEvents = () => { return API.GET(V1, LIST_EVENT); };
export const DeleteEvent = (id) => { return API.DELETE(V1, DELETE_EVENT.replace('{id}', id)); }
export const UpdateEvent = (id, payload) => { return API.PUT(V1, UPDATE_EVENT.replace('{id}', id), payload); }
export const GetEvents = (id) => { return API.GET(V1, GET_EVENT.replace('{id}', id)); }