import React from 'react';
import { Container, Typography, Grid, Stack, Box } from '@mui/material';

function AuthHeader() {
    return (
        <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12} className='logo_imgs'>
           <a href="https://www.stpaulspalampur.com/" target="blank"><img src="/assets/images/logo/logo.png" alt="logo"/></a>
           <a href="https://www.stpaulspalampur.com/" target="blank"><img src="/assets/images/logo/logo-cni-1.png" alt="logo"/></a>
          </Grid>
          </Grid>
       </Container>
    )
}

export default AuthHeader