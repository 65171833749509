import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import {
    Box,
    Stack,
    Container,
    Typography,
} from '@mui/material';
import { css } from '@emotion/css';

// Icons
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { CreateOrUpdateSocialLinks, GetSocialLinks, ListSocialLinks, } from '../../services/social';
import Loader from '../../loader/Loader';

function SocialLinks() {
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [getData, setGetData] = useState([])

    const validationSchema = Yup.object().shape({
        facebook: Yup.string().nullable(),
        instagram: Yup.string().nullable(),
        linkedIn: Yup.string().nullable(),
        twitter: Yup.string().nullable(),

    })

    const id = localStorage.getItem('id')

    const getSocialmediaLinks = async () => {
        setTimeout(async () => {
            try {
                const apiRes = await ListSocialLinks(id);
                if (apiRes.status === 200) {
                    setLoading(true);
                    setGetData(apiRes?.data?.data || []);
                } else {
                    setLoading(false);
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
            } catch (err) {
                setLoading(false);
                toast.error('Please sign in to continue!', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        }, 500)
    };
    useEffect(() => {
        getSocialmediaLinks();
    }, [])

    useEffect(() => {
        if (getData.length > 0) {
            formik.setValues({
                facebook: getData?.find(data => data.type === 'facebook')?.url,
                instagram: getData?.find(data => data.type === 'instagram')?.url,
                linkedIn: getData?.find(data => data.type === 'linkedIn')?.url,
                twitter: getData?.find(data => data.type === 'twitter')?.url
            })
        }
    }, [getData])

    const formik = useFormik({
        initialValues: {
            facebook: '',
            instagram: '',
            linkedIn: '',
            twitter: '',
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                setLoadingBtn(true)
                const data = {
                    social: [
                        { type: 'facebook', url: values.facebook },
                        { type: 'instagram', url: values.instagram },
                        { type: 'linkedIn', url: values.linkedIn },
                        { type: 'twitter', url: values.twitter }
                    ]
                }
                const apiRes = await CreateOrUpdateSocialLinks(data);
                if (apiRes.status === 201) {
                    toast.success('Details added successfully..!', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                    getSocialmediaLinks();
                } else {
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
            } catch (err) {
                toast.error('Something went wrong!, Please try again..!', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            } finally {
                setLoadingBtn(false)
            }

        }
    },)

    return (
        <Stack direction={'column'} spacing={3}>
            <Box
                py={5}
                sx={{
                    width: '100%',
                    boxShadow: '0px 0px 5px -2px',
                    borderRadius: '10px',
                }}>
                {!loading ?
                    <div>
                        <Loader />
                    </div> :
                    <>
                        <form onSubmit={formik.handleSubmit}>
                            <Stack direction={'column'} spacing={3} px={5} py={1}>
                                <Box
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    <TextField
                                        sx={{
                                            width: '100%',
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FacebookIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        id="outlined-Designation/Position"
                                        label="Facebook URL"
                                        type="url"
                                        name="facebook"
                                        onChange={formik.handleChange}
                                        value={formik.values.facebook}
                                        onBlur={formik.handleBlur}
                                        autoComplete="off"
                                    />
                                    {formik.touched.facebook && formik.errors.facebook && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.facebook}</p>}
                                </Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    <TextField
                                        sx={{
                                            width: '100%',
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <InstagramIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        id="outlined-Designation/Position"
                                        label="Instagram URL"
                                        type="url"
                                        name="instagram"
                                        onChange={formik.handleChange}
                                        value={formik.values.instagram}
                                        onBlur={formik.handleBlur}
                                        autoComplete="off"
                                    />
                                    {formik.touched.instagram && formik.errors.instagram && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.instagram}</p>}
                                </Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    <TextField
                                        sx={{
                                            width: '100%',
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LinkedInIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        id="outlined-Designation/Position"
                                        label="LinkedIn URL"
                                        type="url"
                                        name="linkedIn"
                                        onChange={formik.handleChange}
                                        value={formik.values.linkedIn}
                                        onBlur={formik.handleBlur}
                                        autoComplete="off"
                                    />
                                    {formik.touched.linkedIn && formik.errors.linkedIn && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.linkedIn}</p>}
                                </Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    <TextField
                                        sx={{
                                            width: '100%',
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <TwitterIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        id="outlined-Designation/Position"
                                        label="Twitter URL"
                                        type="url"
                                        name="twitter"
                                        onChange={formik.handleChange}
                                        value={formik.values.twitter}
                                        onBlur={formik.handleBlur}
                                        autoComplete="off"
                                    />
                                    {formik.touched.twitter && formik.errors.twitter && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.twitter}</p>}
                                </Box>
                            </Stack>
                            <Stack px={7} py={1}>
                                <Box ml={'auto'}>
                                    <LoadingButton
                                        type='submit'
                                        endIcon={<SaveIcon />}
                                        loading={loadingBtn}
                                        loadingPosition="end"
                                        sx={{
                                            backgroundColor: "rgb(0, 171, 85)"
                                        }}
                                        variant="contained"
                                    >
                                        <span>Save Changes</span>
                                    </LoadingButton>
                                    {/* <Button type='submit' variant="contained" sx={{
                                        backgroundColor: "rgb(0, 171, 85)"
                                    }}>
                                        Save Changes
                                    </Button> */}
                                </Box>
                            </Stack>
                        </form>
                    </>
                }
            </Box>
            <ToastContainer />
        </Stack>
    )
}

export default SocialLinks
