/**
 * @scope api related to social media
 * 
 * @author Umasankar
 * @since 2nd feb 2023
 */

import { API } from './provider';
import { V1, USERS, POST_OR_UPDATE_SOCIAL_DETAILS, GET_SOCIAL_LINKS, LIST_SOCIAL_LINKS } from './endpoints';

export const listAllUsers = (query) => {
    return API.GET(V1, `${USERS}?roleId=${query?.roleId}`);
};

// Social Links apis
export const CreateOrUpdateSocialLinks = (payload) => { return API.POST(V1, POST_OR_UPDATE_SOCIAL_DETAILS, payload); }
export const ListSocialLinks = () => { return API.GET(V1, LIST_SOCIAL_LINKS); }
export const GetSocialLinks = (id) => { return API.GET(V1, GET_SOCIAL_LINKS.replace('{id}', id)); }