import axios from "axios";

/**
 * 
 * @author Rajendra Kumar Majhi
 * @since 15th Jan 2022
 */

// const API_BASE_URL = 'https://stpaulalumniapi.cybraintech.com/api';
// const API_BASE_URL = 'https://stpaulalumniapi.coderspace.cloud/api';
const API_BASE_URL = 'https://alumniapi.stpaulspalampur.com/api';
// const API_BASE_URL = 'http://localhost:9001/api';
console.log(`Admin URL is ${API_BASE_URL}`);

/** 
 * @scope GET
 * @param {string} version
 * @param {string} endpoint
 * */
const GET = async (version, endpoint) => {
    try {
        const res = await axios.get(`${API_BASE_URL}/${version}/${endpoint}`);
        return res;
    } catch (error) {
        return error?.response;
    }
};

/** 
 * @scope POST
 * @param {string} version
 * @param {string} endpoint
 * @param {object} data
 * */
const POST = async (version, endpoint, data, headers) => {
    try {
        const res = await axios.post(`${API_BASE_URL}/${version}/${endpoint}`, data, headers);
        return res;
    } catch (error) {
        return error?.response;
    }
};

/** 
 * @scope PUT
 * @param {string} version
 * @param {string} endpoint
 * @param {object} data
 * */
const PUT = async (version, endpoint, data) => {
    try {

        const res = await axios.put(`${API_BASE_URL}/${version}/${endpoint}`, data);
        return res;
    } catch (error) {
        return error?.response;
    }
};

/** 
 * @scope DELETE
 * @param {string} version
 * @param {string} endpoint
 * @param {object} data
 * */
const DELETE = async (version, endpoint, data = null) => {
    try {
        const res = await axios.delete(`${API_BASE_URL}/${version}/${endpoint}`, data);
        return res;
    } catch (error) {
        return error?.response;
    }
};

export const API = {
    GET,
    POST,
    PUT,
    DELETE,
    API_BASE_URL
};