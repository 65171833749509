import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Container, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useResponsive from "../../hooks/useResponsive";
import Logo from "../../components/logo/Logo";
import { verifyPaymentGateway, processToPayUGateway, addUserToPayments } from '../../services/authentication';

// import QR from '/assets/images/st.paul_payment.jpeg';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    marginLeft: '5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

function Payment() {
    const mdUp = useResponsive('up', 'md');
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const searchParams = new URLSearchParams(location.search);
    const slug = searchParams.get('slug');
    const key = searchParams.get('key');
    const [payload, setPayload] = useState();
    const [open, setOpen] = React.useState(false);
    const user = JSON.parse(localStorage.getItem('alumni-recurrence-user'));
    // JSON.parse(localStorage.getItem('recurrence')); 

    const handleClickOpen = () => {
        saveUserToPayments();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (slug && key) {
            verify(slug, key);
        } else {
            toast.error('Invalid URL.', { position: toast.POSITION.BOTTOM_LEFT });
            navigate('/auth/sign-in', { replace: true });
        }

    }, []);

    const verify = async (slug, key) => {

        try {
            setLoading(true);
            const apiRes = await verifyPaymentGateway(slug, key);
            if (apiRes.status === 200) {
                setPayload(apiRes?.data?.data);
            } else {
                toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error('Something went wrong!, Please try again..!', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    };

    const PayNow = async () => {
        try {
            setLoading(true);
            const apiRes = await processToPayUGateway({
                firstName: payload?.firstName,
                lastName: payload?.lastName,
                email: payload?.email,
                contact: payload?.mobileNo,
                information: slug,
                userId: payload?.id,
                amount: payload?.registrationFee
            });
            if (apiRes.status === 200) {
                document.write(apiRes?.data?.data?.form.toString());
            } else {
                toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error('Something went wrong!, Please try again.', {
                position: toast.POSITION.BOTTOM_LEFT
            })
        }
    };

    const saveUserToPayments = async () => {
        try {
            setLoading(true);
            const apiRes = await addUserToPayments({
                firstName: payload?.firstName,
                lastName: payload?.lastName,
                email: payload?.email,
                contact: payload?.mobileNo,
                information: slug,
                userId: payload?.id,
                amount: payload?.registrationFee
            });
            if (apiRes.status === 200) {
                const success = 'Success';
            } else {
                toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error('Something went wrong!, Please try again.', {
                position: toast.POSITION.BOTTOM_LEFT
            })
        }
    }

    const redirectLogin = () => {
        toast.success('Pending Approval from school', {
            position: toast.POSITION.BOTTOM_LEFT
        })
        handleClose();
        navigate('/auth/sign-in')
    }

    return (
        <div>
            <>
                <Helmet>
                    <title> Login | Alumni </title>
                </Helmet>

                <StyledRoot>
                    <Logo
                        sx={{
                            position: 'fixed',
                            top: { xs: 16, sm: 24, md: 40 },
                            left: { xs: 16, sm: 24, md: 40 },
                        }}
                    />

                    {mdUp && (
                        <StyledSection>
                            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                                Hi, Welcome :)
                            </Typography>
                            <img src="/assets/illustrations/illustration_login.png" alt="login" />
                        </StyledSection>
                    )}

                    <Container maxWidth="sm" >
                        <StyledContent>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                boxShadow: '1px 0px 9px -2px',
                                borderRadius: '8px',
                                padding: 4,
                            }}
                            >
                                <Typography variant="h3">St. Paul’s, Palampur</Typography>
                                <Box style={{
                                    width: '100%',
                                    marginTop: 2,
                                }}>

                                    <Typography
                                        sx={{
                                            marginTop: 2,
                                            width: '100%',
                                        }}
                                    >
                                        Name:  {payload?.firstName || 'N/A'} {payload?.lastName || 'N/A'}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            marginTop: 2,
                                            width: '100%',
                                        }}
                                    >
                                        Email: {payload?.email || 'N/A'}
                                    </Typography>

                                    <Typography
                                        sx={{
                                            marginTop: 2,
                                            width: '100%',
                                        }}
                                    >
                                        Amount: ₹ {payload?.registrationFee}
                                        {console.log(payload?.registrationFee)}
                                    </Typography>
                                    <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <LoadingButton
                                            loading={loading}
                                            variant='contained'
                                            sx={{ bgcolor: 'rgb(0, 171, 85)' }}
                                            // onClick={PayNow} 
                                            onClick={handleClickOpen}
                                        >
                                            Pay Now
                                        </LoadingButton>
                                    </Box>
                                    <Typography mt={2} variant="body2" color="textSecondary">
                                        "By clicking the 'Pay Now' button, you are acknowledging and agreeing to all of our terms and conditions."
                                    </Typography>
                                </Box>
                            </Box>
                        </StyledContent>
                        <Dialog
                            open={open}
                            keepMounted
                            onClose={handleClose}
                            maxWidth="sm"
                            fullWidth
                        // aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle>{"Payment"}</DialogTitle>
                            <DialogContent>
                                <img style={{ height: '350px', width: '50%', margin: 'auto' }} src='/assets/images/st.paul_payment.jpeg' alt='QR_payment' />
                            </DialogContent>
                            <DialogActions>
                                <Stack>
                                    <Box>
                                        <Button
                                            variant='contained'
                                            sx={{ bgcolor: 'rgb(0, 171, 85)' }}
                                            onClick={redirectLogin}
                                        >
                                            Sign In
                                        </Button>
                                    </Box>
                                </Stack>
                            </DialogActions>
                            <Stack sx={{ ml: 'auto', pr: 1, pb: 1 }}>
                                <Box>
                                    <Typography variant='caption' color='text.disabled'>Successfully completed payment!Sign in here.</Typography>
                                </Box>
                            </Stack>
                        </Dialog>
                        <ToastContainer />
                    </Container>
                </StyledRoot>
            </>
        </div >
    )
}

export default Payment
