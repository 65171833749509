/**
 * @scope api related to authentication
 * 
 * @author Rajendra
 * @since 15th Jan 2023
 */

import { API } from './provider';
import { V1, USERS, GET_USER_DETAILS, UPDATE_USER_DETAILS } from './endpoints';

export const listAllUsers = (query) => {
    return API.GET(V1, `${USERS}?roleId=${query?.roleId}`);
};

// User Details apis
export const GetUserDetails = (id) => { return API.GET(V1, GET_USER_DETAILS.replace('{id}', id)); }
export const UpdateUserDetails = (id, payload) => { return API.PUT(V1, UPDATE_USER_DETAILS.replace('{id}', id), payload); }

