import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

const Loader = () => {
    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(224, 224, 224, 0.5)',
                zIndex: 9999, // make sure the loader is on top of other elements
                // backgroundColor: '#eeeeee,0.2',
            }}
        >
            <ThreeDots
                height={80}
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible
            />
        </div>
    )
}

export default Loader;