/**
 * @scope api related to Feeds
 * 
 * @author Umasankar
 * @since 22nd apr 2023
 */
import { API } from './provider';
import { V1, USERS, CREATE_POST, LIST_POSTS, DELETE_POST, UPDATE_POST, GET_POST, } from './endpoints';

export const listAllUsers = (query) => {
    return API.GET(V1, `${USERS}?roleId=${query?.roleId}`);
};

// Notices apis
export const CreatePosts = (payload) => { return API.POST(V1, CREATE_POST, payload) };
export const ListPosts = () => { return API.GET(V1, LIST_POSTS); };
export const DeletePosts = (id) => { return API.DELETE(V1, DELETE_POST.replace('{id}', id)); }
export const UpdatePosts = (id, payload) => { return API.PUT(V1, UPDATE_POST.replace('{id}', id), payload); }
export const GetPosts = (id) => { return API.GET(V1, GET_POST.replace('{id}', id)); }