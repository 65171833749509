/**
 * @scope api related to roles
 * 
 * @author Umasankar
 * @since 9th feb 2023
 */

import { API } from './provider';
import { V1, USERS, ROLES } from './endpoints';

export const listAllUsers = (query) => {
    return API.GET(V1, `${USERS}?roleId=${query?.roleId}`);
};

// Roles apis
export const ListRoles = () => { return API.GET(V1, ROLES); }