/**
 * @scope api related to Notices
 * 
 * @author Umasankar
 * @since 21st mar 2023
 */
import { API } from './provider';
import { V1, USERS, CREATE_NOTICES, LIST_NOTICES, DELETE_NOTICES, UPDATE_NOTICES, GET_NOTICES, } from './endpoints';

export const listAllUsers = (query) => {
    return API.GET(V1, `${USERS}?roleId=${query?.roleId}`);
};

// Notices apis
export const CreateNotices = (payload) => { return API.POST(V1, CREATE_NOTICES, payload) };
export const ListNotices = () => { return API.GET(V1, LIST_NOTICES); };
export const DeleteNotices = (id) => { return API.DELETE(V1, DELETE_NOTICES.replace('{id}', id)); }
export const UpdateNotices = (id, payload) => { return API.PUT(V1, UPDATE_NOTICES.replace('{id}', id), payload); }
export const GetNotices = (id) => { return API.GET(V1, GET_NOTICES.replace('{id}', id)); }