import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Unstable_Grid2';
// icons

import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import Divider from '@mui/material/Divider';

import {
    Box,
    Card,
    Table,
    Stack,
    Paper,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
} from '@mui/material';
import Lottie from "lottie-react";
import { ThreeDots } from 'react-loader-spinner';
import dayjs from 'dayjs';
import { ListWorkingDetails } from '../../services/working';
import noData from '../../animations/Circle.json';
import Loader from '../../loader/Loader';

function WorkingProfile() {
    const [loading, setLoading] = useState(false);
    const [workingDetails, setWorkingDetails] = useState([]);

    const id = localStorage.getItem('id')

    const getWorkingData = async () => {
        setTimeout(async () => {
            try {
                const apiRes = await ListWorkingDetails(id);
                if (apiRes.status === 200) {
                    setLoading(true);
                    setWorkingDetails(apiRes?.data?.data || []);
                } else {
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                    setLoading(false);
                }
            } catch (err) {
                setLoading(false);
                alert('Something went wrong!, Please try again.');
            }
        }, 500)
    };


    useEffect(() => {
        getWorkingData();
    }, [])


    return (
        <>
            {!loading ?
                <div>
                    <Loader />
                </div> :
                <>
                    {workingDetails?.length === 0 ?
                        <>
                            <Box sx={{ bgcolor: 'white' }}>
                                <Lottie style={{ height: '300px' }} loop animationData={noData} />
                            </Box>
                        </>
                        :
                        <>
                            <Stack
                                direction={{ xs: 'column', sm: 'column', md: 'column' }}
                                spacing={5}
                                width={'100%'}
                            >
                                {workingDetails?.map((item) => (
                                    <>
                                        <Box sx={{
                                            width: '100%',
                                            boxShadow: '0px 0px 2px -2px',
                                            borderRadius: 0,
                                            mt: 4,
                                        }}
                                            py={5}
                                            px={5}
                                            width={'100%'}
                                            bgcolor='white'>
                                            <Typography variant="h5" gutterBottom>
                                                {item?.company}
                                            </Typography>
                                            <Divider sx={{
                                                width: '100%',
                                                borderStyle: 'dashed'
                                            }} />
                                            <p>
                                                {item?.designation} at {item?.company}  .
                                            </p>
                                            <Stack direction={'row'} spacing={2}>
                                                <Box><WorkOutlineIcon sx={{ fontSize: '17px' }} /></Box>
                                                <Box>
                                                    <Typography>
                                                        {item?.company}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                            <Stack direction={'row'} spacing={2}>
                                                <Box><BusinessIcon sx={{ fontSize: '17px' }} /></Box>
                                                <Box>
                                                    <Typography >
                                                        {item?.organisationType}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                            <Stack direction={'row'} spacing={2}>
                                                <Box><LocationOnIcon sx={{ fontSize: '17px' }} /></Box>
                                                <Box>
                                                    <Typography >
                                                        {item?.location}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                            <Stack direction={'row'} spacing={2}>
                                                <Box><MilitaryTechIcon sx={{ fontSize: '17px' }} /></Box>
                                                <Box>
                                                    <Typography >
                                                        {item?.description}
                                                    </Typography>
                                                </Box>
                                            </Stack>

                                        </Box>
                                    </>
                                ))
                                }
                            </Stack>
                        </>}
                </>
            }
        </>
    )
}

export default WorkingProfile
