import React, { useEffect, useState } from "react";
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingButton } from '@mui/lab';
import { Container, Typography, Stack, Button, Grid, Box } from '@mui/material';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import OtpInput from 'react-otp-input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import JwtDecode from "jwt-decode";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useResponsive from "../../hooks/useResponsive";
import Logo from "../../components/logo/Logo";
import { signIn } from '../../services/authentication';
import '../../css/main.css';
import AuthFooter from "./AuthFooter";
import AuthHeader from "./AuthHeader";



const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

function Otp() {
    // const [otp, setOtp] = useState('')

    const mdUp = useResponsive('up', 'md');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');

    const [isInputVisible, setInputVisible] = useState(true);

    useEffect(() => { if (!email) navigate('/auth/sign-in', { replace: true }); }, [])

    const handleToggle = () => {
        setInputVisible(!isInputVisible); // Toggle the visibility state
    };

    const validationSchema = Yup.object().shape({
        otp: Yup.string()
            .length(5, 'OTP must be 5 digits')
            .required('OTP is required')
    });

    const formik = useFormik({
        initialValues: { otp: '' },
        validationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const apiRes = await signIn({ email, otp: values.otp });
                // console.log('otp',apiRes)
                if (apiRes.status === 200) {
                    const token = apiRes.data.data.accessToken;
                    const decode = JwtDecode(token);
                    if (token) {
                        localStorage.setItem('id', decode?.payload?.id);
                        localStorage.setItem('firstName', decode?.payload?.firstName);
                        localStorage.setItem('lastName', decode?.payload?.lastName);
                        localStorage.setItem('role', decode?.payload?.role);
                        localStorage.setItem('token', token);
                        Cookies.set('token', token);
                        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                        navigate('/dashboard/analytics', { replace: true });
                    } else {
                        localStorage.clear();
                        delete axios.defaults.headers.common.Authorization;
                    }
                } else {
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
                toast.error('Something went wrong!, Please try again.', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        },
    });

    return (
        <>
            {/* <Helmet>
                <title> Login | Alumni </title>
            </Helmet> */}

            <Helmet>
                <title>Login | Alumni</title>
                <meta name="description" content="otp page for the Alumni website" />
            </Helmet>

            <section className="header">
                <AuthHeader />
            </section>
            {/* {mdUp && (
                    <StyledSection>
                        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                            Hi, Welcome Back
                        </Typography>
                        <img src="/assets/illustrations/illustration_login.png" alt="login" />
                    </StyledSection>
                )} */}

            <StyledRoot>
                <section className='sign_in otp_verify'>
                    <Container maxWidth="xl">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <img src='/assets/images/welcome-img.jpg' alt='welcome' />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} className='right_side'>
                                <form onSubmit={formik.handleSubmit}>
                                    <Stack direction={'column'} spacing={3}>
                                        <Box>
                                            <Typography variant="h4">
                                                Verify your email
                                            </Typography>
                                            <Typography variant="body1" sx={{
                                                color: 'text.disabled',
                                                fontSize: '15px'
                                            }}>
                                                Please verify your email address (<span style={{ color: '#2065D1' }}>{email}</span>) by entering the
                                                security code which is sent to your email address
                                            </Typography>
                                        </Box>
                                        <Box mx={'auto'} sx={{
                                            width: '100%'
                                        }}>
                                            <Stack direction={'row'}>
                                                <Box>
                                                    <OtpInput
                                                        inputStyle={{
                                                            border: '1px solid #CFD3DB',
                                                            marginLeft: '20px',
                                                            width: '40px',
                                                            height: '40px',
                                                            borderRadius: '8px',
                                                            backgroundColor: '#F9FAFB',
                                                        }}
                                                        focusStyle={{
                                                            border: "1px solid #2065D1",
                                                            outline: "none",
                                                            borderWidth: '2px',
                                                            fontSize: '20px',
                                                        }}
                                                        className="otp-input-container"
                                                        required
                                                        name="otp"
                                                        value={formik.values.otp}
                                                        onChange={(otp) => formik.setFieldValue('otp', otp)}
                                                        onBlur={formik.handleBlur}
                                                        numInputs={5}
                                                        separator={<span> </span>}
                                                        isInputSecure={isInputVisible}
                                                        isInputNum={'true'}
                                                        shouldAutoFocus={'false'}
                                                        hasErrored
                                                    />
                                                </Box>
                                                <Box>
                                                    <Button sx={{ ml: 3 }} onClick={handleToggle}>{!isInputVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}</Button>
                                                </Box>
                                            </Stack>
                                        </Box>
                                        {formik.touched.otp && formik.errors.otp && <p style={{ color: 'red', marginBottom: '-10px', marginTop: '10px', textAlign: 'left' }}>{formik.errors.otp}</p>}
                                        <Box mx={'auto'} mt={-2}>
                                            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
                                                Confirm
                                            </LoadingButton>
                                            {/* <Button
                                        variant="contained"
                                        sx={{
                                            width: '70%',
                                        }}
                                        type="submit"
                                    >
                                        Confirm
                                    </Button> */}
                                        </Box>
                                    </Stack>
                                </form>
                            </Grid>
                        </Grid>
                    </Container>
                </section>
            </StyledRoot>

            <footer>
                <AuthFooter />
            </footer>

            <ToastContainer />

        </>
    )
}

export default Otp;
