import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SubjectIcon from '@mui/icons-material/Subject';
import CloseIcon from '@mui/icons-material/Close';
import * as swal from 'sweetalert';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import jwtDecode from 'jwt-decode';
import {
    Box,
    Chip,
    Card,
    Menu,
    Paper,
    Stack,
    Avatar,
    Dialog,
    Button,
    Select,
    Divider,
    Checkbox,
    MenuItem,
    TextField,
    Container,
    Typography,
    IconButton,
    InputLabel,
    CardContent,
    DialogTitle,
    FormControl,
    Autocomplete,
    ListItemText,
    DialogActions,
    DialogContent,
    ListItemAvatar,
    InputAdornment,
    FormControlLabel,
} from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ToastContainer, toast } from 'react-toastify';
import { ListRoles } from '../../services/role';
import { CreateEvent, DeleteEvent, ListEvents, UpdateEvent } from '../../services/calendar';
import Loader from '../../loader/Loader';

import { GetUserDetails, listAllUsers } from '../../services/users';
import { API_BASE_URL } from '../../Setting';

function Calendar() {
    const [openDialog, setOpenDialog] = useState(false);
    const [openAlumniDialog, setOpenAlumniDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [events, setEvents] = useState();
    const [inputValue, setInputValue] = useState('');
    const [roles, setRoles] = useState()
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [editId, setEditId] = useState()
    const [viewId, setViewId] = useState()
    const [users, setUsers] = useState()
    const [currentUser, setCurrentUser] = useState({ passingYear: '', passingOutClass: '' });

    const handleEventClick = (info) => {
        const selectedEvent = info.event;
        formik.setValues({
            title: selectedEvent?.title,
            startDate: dayjs(selectedEvent?.start).toDate(),
            endDate: dayjs(selectedEvent?.end).toDate(),
            passingYear: selectedEvent?.extendedProps?.passingYear,
            passingOutClass: selectedEvent?.extendedProps?.passingOutClass,
            messageContentWithHtml: selectedEvent?.extendedProps?.messageContentWithHtml,
            alumni: selectedEvent?.extendedProps?.alumni,
            scheduleDate: selectedEvent?.extendedProps?.scheduleDate,
        });
        setEditId(selectedEvent?._def?.publicId)
        setOpenDialog(true);
    };

    const handleEventAlumni = (info) => {

        const selectedEvent = info.event;
        setOpenAlumniDialog(true)
        formik.setValues({
            title: selectedEvent?.title,
            startDate: dayjs(selectedEvent?.start).toDate(),
            endDate: dayjs(selectedEvent?.end).toDate(),
            passingYear: selectedEvent?.extendedProps?.passingYear,
            passingOutClass: selectedEvent?.extendedProps?.passingOutClass,
            messageContentWithHtml: selectedEvent?.extendedProps?.messageContentWithHtml,
            alumni: selectedEvent?.extendedProps?.alumni,
            scheduleDate: selectedEvent?.extendedProps?.scheduleDate,
        });
        setViewId(selectedEvent?._def?.publicId)
    }

    const handleCloseDialog = () => {
        setEditId('')
        formik.resetForm();
        setOpenDialog(false);
    };

    const handleCloseAlumniDialog = () => {
        setOpenAlumniDialog(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleButtonClick = (info) => {
        setSelectedEvent(info.event);
        setOpenDialog(true);
    }

    const handleClickOpen = () => {
        setOpen(true);
    }

    const getRoles = async () => {
        try {
            const apiRes = await ListRoles();
            if (apiRes.status === 200) {
                setRoles(apiRes?.data?.data);
            } else {
                toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        } catch (err) {
            toast.error('Something went wrong!, Please try again..!', {
                position: toast.POSITION.BOTTOM_LEFT
            })
        }
    };

    const getCurrentUserId = () => {
        const token = localStorage.getItem("token");
        const decode = jwtDecode(token);
        return decode?.payload?.id;
    };

    const currentUserId = getCurrentUserId();

    const currentUserDetails = async () => {

        try {
            const apiRes = await GetUserDetails(currentUserId);
            if (apiRes.status === 200) {
                setLoading(true);
                const user = apiRes?.data?.data;
                setCurrentUser({ passingYear: user?.passingYear, passingOutClass: user?.passingYear });
            } else {
                setLoading(false);
                toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        } catch (err) {
            setLoading(false);
            toast.error('Something went wrong!, Please try again.', {
                position: toast.POSITION.BOTTOM_LEFT
            })
        }
    };
    // useEffect(() => {
    //     currentUserDetails()
    // }, [])
    useEffect(() => {
        getEvents()
    }, [currentUser])
    const getEvents = async () => {
        setTimeout(async () => {
            try {
                const apiRes = await ListEvents();
                if (apiRes.status === 200) {
                    setLoading(true);
                    let events = apiRes?.data?.data;
                    if (role === 'Alumni') {
                        // const currentUserId = getCurrentUserId();

                        // Filter events to include only those where the current user's passing out year or passing out class matches
                        events = events?.filter(event => {
                            const eventPassingYear = event?.passingYear;
                            const eventPassingOutClass = event?.passingOutClass;

                            return (
                                (eventPassingYear && eventPassingYear === currentUser?.passingYear) ||
                                (eventPassingOutClass && eventPassingOutClass === currentUser?.passingOutClass) ||
                                event?.alumni === true
                            );
                        });
                    }
                    const mappedEvents = events?.map(event => ({
                        ...event,
                        start: event?.startDate,
                        end: event?.endDate,
                    }));
                    setEvents(mappedEvents);
                } else {
                    setLoading(false);
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
            } catch (err) {
                setLoading(false);
                toast.error('Something went wrong!, Please try again..!', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        }, 500)
    };

    const role = localStorage.getItem('role')
    const id = localStorage.getItem('id')
    const selectedRoleId = 2

    const listAllAlumni = async () => {
        try {
            const apiRes = await listAllUsers({ roleId: selectedRoleId });
            if (apiRes.status === 200) {
                const filteredData = apiRes?.data?.data?.filter(item => item?.id !== id).map(item => ({ ...item }));
                setUsers(filteredData || []);
            } else {
                toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        } catch (err) {
            toast.error('Something went wrong!, Please try again..!', {
                position: toast.POSITION.BOTTOM_LEFT
            })
        }
    };

    // const options = users?.map((user) => ({
    //     label: `${user?.firstName} ${user?.lastName} - ${user?.email}`,
    //     value: user?.email,
    //     // avatar: <Avatar alt={user?.firstName} src={API_BASE_URL + user?.avatar} />
    // })) ?? [];

    const options = users
        ?.filter((user) => user?.isPaymentCompleted !== false)
        .map((user) => ({
            label: `${user?.firstName} ${user?.lastName} - ${user?.email}`,
            value: user?.email,
            // avatar: <Avatar alt={user?.firstName} src={API_BASE_URL + user?.avatar} />
        })) ?? [];

    useEffect(() => {
        currentUserDetails()
        // getEvents();
        listAllAlumni();
        getRoles();
    }, [])

    const initialValues = {
        title: '',
        startDate: '',
        endDate: '',
        // guests: [],
        messageContentWithHtml: '',
        passingYear: '',
        passingOutClass: '',
        scheduleDate: '',
        alumni: false,
        roleId: null,
    };


    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Title is required'),
        startDate: Yup.string()
            .required('Start date is required'),
        endDate: Yup.string()
            .required('End date is required'),
        messageContentWithHtml: Yup.string()
            .required('Description is required'),
        // passingYear: Yup.string().when('alumni', {
        //     is: false,
        //     then: Yup.string().required('Passing Year is required'),
        // }),
        // passingOutClass: Yup.string().when('alumni', {
        //     is: false,
        //     then: Yup.string().required('Passing Out Class is required'),
        // }),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            const alumniRoleId = roles?.find(role => role.role === 'Alumni')?.id;
            if (!editId) {
                try {
                    setLoadingBtn(true)
                    const apiRes = await CreateEvent({
                        title: values?.title,
                        startDate: dayjs(values?.startDate).format(),
                        endDate: dayjs(values?.endDate).format(),
                        // guests: values?.guests || [],
                        messageContentWithHtml: values?.messageContentWithHtml,
                        alumni: values?.alumni,
                        roleId: values?.alumni ? alumniRoleId : null,
                        passingYear: values?.passingYear,
                        passingOutClass: values?.passingOutClass,
                        scheduleDate: values?.scheduleDate ? dayjs(values?.scheduleDate).format() : null,
                        allDay: false
                    });
                    if (apiRes.status === 201) {
                        getEvents()
                        toast.success('Event created successfully..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                    } else {
                        toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                    }
                } catch (err) {
                    toast.error('Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                } finally {
                    setLoadingBtn(false)
                    handleCloseDialog();
                    formik.resetForm();
                }
            } else {
                try {
                    setLoadingBtn(true)
                    const apiRes = await UpdateEvent(editId, {
                        title: values?.title,
                        startDate: dayjs(values?.startDate).format(),
                        endDate: dayjs(values?.endDate).format(),
                        // guests: values?.guests || [],
                        messageContentWithHtml: values?.messageContentWithHtml,
                        alumni: values?.alumni,
                        roleId: values?.alumni ? alumniRoleId : null,
                        passingYear: values?.passingYear,
                        passingOutClass: values?.passingOutClass,
                        scheduleDate: values?.scheduleDate ? dayjs(values?.scheduleDate).format() : null,
                        allDay: false
                    });
                    if (apiRes.status === 201) {
                        getEvents()
                        toast.success('Event updated successfully..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                    } else {
                        toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                    }
                } catch (err) {
                    toast.error('Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                } finally {
                    setLoadingBtn(false)
                    handleCloseDialog();
                    formik.resetForm();
                }
            }
        }
    });

    const confirmDelete = async () => {
        try {
            const res = await DeleteEvent(editId);
            if (res.status === 201) {
                swal('Event deleted successfully!', {
                    icon: 'success',
                });
                // setAnchorEl(null);
                handleCloseDialog();
                getEvents();
            } else {
                swal('Something went wrong!, Please try again.');
            }
        } catch (err) {
            setLoading(false);
            toast.error('Something went wrong!, Please try again.', {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        }
    };

    const handleDeleteConfirmation = () => {
        swal({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                confirmDelete();
            }
        });
    };

    const currentYear = dayjs().year();
    // const minDate = `${currentYear}-01-01`; 
    const minDate = dayjs();
    const maxDate = new Date(2026, 11, 31); // current year

    return (
        <div>
            <Container maxWidth="xxl">
                <Typography mb={5} variant="h4" gutterBottom>
                    Calendar
                </Typography>

                {!loading ?
                    <div>
                        <Loader />
                    </div> :
                    <>
                        <Box className='calender_page'
                            py={3}
                            px={3}
                            sx={{
                                bgcolor: 'white'
                            }}>
                            <FullCalendar
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                initialView="dayGridMonth"
                                editable
                                selectable
                                selectMirror
                                dayMaxEvents
                                weekends
                                events={events}
                                eventDisplay="block"
                                eventTimeFormat={{ hour: 'numeric', minute: '2-digit', hour12: true }}
                                eventClick={role === 'Administrator' ? handleEventClick : handleEventAlumni}
                                displayEventTime
                                customButtons={{
                                    myButton: {
                                        text: 'Create Event',
                                        click: handleButtonClick,
                                    },
                                }}
                                headerToolbar={{
                                    left: 'prev,next',
                                    center: 'title',
                                    // right: 'dayGridMonth,timeGridWeek,timeGridDay myButton'
                                    right: role === 'Administrator' ? 'dayGridMonth,timeGridWeek,timeGridDay myButton' : 'dayGridMonth,timeGridWeek,timeGridDay'
                                }}
                            />
                        </Box>
                    </>
                }

                <Dialog
                    maxWidth="sm"
                    fullWidth
                    aria-describedby="alert-dialog-slide-description"
                    open={openDialog}
                    onClose={handleCloseDialog}
                >
                    {/* <DialogTitle mt={2}>Create Event</DialogTitle> */}
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <DialogTitle mt={2}>Create Event</DialogTitle>

                        {editId && (
                            <IconButton aria-label="Delete" onClick={handleDeleteConfirmation}
                                sx={{ mr: 2 }}
                            >
                                <Tooltip title="Delete" placement="top">
                                    <DeleteIcon color='#d7b56d' />
                                </Tooltip>
                            </IconButton>
                        )
                        }
                    </Box>

                    <form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(e) }} className='calendar_popup'>
                        <DialogContent>
                            <Stack spacing={2}>
                                <Box
                                >
                                    <TextField
                                        sx={{
                                            mt: -1
                                        }}
                                        fullWidth
                                        id="title"
                                        name="title"
                                        label="Add title"
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        InputProps={{
                                            startAdornment: (
                                                <ListAltIcon sx={{ mr: 1, my: -0.5 }} color="action" />
                                            ),
                                        }}
                                    />
                                    {formik.touched.title && formik.errors.title && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.title}</p>}
                                </Box>

                                <Stack direction='row' spacing={2}>
                                    <Box>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDateTimePicker
                                                label="Start Date and Time"
                                                inputFormat="DD/MM/YYYY HH:mm:ss"
                                                value={formik.values?.startDate}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue("startDate", newValue);
                                                    formik.setFieldTouched("startDate", true);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{
                                                            width: "100%",
                                                        }}
                                                        {...params}
                                                        name="startDate"
                                                        onBlur={formik.handleBlur}
                                                        error={
                                                            formik.errors.startDate &&
                                                            formik.touched.startDate
                                                        }
                                                    />
                                                )}
                                                minDateTime={minDate}
                                            // maxDate={maxDate}
                                            />
                                        </LocalizationProvider>
                                        {formik.touched.startDate && formik.errors.startDate && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.startDate}</p>}
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>to</Box>
                                    <Box>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDateTimePicker
                                                label="End Date and Time"
                                                inputFormat="DD/MM/YYYY HH:mm:ss"
                                                value={formik.values?.endDate}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue("endDate", newValue);
                                                    formik.setFieldTouched("endDate", true);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{
                                                            width: "100%",
                                                        }}
                                                        {...params}
                                                        name="endDate"
                                                        onBlur={formik.handleBlur}
                                                        error={
                                                            formik.errors.endDate &&
                                                            formik.touched.endDate
                                                        }
                                                    />
                                                )}
                                                minDateTime={minDate}
                                            // maxDate={maxDate}
                                            />
                                        </LocalizationProvider>
                                        {formik.touched.endDate && formik.errors.endDate && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.endDate}</p>}
                                    </Box>
                                </Stack>

                                {!formik.values.alumni && <Stack direction='row' spacing={2}>
                                    <Box width={'100%'}>
                                        <TextField
                                            sx={{
                                                width: '100%'
                                            }}
                                            fullWidth
                                            id="passYear"
                                            name="passingYear"
                                            label="Passing out year"
                                            type="number"
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            value={formik.values.passingYear}
                                            onChange={formik.handleChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <SchoolIcon color="action" />
                                                ),
                                            }}
                                        />
                                        {formik.touched.passingYear && formik.errors.passingYear && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.passingYear}</p>}
                                    </Box>
                                    <Box width={'100%'}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Passing Out Class</InputLabel>
                                            <Select
                                                sx={{
                                                    width: '100%',
                                                }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="passingOutClass"
                                                // value={formik.values.passingOutClass}
                                                value={formik.values?.passingOutClass || ''}
                                                label="Passing Out Class"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            >
                                                <MenuItem value={"Matriculation"}>Matriculation</MenuItem>
                                                <MenuItem value={"Senior Secondary/Plus Two"}>Senior Secondary/Plus Two</MenuItem>
                                            </Select>

                                        </FormControl>
                                        {formik.touched.passingOutClass && formik.errors.passingOutClass && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.passingOutClass}</p>}
                                    </Box>
                                </Stack>}

                                {/* {!formik.values.alumni && <Box>
                                    <Autocomplete
                                        multiple
                                        id="tags-outlined"
                                        options={options}
                                        getOptionLabel={(option) => option?.label}
                                        filterSelectedOptions
                                        filterOptions={(options, { inputValue }) => {
                                            const selectedValues = formik.values.guests.map((guest) => guest.value);
                                            return options.filter((option) => {
                                                const isDuplicate = selectedValues.includes(option.value);
                                                const isMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());
                                                return !isDuplicate && isMatch;
                                            });
                                        }}
                                        value={formik.values.guests} // set the initial value of the Autocomplete component
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue('guests', newValue); // set the value of the "guests" field
                                        }}
                                        onBlur={formik.handleBlur}
                                        inputValue={inputValue}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Add guests"
                                            />
                                        )}
                                    />
                                    {formik.touched.guests && formik.errors.guests && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.guests}</p>}
                                </Box>
                                } */}

                                <Box mb={2}>
                                    <CKEditor
                                        id="messageContentWithHtml"
                                        name="messageContentWithHtml"
                                        className="inputText"
                                        editor={ClassicEditor}
                                        data={formik.values?.messageContentWithHtml}
                                        value={formik.values?.messageContentWithHtml}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.messageContentWithHtml && Boolean(formik.errors.messageContentWithHtml)}
                                        onChange={(event, editor) => {
                                            if (editor) {
                                                formik.setFieldValue("messageContentWithHtml", editor.getData());
                                                formik.setFieldValue(
                                                    "rawContent",
                                                    new DOMParser().parseFromString(
                                                        editor.getData(),
                                                        "text/html"
                                                    ).documentElement.textContent
                                                );
                                            }
                                        }}
                                        config={{
                                            toolbar: ['bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', '|', 'heading', 'blockquote', '|', 'undo', 'redo']
                                        }}
                                    />

                                    {formik.touched.messageContentWithHtml && formik.errors.messageContentWithHtml &&
                                        <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>
                                            {formik.errors.messageContentWithHtml}
                                        </p>}
                                </Box>

                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formik.values.alumni}
                                                onChange={(e) => {
                                                    formik.setFieldValue('alumni', e.target.checked);
                                                    // formik.setFieldValue('guests', e.target.checked ? [] : formik.values.guests);
                                                    formik.setFieldValue('passingYear', e.target.checked ? '' : formik.values.passingYear)
                                                    formik.setFieldValue('passingOutClass', e.target.checked ? '' : formik.values.passingOutClass)
                                                }}
                                                name="alumni"
                                                color="primary"
                                            />
                                        }
                                        label="All Alumni"
                                    />
                                </Box>

                                <Box mt={-1}>
                                    <Button
                                        onClick={handleClickOpen}
                                        startIcon={<ScheduleSendIcon />}> Schedule send</Button>
                                </Box>

                                <Dialog
                                    maxWidth="xs"
                                    fullWidth
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="draggable-dialog-title"
                                >
                                    <DialogTitle id="draggable-dialog-title">
                                        Schedule send
                                    </DialogTitle>
                                    <DialogContent dividers>

                                        <Box my={2}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDateTimePicker
                                                    label="Pick a date and time"
                                                    inputFormat="DD/MM/YYYY HH:mm:ss"
                                                    value={formik.values?.scheduleDate}
                                                    onChange={(newValue) => {
                                                        formik.setFieldValue("scheduleDate", newValue);
                                                        // formik.setFieldTouched("scheduleDate", true);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                            {...params}
                                                            name="scheduleDate"
                                                            onBlur={formik.handleBlur}
                                                            error={
                                                                formik.errors.scheduleDate &&
                                                                formik.touched.scheduleDate
                                                            }
                                                        />
                                                    )}
                                                    minDateTime={
                                                        formik?.values?.startDate
                                                            ? dayjs(formik?.values?.startDate, "DD/MM/YYYY HH:mm:ss").startOf("minute")
                                                            : minDate
                                                    }
                                                    maxDateTime={
                                                        formik?.values?.endDate
                                                            ? dayjs(formik?.values?.endDate, "DD/MM/YYYY HH:mm:ss").startOf("minute")
                                                            : maxDate
                                                    }
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button autoFocus onClick={handleClose}>
                                            Done
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                            </Stack>
                        </DialogContent>

                        <DialogActions>
                            <Stack spacing={1}
                                pr={7}
                                mb={2}
                                mt={-1}
                                direction={'row'}>
                                <LoadingButton
                                    type='submit'
                                    endIcon={<SaveIcon />}
                                    loading={loadingBtn}
                                    loadingPosition="end"
                                    sx={{
                                        backgroundColor: "rgb(0, 171, 85)"
                                    }}
                                    variant="contained"
                                >
                                    {/* <span>{editIndex === -1 ? "Add" : "Save"}</span> */}
                                    <span>Save</span>
                                </LoadingButton>
                                <Divider
                                    disabled={formik.isSubmitting}
                                    orientation="vertical"
                                    flexItem />
                                <Box>
                                    <Button onClick={handleCloseDialog} variant="contained" color="error">
                                        Cancel
                                    </Button>
                                </Box>
                            </Stack>
                        </DialogActions>
                    </form>
                </Dialog>

                {/* Dialog Box for Alumni */}
                <Dialog
                    className='edit_popup'
                    maxWidth="xs"
                    fullWidth
                    aria-describedby="alert-dialog-slide-description"
                    open={openAlumniDialog}
                    onClose={handleCloseAlumniDialog}
                >
                    <DialogTitle>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Box>Event Details</Box>
                            <Box
                                sx={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}
                                onClick={handleCloseAlumniDialog}
                            >
                                <CloseIcon />
                            </Box>
                        </Stack>
                    </DialogTitle>
                    <Divider /> {/* Add the Divider component with margin */}
                    <DialogContent>
                        <Stack direction={'column'} className='about_alumni'>
                            <Stack direction={'row'} spacing={1}>
                                <Box>
                                    <SubtitlesIcon />
                                </Box>
                                <Box sx={{
                                    fontSize: 18,
                                    color: '#00793a',
                                    fontWeight: 600,
                                    wordWrap: 'break-word',
                                }}>
                                    <Stack>
                                        <Box>
                                            {formik?.values?.title && formik?.values?.title?.charAt(0).toUpperCase() + formik?.values?.title?.slice(1)}
                                        </Box>
                                        <Box color={'text.disabled'}>
                                            {dayjs(formik?.values?.startDate).format('dddd, MMMM D⋅h:mm A')} to {dayjs(formik?.values?.endDate).format('dddd, MMMM D⋅h:mm A')}
                                        </Box>
                                    </Stack>
                                </Box>
                            </Stack>
                            <Stack direction={'row'} spacing={1}>
                                <Box><PeopleAltIcon /></Box>
                                <Box>Guests:</Box>
                                <Box>
                                    {formik?.values?.alumni === true &&
                                        <Typography>All alumni</Typography>
                                    }
                                    {formik?.values?.passingOutClass && formik?.values?.passingYear.length === 0 &&
                                        <Typography>{formik?.values?.passingOutClass} Passing out Alumni</Typography>
                                    }
                                    {formik?.values?.passingYear && formik?.values?.passingOutClass.length === 0 &&
                                        <Typography>{formik?.values?.passingYear} Passing out Alumni</Typography>
                                    }
                                    {formik?.values?.passingYear && formik?.values?.passingOutClass &&
                                        <Typography>{formik?.values?.passingYear} ,{formik?.values?.passingOutClass} Passing out Alumni</Typography>
                                    }
                                </Box>
                            </Stack>
                            <Stack direction={'row'} spacing={1}>
                                <Box><SubjectIcon /></Box>
                                <Box>
                                    <Typography>
                                        Please check your email...
                                        {/* <span dangerouslySetInnerHTML={{
                                            __html: formik?.values?.messageContentWithHtml?.slice(0, 40)
                                        }} />
                                        {formik?.values?.messageContentWithHtml?.length > 40 && '...'} */}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Stack>
                    </DialogContent>

                    {/* <DialogActions>

                    </DialogActions> */}
                </Dialog>

                <ToastContainer />
            </Container>
        </div >
    );
}


export default Calendar;
