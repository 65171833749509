import React, { useEffect, useState } from 'react';


// icons
import MailIcon from '@mui/icons-material/Mail';
import CakeIcon from '@mui/icons-material/Cake';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import Divider from '@mui/material/Divider';
import LocationOnIcon from '@mui/icons-material/LocationOn';


import {
    Box,
    Stack,
    Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import SocialProfiles from './SocialProfiles';

function BasicProfile({
    basicDetails
}) {


    const [loading, setLoading] = useState(false)

    return (
        <>
       
            <Stack
                py={3}
                style={{ width: '100%!important' }}
                // px={5}
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                spacing={2} className='basic_info'>
                <Box
                    bgcolor={'white'}
                    px={4}
                    py={2}
                    sx={{
                        width: '100%',
                        boxShadow: '0px 0px 2px -2px',
                        borderRadius: 2,
                    }}  className='about_alumni'>
                    <Typography variant="h5" gutterBottom>
                        About
                    </Typography>
                    <Divider sx={{
                        width: '100%',
                        borderStyle: 'dashed',
                        marginBottom: '15px'
                    }} />

                    <>
                        <Stack direction={'row'} spacing={2}>
                            <Box><LocationOnIcon sx={{ fontSize: '17px' }} /></Box>
                            <Box
                                sx={{
                                    "&:hover": {
                                        cursor: "pointer",
                                        textDecoration: "underline"
                                    },
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}
                            >
                                Live at <span style={{ color: 'black', fontWeight: 'bold' }}>{basicDetails?.location}</span>
                            </Box>
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <Box><MailIcon sx={{ fontSize: '17px' }} /></Box>
                            <Box
                                sx={{
                                    "&:hover": {
                                        cursor: "pointer",
                                        textDecoration: "underline"
                                    },
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}
                            >
                                <a href={`mailto:${basicDetails?.email}`}  style={{color:'unset',textDecoration: 'none'}}>{basicDetails?.email}</a>
                            </Box>
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <Box><CakeIcon sx={{ fontSize: '17px' }} /></Box>
                            <Box
                                style={{ wordWrap: "break-word" }}
                                sx={{
                                    "&:hover": {
                                        cursor: "pointer",
                                        textDecoration: "underline"
                                    },
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}

                            >
                                <Typography >
                                    {dayjs(basicDetails?.dob).format('DD-MM-YYYY')}
                                </Typography>
                            </Box>
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <Box><PhoneAndroidIcon sx={{ fontSize: '17px' }} /></Box>
                            <Box
                                style={{ wordWrap: "break-word" }}
                                sx={{
                                    "&:hover": {
                                        cursor: "pointer",
                                        textDecoration: "underline"
                                    },
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}
                            >
                                <Typography >
                                <a href={`tel:${basicDetails?.mobileNo}`}  style={{color:'unset',textDecoration: 'none'}}>{basicDetails?.mobileNo}</a>
                                </Typography>
                            </Box>
                        </Stack>
                    </>


                </Box>
                <Box
                    bgcolor={'white'}
                    px={4}
                    py={2}
                    sx={{
                        width: '100%',
                        boxShadow: '0px 0px 2px -2px',
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Social
                    </Typography>
                    <Divider
                        sx={{
                            width: '100%',
                            borderStyle: 'dashed'
                        }} />
                    <SocialProfiles />
                </Box>
            </Stack>
            
        </>
    )
}

export default BasicProfile
