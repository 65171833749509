import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Unstable_Grid2';
import '../../css/main.css';
// icons

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Divider from '@mui/material/Divider';
import PeopleIcon from '@mui/icons-material/People';
import EditIcon from '@mui/icons-material/Edit';

import {
    Box,
    Card,
    Stack,
    Container,
    Typography,
} from '@mui/material';
import { API_BASE_URL } from '../../Setting';
import BasicProfile from './BasicProfile';
import EducationProfile from './EducationProfile';
import WorkingProfile from './WorkingProfile';
import Friends from './Friends';

import { GetUserDetails } from '../../services/users';


function Profile() {
    const [value, setValue] = React.useState('1');
    const [role, setRole] = React.useState('');
    const [loading, setLoading] = useState(false);
    const [basicDetails, setBasicDetails] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);

    const id = localStorage.getItem('id')
    const userRole = localStorage.getItem('role')
    const navigate = useNavigate();

    const getBasicDetails = async () => {

        try {
            setLoading(true);
            const apiRes = await GetUserDetails(id);
            if (apiRes.status === 200) {
                setBasicDetails(apiRes?.data?.data || {});
                const url = apiRes?.data?.data?.avatar ? API_BASE_URL + apiRes?.data?.data?.avatar : ""
                setImageUrl(url)
            } else {
                toast.error(apiRes?.data?.data?.message || 'Something went wrong!, Please try again..!', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error('Something went wrong!, Please try again..!', {
                position: toast.POSITION.BOTTOM_LEFT
            })
        }
    };

    useEffect(() => {
        getBasicDetails();
    }, [id])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className='profile_page'>

            <Container maxWidth="xxl">
                <Stack direction="row" mb={1} >
                    <Typography variant="h4" gutterBottom>
                        Profile
                    </Typography>
                </Stack>

                <Grid container spacing={{ xs: 2, md: 1 }}>
                    <Grid item
                        xs={12} sm={12} md={12}
                    >
                        <TabContext value={value}>
                            <Card sx={{ maxWidth: '100%', mt: '20px' }}>
                                <CardMedia
                                    sx={{
                                        // filter: 'brightness(50%)',
                                        filter: 'brightness(40%) blur(2px)',
                                        backgroundColor: 'rgba(0, 82, 73, 0.8)',
                                        objectFit: 'cover',
                                    }}
                                    component="img"
                                    height="150"
                                    image={imageUrl}
                               />

                                <CardContent sx={{ width: '100%', mb: -8 }}>
                                    <Stack
                                        direction={'row'}
                                    >
                                        <Box mt={{xs:-25,sm:-16,md:-16}} mx={{ xs: 'auto', sm: '0px', md: '0px' }}  className='student_pp'>
                                            <Avatar
                                                sx={{
                                                    border: '5px solid white',
                                                    width: 140,
                                                    height: 140,
                                                }}
                                                src={imageUrl}

                                            />
                                        </Box>

                                        <Box mt={-15} ml={2}>
                                            <Typography
                                                component={Stack}
                                                direction={'column'}
                                                zIndex={'9999'}
                                                color={'white'}
                                                mt={{xs:-5,sm:3,md:5}}
                                                // pt={{xs:2,sm:3,md:5}}
                                            >
                                                <Box
                                                    sx={{
                                                        textTransform: 'Capitalize',
                                                        fontWeight: 'bold',
                                                        fontSize: 26,
                                                        opacity: '0.9',
                                                    }}
                                                className='User_name'>
                                                    {basicDetails?.firstName} {basicDetails?.lastName}
                                                    <EditIcon
                                                        onClick={() => navigate(`/profile/${id}`)}
                                                        sx={{
                                                            color: ' #3498db',
                                                            mx: 2,
                                                            "&:hover": {
                                                                cursor: "pointer",
                                                            },
                                                        }}
                                                    />
                                                </Box>
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Divider sx={{
                                                borderStyle: 'dashed'
                                            }} />
                                        </Box>
                                    </Stack>
                                </CardContent>

                                <CardActions component={Stack}
                                    sx={{
                                        width: '100%',
                                        boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
                                    }}>
                                    <Box
                                        style={{ flexWrap: '0', display: 'flex', justifyContent: 'end' }}
                                        pr={2}
                                        sx={{
                                            maxWidth: { xs: '100', sm: '100%', md: '100%' },
                                            width: '100%',
                                        }}
                                        className='basic_tabs'>
                                        <TabList
                                            variant="scrollable"
                                            scrollButtons
                                            allowScrollButtonsMobile
                                            aria-label="scrollable auto tabs example"
                                            onChange={handleChange}
                                            ml={'auto'} >
                                            <Tab icon={<AccountBoxIcon />} label="Basic Details" value="1" />
                                            <Tab icon={<EmojiEventsIcon />} label="Educational Details" value="2" />
                                            <Tab icon={<WorkOutlineIcon />} label="Working Details" value="3" />
                                            {/* {userRole === "Administrator" ? '' : <Tab icon={<PeopleIcon />} label="Friends" value="4" />} */}
                                        </TabList>
                                    </Box>
                                </CardActions>
                            </Card>

                            <TabPanel value="1"
                                sx={{ borderRadius: 2, mt: 4, }}
                            className='tabs_rpadding'>

                                <BasicProfile basicDetails={basicDetails} />

                            </TabPanel>

                            <TabPanel value="2" sx={{borderRadius: 2, mt: 4, }}  className='tabs_rpadding'>
                                <Container maxWidth="xxl" className='edu_info'>
                                    <EducationProfile />
                                </Container>
                            </TabPanel>

                            <TabPanel value="3" sx={{borderRadius: 2, mt: 4, }} className='tabs_rpadding'>
                                <Container maxWidth="xxl" className='work_info'>
                                    <WorkingProfile />
                                </Container>
                            </TabPanel>

                            {/* <TabPanel value="4" sx={{ boxShadow: '0px 0px 5px -2px', borderRadius: 2, mt: 4, }}>
                                <Container maxWidth="lg"
                                >
                                    <Friends />
                                </Container>
                            </TabPanel> */}
                        </TabContext>
                    </Grid>
                </Grid>
                <ToastContainer />
            </Container >
        </div >
    )
}

export default Profile
