import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import CountUp from 'react-countup';
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Button, Box, Stack } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { appCounters } from '../../services/analytics';
import analytic1 from '../../svg/image.svg';
import analytic2 from '../../svg/character_2.png';
import totalUser from '../../svg/totalUser.svg';
import totalActive from '../../svg/totalActive.svg';
import newReg from '../../svg/newReg.svg';

// ----------------------------------------------------------------------

export default function AppAnalytics() {
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [counters, setCounters] = useState([]);

  useEffect(() => {
    getAppCounters();
  }, []);

  const id = localStorage.getItem('id')
  const navigate = useNavigate();

  const getAppCounters = async () => {
    try {
      setLoading(true);
      const apiRes = await appCounters();
      if (apiRes.status === 200) {
        setCounters(apiRes?.data?.data)
      } else {
        toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
          position: toast.POSITION.BOTTOM_LEFT
        })
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error('Something went wrong!, Please try again..!', {
        position: toast.POSITION.BOTTOM_LEFT
      })
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Alumni </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Welcome, {localStorage.getItem('firstName')}!
        </Typography>

        <Grid className='dash_board' direction={'row'}
          bgcolor={'#e4f8ee'}
          container
          color={'rgb(0, 82, 73)'}
          sx={{
            // height: '40vh',
            mb: 3,
            borderRadius: '20px',
          }}
        >
          <Grid item xs={12} sm={12} md={8} px={3} py={2}>
            <Stack direction={'column'} spacing={2}>
              <Box>
                <Typography variant="h4" gutterBottom>
                  Congratulations!
                </Typography>
              </Box>
              <Box>
                <Typography color={'rgb(0, 82, 73)'} variant="body1" gutterBottom>
                  Complete your profile
                </Typography>
              </Box>
              <Box>
                <Button onClick={()=>navigate(`/profile/${ id }`)} variant='contained' sx={{ bgcolor: 'rgb(0, 171, 85)' }} >
                  Edit Now
                </Button>
              </Box>
            </Stack>
          </Grid>

          <Grid item
            xs={12}
            sm={12}
            md={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Stack direction={'row'}>
              <Box>
                <img
                  style={{
                    width: '150px',
                    height: '150px',
                  }}
                  src={analytic1} alt='' />
              </Box>
              <Box ml={-11} mt={6}>
                <img
                  style={{
                    width: '60px',
                    height: '150px',
                  }}
                  src={analytic2} alt='' />
              </Box>
            </Stack>
          </Grid>
        </Grid>
        {/* //------------------------------------ */}
        <Grid mb={3} container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Stack sx={{
              px: 2,
              py: 2,
              boxShadow: '0px 0px 5px -2px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
            }} direction={'row'} bgcolor={'white'}>

              <Box width={'100%'}>
                <Typography variant='h3'>
                  <CountUp duration={3} end={counters?.no_of_alumni} />
                </Typography>
                <Typography variant='body1'>
                  Total Alumni
                </Typography>
              </Box>

              <Box width={'100%'} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <img src={totalUser} style={{ width: '120px', height: '120px', borderRadius: '50%', backgroundColor: '#e8eaf6', padding: '8px' }} alt='' />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Stack sx={{
              px: 2,
              py: 2,
              boxShadow: '0px 0px 5px -2px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
            }} direction={'row'} bgcolor={'white'}>
              <Box width={'100%'}>
                <Typography variant='h3'>
                  <CountUp duration={3} end={counters?.no_of_active_alumni} />
                </Typography>
                <Typography variant='body1'>
                  Total Active Alumni
                </Typography>
              </Box>
              <Box width={'100%'} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <img src={totalActive} style={{ width: '120px', height: '120px', borderRadius: '50%', backgroundColor: '#e8eaf6', padding: '8px' }} alt='' />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Stack sx={{
              px: 2,
              py: 2,
              boxShadow: '0px 0px 5px -2px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
            }} direction={'row'} bgcolor={'white'}>
              <Box width={'100%'}>
                <Typography variant='h3'>
                  <CountUp duration={3} end={counters?.no_of_new_alumni_registered} />
                </Typography>
                <Typography variant='body1'>
                  New Registration
                </Typography>
              </Box>
              <Box width={'100%'} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <img src={newReg} style={{ width: '120px', height: '120px', borderRadius: '50%', backgroundColor: '#e8eaf6', padding: '8px' }} alt='' />
              </Box>
            </Stack>
          </Grid>
        </Grid>

        {/* <Grid container spacing={3} direction='row' >
          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/ assets / images / covers / cover_${ index + 1}.jpg`,
          postedAt: faker.date.recent(),
              }))}
            />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <AppOrderTimeline
            title="Order Timeline"
            list={[...Array(5)].map((_, index) => ({
              id: faker.datatype.uuid(),
              title: [
                '1983, orders, $4220',
                '12 Invoices have been paid',
                'Order #37745 from September',
                'New order placed #XF-2356',
                'New order placed #XF-2346',
              ][index],
              type: `order${index + 1}`,
              time: faker.date.past(),
            }))}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <AppTrafficBySite
            title="Traffic by Site"
            list={[
              {
                name: 'FaceBook',
                value: 323234,
                icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
              },
              {
                name: 'Google',
                value: 341212,
                icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
              },
              {
                name: 'Linkedin',
                value: 411213,
                icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
              },
              {
                name: 'Twitter',
                value: 443232,
                icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
              },
            ]}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={8}>
          <AppTasks
            title="Tasks"
            list={[
              { id: '1', label: 'Create FireStone Logo' },
              { id: '2', label: 'Add SCSS and JS files if required' },
              { id: '3', label: 'Stakeholder Meeting' },
              { id: '4', label: 'Scoping & Estimations' },
              { id: '5', label: 'Sprint Showcase' },
            ]}
          />
        </Grid>
      </Grid> */}
    </Container>
    </>
  );
}
