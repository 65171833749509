/**
 * @scope api related to education details
 * 
 * @author Umasankar
 * @since 2nd feb 2023
 */
import { API } from './provider';
import { V1, USERS, CREATE_EDUCATIONAL_DETAILS, GET_EDUCATIONAL_DETAILS, DELETE_EDUCATIONAL_DETAILS, UPDATE_EDUCATIONAL_DETAILS, LIST_EDUCATIONAL_DETAILS } from './endpoints';

export const listAllUsers = (query) => {
    return API.GET(V1, `${USERS}?roleId=${query?.roleId}`);
};

// Educational details apis
export const CreateEducationalDetails = (payload) => { return API.POST(V1, CREATE_EDUCATIONAL_DETAILS, payload) };
export const ListEducationDetails = () => { return API.GET(V1, LIST_EDUCATIONAL_DETAILS); };
export const DeleteEducationDetails = (id) => { return API.DELETE(V1, DELETE_EDUCATIONAL_DETAILS.replace('{id}', id)); }
export const UpdateEducationDetails = (id, payload) => { return API.PUT(V1, UPDATE_EDUCATIONAL_DETAILS.replace('{id}', id), payload); }
export const GetEducationalDetails = (id) => { return API.GET(V1, GET_EDUCATIONAL_DETAILS.replace('{id}', id)); }