import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useReactToPrint } from "react-to-print";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "react-data-table-component";
import NorthIcon from '@mui/icons-material/North';
import {
  Box,
  Grid,
  Menu,
  Chip,
  Card,
  Paper,
  Stack,
  Table,
  Button,
  Select,
  Popover,
  Tooltip,
  Checkbox,
  MenuItem,
  TableRow,
  FormGroup,
  TableHead,
  TextField,
  Container,
  TableBody,
  TableCell,
  IconButton,
  Typography,
  FormControl,
  ListItemText,
  ListItemIcon,
  OutlinedInput,
  InputAdornment,
  TableContainer,
  FormControlLabel,
  Divider
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PrintIcon from '@mui/icons-material/Print';
import FilterListIcon from '@mui/icons-material/FilterList';
import IosShareIcon from '@mui/icons-material/IosShare';
import Lottie from "lottie-react";
import * as swal from 'sweetalert';
import noData from '../../animations/Circle.json';

import { approvePaymentStatus, paymentsList,deleteUser } from "../../services/authentication";
import Loader from '../../loader/Loader';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const PaymentsList = () => {
  const tableCustomStyles = {
    headRow: {
      style: {
        fontSize: '15px',
        // color: '#223336',
        // backgroundColor: '#e7eef0'
      },
    },
    rows: {
      style: {
        backgroundColor: '#fafafa',
        fontSize: '15px',
      },
      stripedStyle: {
        backgroundColor: "#fff"
      }
    },
    headCells: {
      style: {
        // paddingLeft: '20px', // Adjust the padding here
        // paddingRight: '10px', // Adjust the padding here
      },
    },
    cells: {
      style: {
        // paddingLeft: '10px', 
        // paddingRight: '10px', 
      },
    },
  };

  const columns = [
    {
      name: "Name",
      selector: row => row.name,
      sortable: true,
      minWidth: "190px"
    },
    {
      name: "Email",
      selector: row => row.email,
      sortable: false,
      minWidth: "250px"
    },
    {
      name: "Contact",
      selector: row => row.contact,
      sortable: true,
      minWidth: "200px",
    },
    // {
    //   name: "Regd. Fee",
    //   selector: "regFee",
    //   sortable: false,
    // },
    {
      name: "Paid",
      selector: row => row.paid,
      sortable: false,
    },
    {
      name: "Status",
      selector: row => row.status,
      sortable: false,
      cell: (row) => (
        <Chip
          label={row?.status}
          color={row?.status === 'SUCCESS' ? 'success'
            : row?.status === 'PENDING' ? 'warning' :
              row?.status === 'FAILED' ? 'error' : ''
          }
          variant="outlined"
        />
      ),
    },
    {
      name: 'Date', // Empty field property for the triple dots column
      selector: row => row.date,
      sortable: false,
    },
    {
      name: 'Note', // Empty field property for the triple dots column
      selector: row => row.note,
      sortable: false,
    },
    {
      name: 'Action',
      sortable: false,
      right: false,
      minWidth: "150px",
      cell: (row) => (
        <>
          {/* <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(row.id, event)}>
              <MoreVertIcon />
            </IconButton> */}
          {row.status === 'PENDING' &&
            <>
              <Button variant='outlined' onClick={(event) => handleClickOpen(row.id, event)}>Approve</Button>
              <Divider orientation="vertical" flexItem />
            </>
          }
          <Button variant='outlined' color='error' onClick={(event) => handleDelete(row.id)}>Delete</Button>
        </>
      ),
    }
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(["ALL"]);
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  // Define the initial value of searchText
  const [searchText, setSearchText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [price, setPrice] = useState('')
  const [selectedId, setSelectedId] = useState('')

  const handleClickOpen = (id, event) => {
    setOpenModal(true);
    setSelectedId(id)
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = async (id) => {
    try {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    confirmDelete()
                }
            });

        const confirmDelete = async () => {
            const res = await deleteUser(id)
            if (res.status === 200) {
                swal("User deleted succesfully!", {
                    icon: "success",
                });
                ListPayments();
            } else {
                swal('Something went wrong!, Please try again.');
            }
        }
    } catch (err) {
        setLoading(false);
        toast.error('Something went wrong!, Please try again.', {
            position: toast.POSITION.BOTTOM_LEFT
        })
    }
}

  // Update the value of searchText based on user input or any other criteria
  // const handleSearch = (event) => {
  //   setSearchText(event.target.value);
  // };

  useEffect(() => {
    ListPayments();
  }, [])

  const ListPayments = async () => {
    setTimeout(async () => {
      try {
        const apiRes = await paymentsList();
        if (apiRes.status === 200) {
          setLoading(true);
          const filteredData = apiRes?.data?.data
          const filteredTable = filteredData.map((item, index) => ({
            slno: index + 1,
            id: item?.id,
            email: item?.email,
            name: `${item?.firstName} ${item?.lastName}`,
            firstName: item?.firstName,
            lastName: item?.lastName,
            contact: item?.mobileNo,
            regFee: item?.amount,
            paid: item?.paid,
            note: item?.note,
            status: item?.status,
            date: dayjs(item?.createdAt).format('DD/MM/YY'),
          }));
          setPayments(filteredTable || []);
        } else {
          setLoading(false);
          setPayments([]);
          toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
            position: toast.POSITION.BOTTOM_LEFT
          })
        }
      } catch (err) {
        setLoading(false);
        toast.error('Something went wrong!, Please try again..!', {
          position: toast.POSITION.BOTTOM_LEFT
        })
      }
    }, 500)
  };

  // Ref to the table component to be printed
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const tableData = {
    columns,
    payments,
  };

  const handleSubmit = async () => {
    if (!price) {
      toast.error('Please add amount.', {
        position: toast.POSITION.BOTTOM_LEFT
      })
    } else {
      try {
        const res = await approvePaymentStatus(selectedId, {
          amount: price
        })
        if (res.status === 200) {
          handleClose()
          toast.success('Approved successfully..!', {
            position: toast.POSITION.BOTTOM_LEFT
          })
          ListPayments()
        }
        else {
          toast.error('Something went wrong!, Please try again.', {
            position: toast.POSITION.BOTTOM_LEFT
          })
        }
      } catch (err) {
        toast.error('Something went wrong!, Please try again.', {
          position: toast.POSITION.BOTTOM_LEFT
        })
      }
    }
  }

  const exportToExcel = () => {
    const worksheetData = payments?.map((row) =>
      columns.map((column) => column.selector(row))
    );

    const header = columns.map((column) => column.name);
    const worksheet = XLSX.utils.aoa_to_sheet([header, ...worksheetData]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/vnd.ms-excel" });
    saveAs(data, "Alumni_Payment.xlsx");
  };
  const StatusOptions = ["ALL", "SUCCESS", "PENDING", "FAILED"];
  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleOptionChange = (event) => {
    const option = event.target.name;
    setSelectedOptions([option]);
  };
  const filteredData = selectedOptions?.length === 0 || selectedOptions?.includes("ALL") ? payments : payments.filter((row) => selectedOptions?.includes(row?.status));


  const open = Boolean(anchorEl);
  return (
    <>

      <Container maxWidth="xxl" className='payment_table'>
        <Grid container spacing={2}>
          <Grid item md={8} className='title_page'>
            <Typography variant="h4" gutterBottom>
              Payments
            </Typography>
          </Grid>

          <Grid item md={4} container alignItems="center" justifyContent="flex-end">
            {/* <Box className='search_area'>
              <TextField
                sx={{ p: '-10px' }}
                label="Search filter"
                variant="outlined"
                value={searchText}
                onChange={handleSearch}
                placeholder="Search Filter"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box> */}
            <Box sx={{
              '&:hover': {
                cursor: 'pointer'
              },
              marginLeft: '10px' // Adjust the margin as needed
            }}>
              <Tooltip title="Filter">
                <IconButton
                  sx={{ color: '#d7b56d' }}
                  aria-label="filter"
                  onClick={handleOpenPopover}
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <FormControl>
                  <FormGroup>
                    {StatusOptions?.map((option) => (
                      <FormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            checked={selectedOptions?.includes(option)}
                            onChange={handleOptionChange}
                            name={option}
                            sx={{
                              mx: 2
                            }}
                          />
                        }
                        label={option}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Popover>
            </Box>
            <Box sx={{
              '&:hover': {
                cursor: 'pointer'
              },
              marginLeft: '10px' // Adjust the margin as needed
            }}>
              <Tooltip title="Export to excel">
                <IconButton sx={{ color: '#d7b56d' }} aria-label="add to shopping cart" onClick={exportToExcel}>
                  <IosShareIcon />
                </IconButton>
              </Tooltip>
              {/* <Button variant="contained"  sx={{ color: '#d7b56d' }} >
                Export to Excel <IosShareIcon />
              </Button> */}
            </Box>
            <Box sx={{
              '&:hover': {
                cursor: 'pointer'
              },
              marginLeft: '10px' // Adjust the margin as needed
            }}>
              <Tooltip title="Print">
                <IconButton sx={{ color: '#d7b56d' }} aria-label="add to shopping cart" onClick={handlePrint}>
                  <PrintIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>

        {!loading ?
          <div>
            <Loader />
          </div> :
          <>
            <div>

              <Card className='alumni_data print-card'
                ref={componentRef}
              >
                <DataTable
                  columns={columns}
                  data={filteredData}
                  paginationPerPage={50}
                  paginationRowsPerPageOptions={[50, 100, 500]}
                  print
                  defaultSortField="name"
                  sortIcon={<NorthIcon />}
                  pagination
                  selectableRows={false}
                  customStyles={tableCustomStyles}
                  striped
                />
              </Card>
            </div>
            <Dialog open={openModal} onClose={handleClose}>
              <DialogTitle>Approve</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  To approve, please enter the amount here.
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="amount"
                  label="Amount"
                  type="number"
                  onChange={(e) => setPrice(e.target.value)}
                  fullWidth
                  variant="standard"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Submit</Button>
              </DialogActions>
            </Dialog>
          </>
        }
      </Container >
    </>

  );
};

export default PaymentsList;
