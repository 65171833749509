import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { generateSignInOTP } from '../../services/authentication';
// ----------------------------------------------------------------------

export function LoginForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [alumniRegdFee, setAlumniRegdFee] = useState("Alumni Registration Fee");

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Please enter your email'),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const apiRes = await generateSignInOTP({ email: values.email });
        if (apiRes.status === 200) {
          if (apiRes?.data?.data?.redirect) {
            navigate(`/payment?slug=${alumniRegdFee}&key=${apiRes?.data?.data?.key}`)
          } else {
            navigate(`/verify-otp?email=${values.email}`, { replace: true });
          }
        } else {
          toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
            position: toast.POSITION.BOTTOM_LEFT
          })
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error('Something went wrong!, Please try again.', {
          position: toast.POSITION.BOTTOM_LEFT
        })
      }
    },
  });

  return (
    <>

      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3} mb={4}>
          <TextField
            name="email"
            type={'email'}
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            label="Email address"
            required
          />
        </Stack>
        {formik.touched.email && formik.errors.email &&
          <p style={{ color: 'red', marginTop: '-20px', marginBottom: '5px',textAlign: 'left' }}>
            {formik.errors.email}
          </p>}

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
          Verify
        </LoadingButton>
      </form>
      <ToastContainer />
    </>
  );
}
export default LoginForm; 
