/**
 * @scope api related to profile
 * 
 * @author Umasankar
 * @since 8th feb 2023
 */

import { API } from './provider';
import { V1, UPLOAD_PROFILE } from './endpoints';

// Profile photo apis
export const UpdateProfile = (id, payload) => { return API.POST(V1, UPLOAD_PROFILE.replace('{id}', id), payload); }