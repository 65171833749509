import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from '../../../Setting';
import { GetUserDetails, } from '../../../services/users';
// mocks_
import account from '../../../_mock/account';
import { getInitials } from '../../../utils/string';

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [loading, setLoading] = useState(false);
  const [basicDetails, setBasicDetails] = useState();
  const [imageUrl, setImageUrl] = useState(null);

  const id = localStorage.getItem('id')

  const getBasicDetails = async () => {

    try {
      setLoading(true);
      const apiRes = await GetUserDetails(id);
      if (apiRes.status === 200) {
        setBasicDetails(apiRes?.data?.data);
        const url = apiRes?.data?.data?.avatar ? API_BASE_URL + apiRes?.data?.data?.avatar : ""
        setImageUrl(url)
      } else {
        if (apiRes?.data?.message === 'UnAuthorized') {
          localStorage.clear()
          navigate("/auth/sign-in");
        }
        toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
          position: toast.POSITION.BOTTOM_LEFT
        })
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error('Something went wrong!, Please try again..!', {
        position: toast.POSITION.BOTTOM_LEFT
      })
    }
  };

  useEffect(() => {
    getBasicDetails();
  }, [])

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const navigate = useNavigate();

  const handleClickHome = () => {
    handleClose();
    navigate("/dashboard/analytics");
  }

  const handleClickProfile = () => {
    handleClose();
    navigate("/profile");
  }

  const handleClickEditProfile = () => {
    handleClose();
    navigate(`/profile/${id}`);
  }

  const handleClickLogout = () => {
    handleClose();
    localStorage.clear()
    navigate("/auth/sign-in");
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={imageUrl || account.photoURL} alt="photoURL" >
          {/* <Avatar src={'https://www.almabay.com/common/marvel/photos/2023/05/duOV6_39555_f47b47a611df8ce65ef10b826ed1ad2e.jpg'}> */}
          {imageUrl || getInitials(`${basicDetails?.firstName} ${basicDetails?.lastName}`)}
        </Avatar>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >

        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {basicDetails?.firstName} {basicDetails?.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {basicDetails?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Stack sx={{ p: 1 }}>
          <MenuItem onClick={handleClickHome}>
            Home
          </MenuItem>
          <MenuItem onClick={handleClickProfile}>
            Profile
          </MenuItem>
          <MenuItem onClick={handleClickEditProfile}>
            Edit Profile
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleClickLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
        <ToastContainer />
      </Popover>
    </>
  );
}
