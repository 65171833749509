
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Link from '@mui/material/Link';
// icons

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Box, Stack } from '@mui/system';
import Lottie from "lottie-react";
import { ListSocialLinks } from '../../services/social';

import NoData from '../../animations/Monitoring Info.json'
import Loader from '../../loader/Loader';


function SocialProfiles() {
    const [loading, setLoading] = useState(false);
    const [socialDetails, setSocialDetails] = useState([]);

    const id = localStorage.getItem('id')

    const getSocialMediaDetails = async () => {
        setTimeout(async () => {
            try {
                const apiRes = await ListSocialLinks(id);
                if (apiRes.status === 200) {
                    setLoading(true);
                    setSocialDetails(apiRes?.data?.data || []);
                } else {
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
            } catch (err) {
                setLoading(false);
                toast.error('Something went wrong!, Please try again..!', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        }, 500)
    };

    useEffect(() => {
        getSocialMediaDetails();
    }, [])

    return (
        <>
            {!loading ?
                <div>
                    <Loader />
                </div> :
                <>
                    <Stack justifyContent="center" alignItems="center" sx={{
                        flexWrap: "wrap",
                    }}
                    >
                        {!socialDetails.length > 0 ? <Lottie style={{ width: '30%', height: '20%', scale: '1.8' }} loop animationData={NoData} /> :
                            <>

                                <Box>
                                    <Stack mt={2} direction={'row'} spacing={2}>
                                        <Box color={'rgb(24, 119, 242)'}><FacebookIcon /></Box>
                                        <Box
                                            width={{ xs: '50%', sm: '100%', md: '100%' }}
                                            style={{ wordWrap: "break-word" }}

                                        >
                                            <Link href={socialDetails?.find(link => link.type === 'facebook')?.url} underline="none" target="_blank" sx={{ textOverflow: 'ellipsis' }}>
                                                {socialDetails?.map((item) => (item?.type === 'facebook' ? item?.url : ''))}
                                            </Link>
                                        </Box>
                                    </Stack>
                                    <Stack direction={'row'} spacing={2}>
                                        <Box color={'rgb(224, 45, 105)'}><InstagramIcon /></Box>
                                        <Box
                                            style={{ wordWrap: "break-word" }}
                                            width={{ xs: '50%', sm: '100%', md: '100%' }}
                                        >
                                            <Link href={socialDetails?.find(link => link.type === 'instagram')?.url} underline="none" target="_blank" sx={{ textOverflow: 'ellipsis' }}>
                                                {socialDetails?.map((item) => (item?.type === 'instagram' ? item?.url : ''))}
                                            </Link>
                                        </Box>
                                    </Stack>
                                    <Stack direction={'row'} spacing={2}>
                                        <Box color={'rgb(0, 126, 187)'}><LinkedInIcon /></Box>
                                        <Box
                                            style={{ wordWrap: "break-word" }}
                                            width={{ xs: '50%', sm: '100%', md: '100%' }}
                                        >
                                            <Link href={socialDetails?.find(link => link.type === 'linkedIn')?.url} underline="none" target="_blank">
                                                {socialDetails?.map((item) => (item?.type === 'linkedIn' ? item?.url : ''))}
                                            </Link>
                                        </Box>
                                    </Stack>
                                    <Stack direction={'row'} spacing={2}>
                                        <Box color={'rgb(0, 170, 236)'}><TwitterIcon /></Box>
                                        <Box
                                            style={{ wordWrap: "break-word" }}
                                            width={{ xs: '50%', sm: '100%', md: '100%' }}
                                        >
                                            <Link href={socialDetails?.find(link => link.type === 'twitter')?.url} underline="none" target="_blank">
                                                {socialDetails?.map((item) => (item?.type === 'twitter' ? item?.url : ''))}
                                            </Link>
                                        </Box>
                                    </Stack>
                                </Box>
                            </>
                        }
                        <ToastContainer />
                    </Stack>
                </>
            }
        </>
    )
}

export default SocialProfiles
