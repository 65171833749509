import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
    Box,
    Stack,
    Button,
    Select,
    MenuItem,
    FormGroup,
    TextField,
    Container,
    InputLabel,
    Typography,
    FormControl,
    FormControlLabel,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import Switch from '@mui/material/Switch';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import { ToastContainer, toast } from 'react-toastify';
import '../../css/main.css'

// Importing the required components from react-draft-wysiwyg
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CreateNotices, GetNotices, UpdateNotices } from '../../services/notices';
import { ListRoles } from '../../services/role';

function Announcements({ editNotice }) {

    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [isScheduleOn, setIsScheduleOn] = useState(false);
    const [isBrowserOn, setIsBrowserOn] = useState(false);
    const [isEmailOn, setIsEmailOn] = useState(false);
    const [isSmsOn, setIsSmsOn] = useState(false);
    const [editorState, setEditorState] = useState("");
    const [notice, setNotice] = useState(null);
    const [roles, setRoles] = useState();

    const validationSchema = Yup.object({
        channel: Yup.object().test({
            name: "channel",
            message: "Please select at least one channel",
            test: (value) => {
                return value.browserPush || value.email || value.sms;
            },
        }),
        role: Yup.string().required("Role is required"),
        title: Yup.string().required("Title/Subject is required"),
        messageBody: Yup.string().required("Comment is required"),
        messageContentWithHtml: Yup.string().required("Message is required"),
        // expairyDateTime: Yup.string().required("expairyDate is required"),
    });

    const { id } = useParams();
    const navigate = useNavigate();

    const getRoles = async () => {
        try {
            const apiRes = await ListRoles();
            if (apiRes.status === 200) {
                setRoles(apiRes?.data?.data || []);
            } else {
                toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        } catch (err) {
            toast.error('Something went wrong!, Please try again..!', {
                position: toast.POSITION.BOTTOM_LEFT
            })
        }
    };

    const filteredRoles = roles?.filter((role) => role?.role !== 'Administrator');

    const getNotice = async () => {
        try {
            if (id !== "0") {
                setLoading(true);
                const apiRes = await GetNotices(id);
                if (apiRes.status === 200) {
                    setNotice(apiRes?.data?.data || null);
                } else {
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            toast.error('Something went wrong!, Please try again.', {
                position: toast.POSITION.BOTTOM_LEFT
            })
        }
    };

    useEffect(() => {
        getNotice();
        getRoles()
    }, [id])

    // Update initialValues with notice data
    useEffect(() => {
        if (notice) {
            formik.setValues({
                channel: {
                    browserPush: notice?.channel.browserPush,
                    email: notice?.channel.email,
                    sms: notice?.channel.sms
                },
                role: notice?.roleId || '',
                title: notice?.title || '',
                messageBody: notice?.messageBody || '',
                passingOutClass: notice?.passingOutClass,
                passingYear: notice?.passingYear,
                messageContentWithHtml: notice?.messageContentWithHtml || '',
                expairyDateTime: notice?.expairyDateTime || '',
                scheduleDateTime: notice?.scheduleDateTime || ''
            });
        }
    }, [notice]);

    const formik = useFormik({
        initialValues: {
            channel: {
                browserPush: false,
                email: true,
                sms: false,
            },
            role: "",
            title: "",
            messageBody: "",
            passingYear: "",
            passingOutClass: "",
            messageContentWithHtml: "",
            status: '',
            expairyDateTime: '',
            scheduleDateTime: '',
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            if (notice === null) {
                try {
                    const expairyDateTimeString = dayjs(values?.expairyDateTime).format('YYYY-MM-DD HH:mm:ss');
                    setLoadingBtn(true)
                    const apiRes = await CreateNotices({
                        channel: values?.channel,
                        roleId: values?.role,
                        title: values?.title,
                        messageBody: values?.messageBody,
                        passingOutClass: values?.passingOutClass,
                        passingYear: values?.passingYear,
                        messageContentWithHtml: values?.messageContentWithHtml,
                        status: values.status === 'draft' ? 'draft' : 'publish',
                        expairyDateTime: expairyDateTimeString,
                        scheduleDateTime: values?.scheduleDateTime,
                    });
                    if (apiRes.status === 201) {
                        navigate('/announcement')
                        toast.success('Announcement created successfully..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                    } else {
                        toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                    }
                } catch (err) {
                    toast.error('Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                    setLoading(false)
                } finally {
                    setLoadingBtn(false)
                }
            } else {
                try {
                    const expairyDateTimeString = dayjs(values?.expairyDateTime).format('YYYY-MM-DD HH:mm:ss');
                    setLoadingBtn(true)
                    const apiRes = await UpdateNotices(id, {
                        channel: values?.channel,
                        roleId: values?.role,
                        title: values?.title,
                        messageBody: values?.messageBody,
                        passingOutClass: values?.passingOutClass,
                        passingYear: values?.passingYear,
                        messageContentWithHtml: values?.messageContentWithHtml,
                        status: values.status === 'draft' ? 'draft' : 'publish',
                        expairyDateTime: expairyDateTimeString,
                        scheduleDateTime: values?.scheduleDateTime,
                    });
                    if (apiRes.status === 201) {
                        navigate('/announcement')
                        toast.success('Announcement updated successfully..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                    } else {
                        toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again..!', {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                    }
                } catch (err) {
                    toast.error('Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                    setLoading(false)
                } finally {
                    setLoadingBtn(false)
                }
            }
        },
    });

    const toolbarOptions = [
        "heading",
        "|",
        "bold",
        "italic",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "link",
        "blockQuote",
        "|",
        "undo",
        "redo"
    ];

    const currentYear = dayjs().year();
    // const minDate = `${currentYear}-01-01`; 
    const minDate = dayjs();
    const maxDate = new Date(2026, 11, 31); // current year
    const { channel } = formik.values;

    return (
        <>
            <Helmet>
                <title>Announcement </title>
            </Helmet>

            <Container maxWidth="lg">
                <Typography mb={5} variant="h4" gutterBottom>
                    Announcements
                </Typography>

                <Stack direction={'row'} spacing={2}>

                    <Box width={'40%'}
                        bgcolor={"white"}
                        whiteSpace="normal"
                        px={2}
                        pt={2}
                        sx={{
                            // width: { xs: '100%', sm: '100%', md: '40%', lg: '40%' },
                            boxShadow: '0px 0px 5px -2px',
                            borderRadius: '10px',
                        }}
                    >

                        <Typography variant='h4' my={2} >Channels</Typography>

                        {/* <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">

                            <Box display={'flex'}>
                                <OpenInBrowserIcon style={{ color: "#9e9e9e" }} />
                                <Typography ml={1} variant="body1" color="inherit">Browser Push</Typography>
                            </Box>
                            <Box>
                                <Switch checked={channel?.browserPush} onChange={() => formik.setFieldValue('channel.browserPush', !channel.browserPush)} />
                            </Box>
                        </Stack> */}

                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">

                            <Box display={'flex'}>
                                <EmailIcon style={{ color: "#9e9e9e" }} />
                                <Typography ml={1} variant="body1" color="inherit">Email</Typography>
                            </Box>
                            <Box>
                                <Switch
                                    checked={channel.email}
                                    onChange={() => formik.setFieldValue('channel.email', true)}
                                    disabled
                                />
                            </Box>


                        </Stack>

                        {/* <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                            <Box display={'flex'}>
                                <SmsIcon style={{ color: "#9e9e9e" }} />
                                <Typography ml={1} variant="body1" color="inherit">SMS</Typography>
                            </Box>
                            <Box>
                                <Switch checked={channel?.sms} onChange={() => formik.setFieldValue('channel.sms', !channel.sms)} />
                            </Box>
                        </Stack> */}

                        {formik.touched.channel && !formik.values.channel.browserPush && !formik.values.channel.email && !formik.values.channel.sms && (
                            <Typography color="error">Please select at least one channel</Typography>
                        )}

                    </Box>

                    <Box
                        px={2}
                        py={2}
                        width={"100%"}
                        bgcolor={"white"}
                        sx={{
                            // width: { xs: '100%', sm: '100%', md: '40%', lg: '40%' },
                            boxShadow: '0px 0px 5px -2px',
                            borderRadius: '10px',
                        }}
                    >
                        <form onSubmit={formik.handleSubmit}>
                            <Stack
                                mx={2}
                                direction={'column'}
                                spacing={2}

                            >

                                <Typography variant='h4' my={2} >Announcement Details</Typography>

                                <Box width={'100%'} mb={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="role">Role</InputLabel>
                                        <Select
                                            sx={{
                                                width: '100%',
                                            }}
                                            labelId="role"
                                            id="role"
                                            name="role"
                                            label="Role"
                                            value={formik.values.role}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.role && Boolean(formik.errors.role)}
                                            helperText={formik.touched.role && formik.errors.role}
                                        >
                                            {filteredRoles?.map((role) => (
                                                <MenuItem key={role?.id} value={role?.id}>
                                                    {role?.tag}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </FormControl>
                                    {formik.touched.role && formik.errors.role && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.role}</p>}
                                </Box>

                                <Box width={'100%'} mb={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Passing Out Class</InputLabel>
                                        <Select
                                            sx={{
                                                width: '100%',
                                            }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="passingOutClass"
                                            value={formik.values?.passingOutClass || ''}
                                            label="Passing Out Class"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            <MenuItem value={"Matriculation"}>Matriculation</MenuItem>
                                            <MenuItem value={"Senior Secondary/Plus Two"}>Senior Secondary/Plus Two</MenuItem>
                                        </Select>

                                    </FormControl>
                                    {/* {formik.touched.passingOutClass && formik.errors.passingOutClass && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.passingOutClass}</p>} */}
                                </Box>

                                <Box width={'100%'} mb={2}>
                                    <TextField
                                        sx={{
                                            width: '100%'
                                        }}
                                        fullWidth
                                        id="passYear"
                                        name="passingYear"
                                        label="Passing out year"
                                        type="number"
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        value={formik.values.passingYear}
                                        onChange={formik.handleChange}
                                    // InputProps={{
                                    //     startAdornment: (
                                    //         <SchoolIcon color="action" />
                                    //     ),
                                    // }}
                                    />
                                    {/* {formik.touched.passingYear && formik.errors.passingYear && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.passingYear}</p>} */}
                                </Box>

                                <Box mb={2}>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        label="Title/Subject"
                                        id="title"
                                        name="title"
                                        type="text"
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.title && Boolean(formik.errors.title)}
                                    />
                                    {formik.touched.title && formik.errors.title && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.title}</p>}
                                </Box>

                                <Box mb={2}>
                                    <TextField
                                        sx={{
                                            width: '100%',
                                        }}
                                        id="outlined-comment"
                                        label="Add a comment"
                                        type="text"
                                        multiline
                                        rows={4}
                                        maxRows={6}
                                        name="messageBody"
                                        value={formik.values.messageBody}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.messageBody && Boolean(formik.errors.messageBody)}
                                        autoComplete="off"
                                    />
                                    {formik.touched.messageBody && formik.errors.messageBody && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.messageBody}</p>}
                                </Box>

                                {channel?.email && (
                                    <Box mb={2}>
                                        <CKEditor
                                            id="messageContentWithHtml"
                                            name="messageContentWithHtml"
                                            className="inputText"
                                            editor={ClassicEditor}
                                            data={formik.values?.messageContentWithHtml}
                                            value={formik.values?.messageContentWithHtml}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.messageContentWithHtml && Boolean(formik.errors.messageContentWithHtml)}
                                            onChange={(event, editor) => {
                                                if (editor) {
                                                    formik.setFieldValue("messageContentWithHtml", editor.getData());
                                                    formik.setFieldValue(
                                                        "rawContent",
                                                        new DOMParser().parseFromString(
                                                            editor.getData(),
                                                            "text/html"
                                                        ).documentElement.textContent
                                                    );
                                                }
                                            }}
                                            config={{
                                                toolbar: ['bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', '|', 'heading', 'blockquote', '|', 'undo', 'redo']
                                            }}
                                        />

                                        {formik.touched.messageContentWithHtml && formik.errors.messageContentWithHtml &&
                                            <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>
                                                {formik.errors.messageContentWithHtml}
                                            </p>}
                                    </Box>
                                )}

                                {/* <Box mb={2}>
                                    <Box mb={2}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDateTimePicker
                                                label="Expairy Date and Time"
                                                inputFormat="DD/MM/YYYY HH:mm:ss"
                                                value={formik.values?.expairyDateTime}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue("expairyDateTime", newValue);
                                                    formik.setFieldTouched("expairyDateTime", true);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{
                                                            width: "100%",
                                                        }}
                                                        {...params}
                                                        name="expairyDateTime"
                                                        onBlur={formik.handleBlur}
                                                        error={
                                                            formik.errors.expairyDateTime &&
                                                            formik.touched.expairyDateTime
                                                        }
                                                    />

                                                )}
                                                minDateTime={minDate}
                                                maxDate={maxDate}
                                            />
                                        </LocalizationProvider>
                                        {formik.touched.expairyDateTime && formik.errors.expairyDateTime && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px' }}>{formik.errors.expairyDateTime}</p>}
                                    </Box>
                                </Box> */}

                                <Box mb={2}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch checked={isScheduleOn} onChange={(event) => setIsScheduleOn(event.target.checked)} />} label="Schedule" />
                                    </FormGroup>
                                </Box>
                                {isScheduleOn && (
                                    <Box mb={2}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDateTimePicker
                                                label="Schedule Date and Time"
                                                inputFormat="DD/MM/YYYY HH:mm:ss"
                                                value={formik.values?.scheduleDateTime}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue("scheduleDateTime", newValue);
                                                    formik.setFieldTouched("scheduleDateTime", true);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{
                                                            width: "100%",
                                                        }}
                                                        {...params}
                                                        name="scheduleDateTime"
                                                        onBlur={formik.handleBlur}
                                                        error={
                                                            formik.errors.scheduleDateTime &&
                                                            formik.touched.scheduleDateTime
                                                        }
                                                    />
                                                )}
                                                minDateTime={minDate}
                                                maxDate={maxDate}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                )}

                                <Stack py={1} spacing={2}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Box>
                                            <LoadingButton
                                                loadingPosition="end"
                                                loading={loadingBtn}
                                                type='button'
                                                variant="outlined"
                                                onClick={() => {
                                                    formik.setValues({
                                                        ...formik.values,
                                                        status: 'draft' // set the status to 'draft'
                                                    });
                                                    formik.handleSubmit(); // call handleSubmit with the updated values
                                                }}
                                            >
                                                Save as Draft
                                            </LoadingButton>

                                        </Box>
                                        <Box ml={2}>
                                            <LoadingButton
                                                type='button'
                                                endIcon={<SendIcon />}
                                                loading={loadingBtn}
                                                loadingPosition="end"
                                                sx={{
                                                    backgroundColor: "rgb(0, 171, 85)"
                                                }}
                                                variant="contained"
                                                onClick={() => {
                                                    formik.setValues({
                                                        ...formik.values,
                                                        status: 'publish' // set the status to 'publish'
                                                    });
                                                    formik.handleSubmit(); // call handleSubmit with the updated values
                                                }}
                                            >
                                                <span>Publish</span>
                                            </LoadingButton>
                                        </Box>
                                    </Box>
                                </Stack>

                            </Stack>
                        </form>
                    </Box>
                </Stack>
                <ToastContainer />
            </Container>
        </>
    )
}

export default Announcements
