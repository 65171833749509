import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, Container, Typography, Stack, Box, MenuItem, Select, FormControl, InputLabel, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from "react-google-autocomplete";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useResponsive from "../../hooks/useResponsive";
import Logo from "../../components/logo/Logo";
import { signUp } from '../../services/authentication';
import '../../css/main.css';
import AuthFooter from "./AuthFooter";
import AuthHeader from "./AuthHeader";

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    top: '42%',
    left: '5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

function SignUp() {

    const mdUp = useResponsive('up', 'md');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [alumniRegdFee, setAlumniRegdFee] = useState("Alumni Registration Fee");

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is required'),
        lastName: Yup.string()
            // .length(3, 'Last Name must be greater than 3 characters')
            .required('Last Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        mobile: Yup.string()
            .length(10, 'Mobile Number must be 10 digit')
            .required('Mobile Number is required'),
        dob: Yup.string()
            .required("Date of birth is required").nullable(),
        passingYear: Yup.string()
            .required('Passing out year is required'),
        passingOutClass: Yup.string()
            .required('Passing out class is required'),
        location: Yup.string()
            .required('Location is required'),
        occupation: Yup.string()
            .required('Occupation is required'),
        address: Yup.string()
            .required('Addres is required'),
    })

    const validate = (values) => {
        const errors = {};

        if (values?.dob && values?.passingYear) {
            const dob = dayjs(values?.dob);
            const passingYear = dayjs(`01/01/${values?.passingYear}`, 'DD/MM/YYYY');

            if (passingYear?.isBefore(dob)) {
                errors.passingYear = "Passing year cannot be before date of birth";
            }
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            dob: '',
            passingYear: '',
            passingOutClass: '',
            location: '',
            address: '',
            occupation: '',
        },
        validationSchema,
        validate,
        onSubmit: async (values) => {
            try {
                const apiRes = await signUp({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    mobileNoPrefix: "+91",
                    mobileNo: values.mobile,
                    dob: new Date(values.dob),
                    passingYear: values.passingYear,
                    passingOutClass: values.passingOutClass,
                    location: values.location,
                    address: values.address,
                    occupation: values.occupation,
                });
                if (apiRes.status === 200) {
                    setLoading(true);
                    if (apiRes?.data?.data?.redirect) {
                        navigate(`/payment?slug=${alumniRegdFee}&key=${apiRes?.data?.data?.key}`)
                    }
                } else {
                    setLoading(false);
                    if (apiRes?.data?.message === "Sorry, EmailId already exist.") {
                        navigate('/auth/sign-in')
                    }
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
            } catch (err) {
                setLoading(false);
                toast.error('Something went wrong!, Please try again.', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        },
    });

    const currentYear = dayjs().year();
    const minDate = `${currentYear - 72}-01-01`; // 72 years ago
    const maxDate = `${currentYear}-01-01`; // current year

    return (
        <>
            <Helmet>
                <title> Sign Up | Alumni </title>
            </Helmet>


            <section className="header">
                <AuthHeader />
            </section>
            <StyledRoot>
                <section className='sign_in sign_up'>
                    <Container maxWidth="xxl">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <img src='/assets/images/welcome-img.jpg' alt='welcome' />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} className='right_side'>

                                <StyledContent>
                                    <Typography variant="h4" gutterBottom>
                                        Sign up to St. Paul’s Alumni.
                                    </Typography>

                                    <Typography variant="body2" sx={{ mb: 5 }}>
                                        Already have an account? {''}
                                        <Link to={'/auth/sign-in'} variant="subtitle2">Sign in</Link>
                                    </Typography>

                                    <form onSubmit={formik.handleSubmit}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <Box>
                                                    <TextField
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                        id="outlined-firstName-input"
                                                        label="First Name"
                                                        name="firstName"
                                                        type={'text'}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.firstName}
                                                        onBlur={formik.handleBlur}
                                                        autoComplete="off"

                                                    />
                                                    {formik.touched.firstName && formik.errors.firstName && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.firstName}</p>}
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <Box>
                                                    <TextField
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                        id="outlined-lastName-input"
                                                        label="Last Name"
                                                        name="lastName"
                                                        type={'text'}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.lastName}
                                                        onBlur={formik.handleBlur}
                                                        autoComplete="off"
                                                    />
                                                    {formik.touched.lastName && formik.errors.lastName && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.lastName}</p>}
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <Box>
                                                    <TextField
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                        id="outlined-email-input"
                                                        label="Email Address"
                                                        name="email"
                                                        type={'email'}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.email}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.email && formik.errors.email && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.email}</p>}
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6} className="phone_field">
                                                <Box>
                                                    <TextField
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    +91
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        id="outlined-mobile-input"
                                                        label="Mobile Number"
                                                        type="tel"
                                                        name="mobile"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.mobile}
                                                        onBlur={formik.handleBlur}
                                                        autoComplete="off"
                                                    />
                                                    {formik.touched.mobile && formik.errors.mobile && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.mobile}</p>}
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6} className="btn_icons">
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                    }}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DesktopDatePicker
                                                            label="Date Of Birth"
                                                            inputFormat="DD/MM/YYYY"
                                                            value={formik.values?.dob}
                                                            onChange={(newValue) => {
                                                                formik.setFieldValue(
                                                                    "dob",
                                                                    newValue
                                                                );
                                                                formik.setFieldTouched("dob", true);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    sx={{
                                                                        width: "100%"
                                                                    }}
                                                                    {...params}
                                                                    name="dob"
                                                                    onBlur={formik.handleBlur}
                                                                    error={formik.errors.dob && formik.touched.dob}
                                                                />
                                                            )}
                                                            minDate={minDate}
                                                            maxDate={maxDate}
                                                        />
                                                    </LocalizationProvider>
                                                    {formik.touched.dob && formik.errors.dob && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.dob}</p>}
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6} className="btn_icons">
                                                <Box>
                                                    <TextField
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                        id="outlined-occupation-input"
                                                        label="Occupation"
                                                        name="occupation"
                                                        type={'text'}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.occupation}
                                                        onBlur={formik.handleBlur}
                                                        autoComplete="off"
                                                    />
                                                    {formik.touched.occupation && formik.errors.occupation && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.occupation}</p>}
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6} className="btn_icons">
                                                <Box sx={{ width: '100%' }}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            views={['year']}
                                                            label="Passing Year"
                                                            maxDate={dayjs().subtract(1, 'year')}
                                                            value={formik.values.passingYear || ''}
                                                            onBlur={formik.handleBlur}
                                                            onChange={(newValue) => {
                                                                formik.setFieldValue('passingYear', newValue?.format('YYYY'))
                                                            }}
                                                            renderInput={(params) => <TextField
                                                                sx={{
                                                                    width: '100%',
                                                                }}
                                                                {...params}
                                                                name='passingYear'
                                                                onBlur={formik.handleBlur}
                                                                error={formik.errors.passingYear && formik.touched.passingYear}
                                                            />}
                                                            minDate={minDate}
                                                        />

                                                        {formik.touched.passingYear && formik.errors.passingYear && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.passingYear}</p>}
                                                    </LocalizationProvider>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <Box>
                                                    <Autocomplete
                                                        className="location"
                                                        style={{
                                                            width: '100%',
                                                            paddingLeft: '13px',
                                                            height: '9vh',
                                                            borderRadius: '6px',
                                                            fontSize: '17px',
                                                            fontWeight: '500',
                                                            color: "#333",
                                                            backgroundColor: "#F9FAFB",
                                                            border: "1px solid #ced4da",
                                                        }}


                                                        apiKey={"AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc"}
                                                        onPlaceSelected={(place) => {
                                                            formik.setFieldValue('location', place?.formatted_address)
                                                        }}
                                                        types={["address"]}
                                                        componentRestrictions={{ country: "us" }}
                                                        placeholder={'Enter a Location'}
                                                        name="location"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.location && formik.errors.location && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.location}</p>}
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12}>
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <FormControl fullWidth >
                                                        <InputLabel id="demo-simple-select-label">Passing Out Class</InputLabel>
                                                        <Select
                                                            sx={{
                                                                height: '56px',
                                                            }}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            name="passingOutClass"
                                                            value={formik.values.passingOutClass}
                                                            label="Passing Out Class"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        >
                                                            <MenuItem value={'Matriculation'}>Matriculation</MenuItem>
                                                            <MenuItem value={'Senior Secondary/Plus Two'}>Senior Secondary/Plus Two</MenuItem>
                                                            <MenuItem value={'Other'}>Other</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {formik.touched.passingOutClass && formik.errors.passingOutClass && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.passingOutClass}</p>}
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12}>
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <TextField
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                        id="outlined-password-input"
                                                        label="Address"
                                                        name="address"
                                                        value={formik.values.address}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        type="text"
                                                        multiline
                                                        rows={4}
                                                        maxRows={6}
                                                        autoComplete="off"
                                                    />
                                                    {formik.touched.address && formik.errors.address && <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>{formik.errors.address}</p>}
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12}>
                                                <Box ml={'auto'}>
                                                    <LoadingButton
                                                        fullWidth
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                        loading={loading}
                                                    >
                                                        Sign Up
                                                    </LoadingButton>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </form>

                                </StyledContent>
                            </Grid>
                        </Grid>
                    </Container>
                </section>
            </StyledRoot>

            <footer>
                <AuthFooter />
            </footer>

            <ToastContainer />
        </>
    )
}

export default SignUp

