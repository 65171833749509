import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { styled } from '@mui/material/styles';
import { Container, Typography, Grid, Stack, Box } from '@mui/material';
import useResponsive from "../../hooks/useResponsive";
import Logo from "../../components/logo/Logo"; 
import { LoginForm } from './LoginForm';
import AuthHeader from './AuthHeader';
import AuthFooter from './AuthFooter';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  marginLeft:'5%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function SignIn() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> Login | Alumni </title>
      </Helmet>

      <section className="header">
       <AuthHeader/>
       </section>

       <StyledRoot>
        {/* {mdUp && (
          <StyledSection>
           
          </StyledSection>
        )} */}
        
        <section className='sign_in'>
        <Container maxWidth="xxl">
        <Grid container spacing={2}>  
        <Grid item xs={12} sm={6} md={6}>
          <img src='/assets/images/welcome-img.jpg' alt='welcome'/>     
          </Grid>

        <Grid item xs={12} sm={6} md={6} className='right_side'>
        <Typography variant="h3">
              Hi, Welcome
            </Typography>   
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign in to St. Paul’s Alumni.
            </Typography>

            <Typography variant="body2" sx={{ mb:5 }}>
              Don’t have an account? {''}
              <Link to={'/auth/sign-up'} variant="subtitle2">Get started</Link>
            </Typography>
    
            <LoginForm />

          </StyledContent>
          </Grid>
          </Grid>
        </Container>
        </section>

      </StyledRoot>

     <footer>
     <AuthFooter/> 
      </footer>
    </>
  );
}
