import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from "react-google-autocomplete";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Box,
    Stack,
    Select,
    MenuItem,
    InputLabel,
    Container,
    Typography,
    FormControl,
} from '@mui/material';
import '../../css/main.css';
import { ThreeDots } from 'react-loader-spinner';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { API_BASE_URL } from '../../Setting';
import { GetUserDetails, UpdateUserDetails } from '../../services/users';
import { UpdateProfile } from '../../services/profile';
import Loader from '../../loader/Loader';

function BasicDetails() {

    const [imageUrl, setImageUrl] = useState(null);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [getData, setGetData] = useState([]);
    const [locationValue, setLocationValue] = useState("");

    const handleFileChange1 = e => {
        setFile(e.target.files[0]);
        setImageUrl(URL.createObjectURL(e.target.files[0]));
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is required'),
        lastName: Yup.string()
            // .length(3, 'Last Name must be greater than 3 characters')
            .required('Last Name is required'),
        email: Yup.string().email('Invalid email').required('Please enter your email'),
        mobile: Yup.string()
            .length(10, 'Mobile Number must be 10 digit')
            .required('Mobile Number is required'),
        dob: Yup.string()
            .required("Date of birth is required").nullable(),
        // anniversaryDate: Yup.string()
        //     .required("AnniversaryDate date is required").nullable(),
        passingYear: Yup.string()
            .required('Passing out year is required').nullable(),
        passingOutClass: Yup.string()
            .required('Passing out class is required').nullable(),
        location: Yup.string()
            .required('Location is required'),
        occupation: Yup.string()
            .required('Occupation is required').nullable(),
        address: Yup.string()
            .required('Addres is required').nullable(),
    })

    const validate = (values) => {
        const errors = {};
        if (values?.dob && values?.passingYear) {
            const dob = dayjs(values?.dob);
            const passingYear = dayjs(`01/01/${values?.passingYear}`, 'DD/MM/YYYY');

            if (passingYear?.isBefore(dob)) {
                errors.passingYear = "Passing year cannot be before date of birth";
            }
        }
        return errors;
    };


    const userId = localStorage.getItem('id')
    const { id } = useParams();
    const userRole = localStorage.getItem('role')
    const navigate = useNavigate()
    // !userRole === "Administrator" || 

    useEffect(() => {
        if (userRole !== "Administrator" && userId !== id) {
            navigate('/dashboard/analytics');
        }
    }, []);

    const getUserDetails = async () => {
        setTimeout(async () => {
            try {
                const apiRes = await GetUserDetails(id);
                if (apiRes.status === 200) {
                    setLoading(true);
                    setGetData(apiRes?.data?.data || []);
                    const url = apiRes?.data?.data?.avatar ? API_BASE_URL + apiRes?.data?.data?.avatar : ""
                    console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^',url)
                    setImageUrl(url)
                } else {
                    setLoading(false);
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
            } catch (err) {
                setLoading(false);
                toast.error('Something went wrong!, Please try again.', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        }, 500)
    };

    const updateProfilePhoto = async (id) => {
        const formData = new FormData()
        formData.append('file', file)
        const apiRes = await UpdateProfile(id, formData)
    };

    useEffect(() => {
        getUserDetails();
    }, [])

    useEffect(() => {
        if (getData) {
            formik.setValues({
                firstName: getData?.firstName,
                lastName: getData?.lastName,
                email: getData?.email,
                mobile: getData?.mobileNo,
                dob: getData?.dob,
                anniversaryDate: getData?.anniversaryDate,
                passingYear: getData?.passingYear,
                passingOutClass: getData?.passingOutClass,
                location: getData?.location,
                address: getData?.address,
                occupation: getData?.occupation,
            });
        }
    }, [getData]);

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            dob: '',
            anniversaryDate: '',
            passingYear: '',
            passingOutClass: '',
            location: '',
            address: '',
            occupation: '',
        },
        validationSchema,
        validate,
        onSubmit: async (values) => {
            try {
                await updateProfilePhoto(id);
                setLoadingBtn(true)
                const apiRes = await UpdateUserDetails(id, {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    mobileNoPrefix: "+91",
                    mobileNo: values.mobile,
                    dob: new Date(values.dob),
                    anniversaryDate: values.anniversaryDate ? new Date(values.anniversaryDate) : '',
                    passingYear: values.passingYear,
                    passingOutClass: values.passingOutClass,
                    location: values.location,
                    address: values.address,
                    occupation: values.occupation,
                });
                if (apiRes.status === 200) {
                    setLoading(true);
                    toast.success('Updated successfully..!', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                    getUserDetails();
                    formik.resetForm();
                } else {
                    setLoading(false);
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
            } catch (err) {
                setLoading(false);
                toast.error('Something went wrong!, Please try again.', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            } finally {
                setLoadingBtn(false)
            }
        },
    });

    const currentYear = dayjs().year();
    const minDate = `${currentYear - 72}-01-01`; // 72 years ago
    const maxDate = `${currentYear}-01-01`; // current year

    return (
        <>
            {!loading ?
                <div>
                    <Loader />
                </div> :
                <>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', }} spacing={3}>
                            <Box sx={{
                                width: { xs: '100%', sm: '100%', md: '40%', lg: '40%' },
                                boxShadow: '0px 0px 5px -2px',
                                borderRadius: '10px',
                            }} className="edit_pp">
                                <Stack direction={'column'} justifyContent='center' mt={15} mx={5} className='student_img'>
                                    <Avatar
                                        className='editProfile'
                                        // src={'https://www.almabay.com/common/marvel/photos/2023/05/duOV6_39555_f47b47a611df8ce65ef10b826ed1ad2e.jpg'}
                                        src={imageUrl}
                                        sx={{
                                            mx: 'auto',
                                            width: { xs: 100, sm: 100, md: 160, lg: 160 },
                                            height: { xs: 100, sm: 100, md: 160, lg: 160 },
                                            border: '5px solid #f1f1f1',
                                            boxShadow: '0px 0px 5px -2px',
                                            alignItems: 'center',
                                            '&:hover': {
                                                filter: 'brightness(50%)',
                                            },
                                        }}
                                    />

                                    <Button
                                        fullWidth size="medium"
                                        variant="contained"
                                        component="label"
                                        sx={{
                                            mt: 3,
                                            backgroundColor: '#d7b56d'
                                        }}
                                    >
                                        <CameraAltIcon />
                                        Upload Photo
                                        <input
                                            type="file"
                                            hidden
                                            onChange={handleFileChange1}
                                        />
                                    </Button>
                                    <Typography variant='caption' textAlign={'center'} mt={3}>
                                        <Box
                                            sx={{ color: 'text.disabled' }}
                                        >

                                            Allowed *.jpeg, *.jpg, *.png, *.gif
                                            max size of 3.1 MB
                                        </Box>
                                    </Typography>
                                </Stack>
                            </Box>
                            <Box sx={{
                                width: '100%',
                                boxShadow: '0px 0px 5px -2px',
                                borderRadius: '10px'
                            }}
                                py={5}
                                className='edit_data'>

                                <Stack direction={'row'} spacing={2} px={5} py={1}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: '100%',
                                            }}
                                            id="outlined-firstName-input"
                                            label="First Name"
                                            name="firstName"
                                            type={'text'}
                                            onChange={formik.handleChange}
                                            value={formik.values.firstName}
                                            onBlur={formik.handleBlur}
                                            autoComplete="off"

                                        />
                                        {formik.touched.firstName && formik.errors.firstName && <p style={{ color: 'red', marginTop: '-10px' }}>{formik.errors.firstName}</p>}
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: '100%',
                                            }}
                                            id="outlined-lastName-input"
                                            label="Last Name"
                                            name="lastName"
                                            type={'text'}
                                            onChange={formik.handleChange}
                                            value={formik.values.lastName}
                                            onBlur={formik.handleBlur}
                                            autoComplete="off"
                                        />
                                        {formik.touched.lastName && formik.errors.lastName && <p style={{ color: 'red', marginTop: '-10px' }}>{formik.errors.lastName}</p>}
                                    </Box>
                                </Stack>
                                <Stack direction={'row'} spacing={2} px={5} py={1}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: '100%',
                                            }}
                                            id="outlined-email-input"
                                            label="Email address"
                                            name="email"
                                            type={'email'}
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            onBlur={formik.handleBlur}
                                            disabled
                                        />
                                        {formik.touched.email && formik.errors.email && <p style={{ color: 'red', marginTop: '-10px' }}>{formik.errors.email}</p>}
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: '100%',
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" sx={{ mt: 1 }}>+91</InputAdornment>
                                                ),
                                            }}
                                            id="outlined-mobile-input"
                                            label="Mobile Number"
                                            type="tel"
                                            name="mobile"
                                            onChange={formik.handleChange}
                                            value={formik.values.mobile}
                                            onBlur={formik.handleBlur}
                                            autoComplete="off"
                                            disabled
                                            fullWidth
                                        />
                                        {formik.touched.mobile && formik.errors.mobile && <p style={{ color: 'red', marginTop: '-10px' }}>{formik.errors.mobile}</p>}
                                    </Box>
                                </Stack>
                                <Stack direction={'row'} spacing={2} px={5} py={1}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                                            <DesktopDatePicker
                                                label="Date of birth"
                                                inputFormat="DD/MM/YYYY"
                                                value={formik.values?.dob}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue(
                                                        "dob",
                                                        newValue
                                                    );
                                                    formik.setFieldTouched("dob", true);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{
                                                            width: "100%"
                                                        }}
                                                        {...params}
                                                        name="dob"
                                                        onBlur={formik.handleBlur}
                                                        error={formik.errors.dob && formik.touched.dob}
                                                    />
                                                )}
                                                minDate={minDate}
                                                maxDate={maxDate}
                                            />

                                        </LocalizationProvider>
                                        {formik.touched.dob && formik.errors.dob && <p style={{ color: 'red', marginTop: '-10px' }}>{formik.errors.dob}</p>}
                                    </Box>

                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                views={['day', 'month']}
                                                label="Anniversary Date"
                                                value={formik.values.anniversaryDate || null}
                                                onBlur={formik.handleBlur}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue('anniversaryDate', newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                        {...params}
                                                        name="anniversaryDate"
                                                        onBlur={formik.handleBlur}
                                                        error={formik.errors.anniversaryDate && formik.touched.anniversaryDate}
                                                    />
                                                )}
                                            // maxDate={dayjs().subtract(1, 'day')} // Optional: To prevent future dates (anniversaries)
                                            />

                                            {/* {formik.touched.anniversaryDate && formik.errors.anniversaryDate && (
                                                                <p style={{ color: 'red', marginTop: '5px', marginBottom: '-15px', textAlign: 'left' }}>
                                                                    {formik.errors.anniversaryDate}
                                                                </p>
                                                            )} */}
                                        </LocalizationProvider>
                                        {/* {formik.touched.anniversaryDate && formik.errors.anniversaryDate && <p style={{ color: 'red', marginTop: '-10px' }}>{formik.errors.anniversaryDate}</p>} */}
                                    </Box>

                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                views={['year']}
                                                label="Passing year"
                                                maxDate={dayjs().subtract(1, 'year')}
                                                value={formik.values?.passingYear || ''}
                                                onBlur={formik.handleBlur}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue('passingYear', newValue?.format('YYYY'))
                                                }}
                                                renderInput={(params) => <TextField
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                    {...params}
                                                    name='passingYear'
                                                    onBlur={formik.handleBlur}
                                                    error={formik.errors.passingYear && formik.touched.passingYear}
                                                />}
                                                minDate={minDate}
                                            />

                                            {formik.touched.passingYear && formik.errors.passingYear && <p style={{ color: 'red', marginTop: '-10px' }}>{formik.errors.passingYear}</p>}
                                        </LocalizationProvider>
                                    </Box>
                                </Stack>
                                <Stack direction={'row'} spacing={2} px={5} py={1}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: '100%',
                                            }}
                                            id="outlined-occupation-input"
                                            label="Occupation"
                                            name="occupation"
                                            type={'text'}
                                            onChange={formik.handleChange}
                                            value={formik.values.occupation}
                                            onBlur={formik.handleBlur}
                                            autoComplete="off"
                                        />
                                        {formik.touched.occupation && formik.errors.occupation && <p style={{ color: 'red', marginTop: '-10px' }}>{formik.errors.occupation}</p>}
                                    </Box>

                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <Autocomplete
                                            className="location"
                                            style={{
                                                width: '100%',
                                                padding: '16.5px 14px',
                                                borderRadius: '6px',
                                                fontSize: '17px',
                                                fontWeight: '500',
                                                color: "#333",
                                                backgroundColor: "#F9FAFB",
                                                border: "1px solid #ced4da",
                                            }}


                                            apiKey={"AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc"}
                                            onPlaceSelected={(place) => {
                                                formik.setFieldValue('location', place?.formatted_address)
                                                formik.setFieldTouched('location', true);
                                            }}
                                            defaultValue={formik.values?.location}
                                            types={["address"]}
                                            componentRestrictions={{ country: "us" }}
                                            name="location"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.location && formik.errors.location && <p style={{ color: 'red', marginTop: '5px' }}>{formik.errors.location}</p>}
                                    </Box>

                                </Stack>

                                <Stack direction={'row'} spacing={2} px={5} py={1}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Passing Out Class</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="passingOutClass"
                                                // value={formik.values.passingOutClass}
                                                value={formik.values?.passingOutClass || ''}
                                                label="Passing Out Class"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            >
                                                <MenuItem value={"Matriculation"}>Matriculation</MenuItem>
                                                <MenuItem value={"Senior Secondary/Plus Two"}>Senior Secondary/Plus Two</MenuItem>
                                            </Select>

                                        </FormControl>
                                        {formik.touched.passingOutClass && formik.errors.passingOutClass && <p style={{ color: 'red', marginTop: '-10px' }}>{formik.errors.passingOutClass}</p>}
                                    </Box>
                                </Stack>

                                <Stack px={5} py={1}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: '100%',
                                            }}
                                            id="outlined-password-input"
                                            label="Addres"
                                            name="address"
                                            value={formik.values.address}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            type="text"
                                            multiline
                                            rows={4}
                                            maxRows={6}
                                            autoComplete="off"
                                        />
                                        {formik.touched.address && formik.errors.address && <p style={{ color: 'red', marginTop: '-10px' }}>{formik.errors.address}</p>}
                                    </Box>
                                </Stack>

                                <Stack px={7} py={1}>
                                    <Box ml={'auto'} className='save_btn'>
                                        <LoadingButton
                                            type='submit'
                                            endIcon={<SaveIcon />}
                                            loading={loadingBtn}
                                            loadingPosition="end"
                                            sx={{
                                                backgroundColor: "#00793a"
                                            }}
                                            variant="contained"
                                        >
                                            <span>Save Changes</span>
                                        </LoadingButton>
                                        {/* <Button type='submit' variant="contained" sx={{
                                            backgroundColor: "rgb(0, 171, 85)"
                                        }}>
                                            Save Changes
                                        </Button> */}
                                    </Box>
                                </Stack>
                            </Box>


                        </Stack>
                    </form >
                    <ToastContainer />
                </>
            }

        </>
    )
}

export default BasicDetails
