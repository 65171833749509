/**
 * @scope api related to analytics
 * 
 * @author Rajendra
 * @since 15th Jan 2023
 */

import { API } from './provider';
import { V1, ANALYTICS_COUNTER } from './endpoints';

// User Details apis

export const appCounters = (id) => { return API.GET(V1, ANALYTICS_COUNTER) }

