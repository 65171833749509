import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from "lottie-react";
import {
    Box,
    Menu,
    Button,
    Paper,
    Card,
    IconButton,
    Container,
    Typography,
    CardContent,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../loader/Loader';
import noData from '../../animations/Circle.json';

import { ListNotices } from '../../services/notices';

function AnnouncementList() {
    const [channel, setChannel] = React.useState([]);
    const [status, setStatus] = React.useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [notices, setNotices] = useState();
    const [originalNotices, setOriginalNotices] = useState([]);
    const [selectedNoticeId, setSelectedNoticeId] = useState(null);

    const ChannelOptions = [
        'Email',
    ];
    const StatusOptions = [
        'Pending',
        'Sent',
        'Draft',
        // 'Not sent',
    ];

    const handleClick = (event, id) => {
        setSelectedNoticeId(id);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        handleClose();
        navigate(`/announcement/${selectedNoticeId}`);
    }

    const navigate = useNavigate()
    const userId = localStorage.getItem('id')

    const getNotices = async () => {
        setTimeout(async () => {
            try {
                const apiRes = await ListNotices();
                if (apiRes.status === 200) {
                    setLoading(true);
                    setOriginalNotices(apiRes?.data?.data || []);
                    setNotices(apiRes?.data?.data || []);
                } else {
                    setLoading(false);
                    toast.error(apiRes?.data?.message || 'Something went wrong!, Please try again.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                }
            } catch (err) {
                setLoading(false);
                toast.error('Something went wrong!, Please try again.', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        }, 500)
    };

    const handleChange = (e) => {
        setStatus(e.target.value);
        let filteredNotices = originalNotices;
        if (e.target.value === "Pending") {
            filteredNotices = originalNotices.filter((notice) => notice.notifications.some((notification) => notification.scheduleDateTime && !notification.isSent));
        } else if (e.target.value === "Sent") {
            filteredNotices = originalNotices.filter(
                (notice) =>
                    notice.notifications.length > 0 &&
                    notice.notifications.every((notification) => notification.isSent)
            );
        } else if (e.target.value === "Draft") {
            filteredNotices = originalNotices.filter((notice) => notice.status === "draft");
        } else if (e.target.value === "Not sent") {
            filteredNotices = originalNotices.filter((notice) => notice.notifications.every((notification) => !notification.isSent));
        }
        setNotices(filteredNotices);
    };

    useEffect(() => {
        getNotices();
    }, [])

    return (
        <div>
            <Container maxWidth="xxl">
                <Typography mb={5} variant="h4" gutterBottom>
                    Announcements
                </Typography>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid xs={12}>
                        <Box sx={{ minWidth: 275 }}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Grid
                                        my={-2}
                                        container
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                    >
                                        <Grid container direction="row" alignItems="center">
                                            <Grid>
                                                <Box sx={{ minWidth: 140 }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-multiple-checkbox-label">All Channels</InputLabel>
                                                        <Select
                                                            labelId="demo-multiple-checkbox-label"
                                                            id="demo-multiple-checkbox"
                                                            multiple
                                                            value={channel}
                                                            input={<OutlinedInput label="All Channels" />}
                                                            onChange={(e) => setChannel(e.target.value)}
                                                            renderValue={(selected) => selected.join(', ')}
                                                            CheckboxProps={{
                                                                color: 'primary',
                                                            }}
                                                            sx={{ height: 50 }}
                                                        >
                                                            {ChannelOptions.map((option) => (
                                                                <MenuItem key={option} value={option}>
                                                                    <Checkbox checked={channel.indexOf(option) > -1} />
                                                                    <ListItemText primary={option} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid>
                                                <Box sx={{ minWidth: 160 }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label" sx={{ textAlign: 'center' }}>All Status</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={status}
                                                            input={<OutlinedInput label="All Status" />}
                                                            onChange={(e) => handleChange(e)}
                                                            renderValue={(selected) => selected}
                                                            CheckboxProps={{ color: 'primary' }}
                                                            sx={{ height: 50 }}
                                                        >
                                                            <MenuItem key="" value="" sx={{ height: 40 }}>
                                                                <Checkbox checked={status === ""} />
                                                                <ListItemText primary="All" />
                                                            </MenuItem>
                                                            {StatusOptions.map((option) => (
                                                                <MenuItem key={option} value={option} sx={{ height: 40 }}>
                                                                    <Checkbox checked={status === option} />
                                                                    <ListItemText primary={option} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Grid>


                                            {/* <Grid>
                                            time duration
                                        </Grid> */}
                                        </Grid>
                                        <Grid>
                                            <Button className='create_news' endIcon={<AddIcon />} onClick={() => navigate(`/announcement/${0}`)} variant='contained' sx={{ bgcolor: 'rgb(0, 171, 85)' }} >
                                                Create
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
                {!loading ?
                    <div>
                        <Loader />
                    </div> :
                    <>
                        {notices?.length === 0 ?
                            <>
                                <Box sx={{ bgcolor: 'white', mt: 3 }}>
                                    <Lottie style={{ height: '300px' }} loop animationData={noData} />
                                </Box>
                            </>
                            :
                            <>
                                {notices?.map((item, index) => (
                                    <>
                                        <Grid key={index} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2}>
                                            <Grid xs={12}>
                                                <Box sx={{ minWidth: 275 }}>
                                                    <Card variant="outlined">
                                                        <CardContent>
                                                            <Grid
                                                                container
                                                                justifyContent="space-between"
                                                                alignItems="flex-start"
                                                            >
                                                                <Grid container direction="row" alignItems="center">
                                                                    <Grid>
                                                                        <NotificationsIcon style={{ color: '#9e9e9e', fontSize: '50px' }} />
                                                                    </Grid>
                                                                    <Grid>
                                                                        <Typography variant="h5" component="div">
                                                                            {item?.title}
                                                                        </Typography>
                                                                        <Typography my={1} variant="subtitle1" color="text.secondary">
                                                                            {item?.messageBody}
                                                                        </Typography>
                                                                        <Chip
                                                                            sx={{ borderRadius: '6px' }}
                                                                            label={item?.roleId === '2' ? 'Alumni' : item?.roleId === '3' ? 'Student' : item?.roleId === '4' ? 'Teacher' : ''}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid>
                                                                    <Grid item>
                                                                        {
                                                                            item?.status === 'draft' &&
                                                                            <MoreVertIcon sx={{
                                                                                '&:hover': {
                                                                                    cursor: 'pointer'
                                                                                },
                                                                            }} onClick={(e) => handleClick(e, item.id)} />
                                                                        }
                                                                        <Menu
                                                                            anchorEl={anchorEl}
                                                                            open={Boolean(anchorEl)}
                                                                            onClose={handleClose}
                                                                            anchorOrigin={{
                                                                                vertical: 'top',
                                                                                horizontal: 'right',
                                                                            }}
                                                                            // transformOrigin={{
                                                                            //     vertical: 'top',
                                                                            //     horizontal: 'right',
                                                                            // }}
                                                                        >
                                                                            <MenuItem onClick={handleEdit} sx={{ my: -1 }}>Edit</MenuItem>
                                                                        </Menu>
                                                                    </Grid>
                                                                    <Grid item mt={2}>
                                                                        <Chip
                                                                            label={
                                                                                item?.notifications.some((notification) => !notification?.isSent)
                                                                                    ? "Not Sent"
                                                                                    : item?.status === "publish"
                                                                                        ? "Sent"
                                                                                        : "Draft"
                                                                            }
                                                                            sx={{ borderRadius: "6px" }}
                                                                            color={
                                                                                item?.notifications.some((notification) => !notification?.isSent)
                                                                                    ? "error"
                                                                                    : item?.status === "publish"
                                                                                        ? "success"
                                                                                        : "warning"
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </>
                                ))}
                            </>
                        }
                    </>
                }
            </Container>
            <ToastContainer />
        </div >
    )
}

export default AnnouncementList
