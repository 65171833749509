import React, { useState, useEffect } from 'react';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PaymentsIcon from '@mui/icons-material/Payments';
import CampaignIcon from '@mui/icons-material/Campaign';
// component
import SvgColor from '../../../components/svg-color';

function Config() {
  const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
  const [menus, setMenus] = useState([{
    title: 'dashboard',
    path: '/dashboard/analytics',
    icon: icon('ic_analytics'),
    role: ["Administrator", "Alumni"]
  },
  {
    title: 'profile',
    path: '/profile',
    icon: <ManageAccountsIcon />,
    role: ["Administrator", "Alumni"]
  },
  {
    title: 'alumni',
    path: '/dashboard/alumni',
    icon: icon('ic_user'),
    role: ["Administrator"]
  },
  {
    title: 'Payments',
    path: '/dashboard/payments-list',
    icon: <PaymentsIcon />,
    role: ["Administrator"]
  },
  {
    title: 'Payments',
    path: '/dashboard/payments-list',
    icon: <PaymentsIcon />,
    role: ["Administrator"]
  }]);

  // useEffect(() => {
  //   console.log("NAL", navConfig?.map((item) => {
  //     if (item.role.includes(role)) {
  //       return item;
  //     }
  //   }))
  // }, [role]);

  const navConfigWithoutAlumni = [
    {
      title: 'dashboard',
      path: '/dashboard/analytics',
      icon: icon('ic_analytics'),
    },
    // {
    //   title: 'Announcements',
    //   path: '/announcements',
    //   icon: <CampaignIcon />,
    // },
  ];

  const navConfigWithAlumni = [
    {
      title: 'dashboard',
      path: '/dashboard/analytics',
      icon: icon('ic_analytics'),
    },
    {
      title: 'alumni',
      path: '/dashboard/alumni',
      icon: icon('ic_user'),
    },
    {
      title: 'Payments List',
      path: '/dashboard/payments-list',
      icon: <PaymentsIcon />,
    },
    {
      title: 'profile',
      path: '/profile',
      icon: <ManageAccountsIcon />,
    },
  ];




  // useEffect(() => {
  //   const roleFromStorage = localStorage.getItem('role');
  //   if (roleFromStorage) {
  //     setRole(roleFromStorage);
  //   }
  // }, []);

  // useEffect(() => {
  //   setNavConfig(role === 'Administrator' ? navConfigWithAlumni : navConfigWithoutAlumni);
  // }, [role]);
  // console.log('role:', role);


  return menus;
}

export default Config

