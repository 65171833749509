import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Lottie from "lottie-react";
import SuccessFull from '../../animations/Successful.json';
import Pending from '../../animations/actions.json';
import Failed from '../../animations/Failed.json';
import { paymentResponse } from '../../services/authentication';
import useResponsive from "../../hooks/useResponsive";
import Logo from "../../components/logo/Logo";


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    marginLeft: '5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
function PaymentStatus() {
    const mdUp = useResponsive('up', 'md');
    const [loading, setLoading] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [status, setStatus] = useState(0);

    const navigate = useNavigate();
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('txnid');


    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await paymentResponse(id);
                // console.log(res.data)
                if (res.data.data === 'SUCCESS') {
                    setStatus(1);
                } else if (res.data.data === 'FAILED') {
                    setStatus(2);
                } else {
                    setStatus(3);
                }
            } catch (err) {
                setLoading(false);
                toast.error('Something went wrong!, Please try again.', {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        }
        fetchData();
    }, [id]);

    const handleClick = () => {
        if (status === 1) {
            navigate('/auth/sign-in', { replace: true });
            setLoading(true)
        } else if (status === 2) {
            navigate('/auth/sign-up', { replace: true });
            setLoading(false)
        } else {
            navigate('/auth/sign-up', { replace: true });
            setLoading(false)
        }
    }


    return (
        <div>
            <>
                <Helmet>
                    <title> Login | Alumni </title>
                </Helmet>

                <StyledRoot>
                    <Logo
                        sx={{
                            position: 'fixed',
                            top: { xs: 16, sm: 24, md: 40 },
                            left: { xs: 16, sm: 24, md: 40 },
                        }}
                    />

                    {mdUp && (
                        <StyledSection>
                            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                                Hi, Welcome
                            </Typography>
                            <img src="/assets/illustrations/illustration_login.png" alt="login" />
                        </StyledSection>
                    )}

                    <Container maxWidth="sm" >
                        <StyledContent>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                boxShadow: '1px 0px 9px -2px',
                                borderRadius: '8px',
                                padding: 4,
                            }}
                            >
                                <Typography variant="h3">Payment Status</Typography>
                                <Box style={{
                                    width: '100%',
                                    marginTop: 2,
                                }}>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        {status === 1 ?
                                            <>
                                                <Lottie loop animationData={SuccessFull} />
                                            </> :
                                            status === 2 ?
                                                <>
                                                    <Lottie loop animationData={Failed} />
                                                </> :
                                                status === 3 ?
                                                    <>
                                                        <Lottie style={{ width: '60%' }} loop animationData={Pending} />
                                                    </> : ''
                                        }

                                    </Box>
                                    <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <LoadingButton
                                            onClick={handleClick}
                                            loading={loading}
                                            variant="contained" sx={{
                                                backgroundColor: "rgb(0, 171, 85)"
                                            }}
                                        >
                                            {status === 1 ? 'Sign In' : status === 2 ? 'Sign Up' : status === 3 ? 'Sign Up' : ''}
                                        </LoadingButton>
                                    </Box>
                                    <Typography sx={{ display: 'flex', justifyContent: 'center' }} mt={2} variant="body2" color="textSecondary">
                                        "please sign in to continue."
                                    </Typography>
                                </Box>
                            </Box>
                        </StyledContent>
                        <ToastContainer />
                    </Container>
                </StyledRoot>
            </>
        </div>
    )
}

export default PaymentStatus
